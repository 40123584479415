import React, { useCallback, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { isAndroid, isIOS } from 'react-device-detect';

import AppManager from '@src/lib/app/AppManager';
import { ArrowBtn } from '../arrowBtn';
import { Navigation } from 'swiper';
import cn from 'clsx';
import { useMediaBreak } from '@src/hooks';
import { useRouter } from 'next/router';

const Banner = (children: React.ReactNode) => {
  return { children };
};

/**
 * @deprecated 사용안함
 * 기획팀 요청으로 사용안하기로 함
 * Ongoing용(/main && /ongoing) 정적배너
 */
const StaticDaily = () => {
  return (
    <div className="flex items-center gap-x-[2px]">
      <div className="icon w-[26px] mo:w-[20px]">
        <img src="/images/daily/ico_clock.png" alt="" />
      </div>
      <div className="text-[.7rem] font-medium text-[#777] -tracking-[.3px]">
        Updates everyday at 8pm
      </div>
    </div>
  );
  // return (
  //   <div className="tablet:mt-[10px] tablet:text-[13px] tablet:p-[5px_10px] bg-[#7480F2] mb-[20px] text-center leading-[24px] rounded-[5px] p-[10px_25px] text-[24px] font-[600] text-[#fff]">
  //     <div className="flex items-center justify-center">
  //       <img
  //         className="max-w-[26px] tablet:max-w-[18px] mr-[4px]"
  //         src="/images/daily/ico_clock.png"
  //         alt="clock"
  //       />
  //       <div>
  //         UPDATES EVERYDAY <span className="text-[#FFD993]">AT 8PM</span>
  //       </div>
  //     </div>
  //   </div>
  // );
};

enum ActionType {
  PC_ORIGIN = 'pc_origin',
  PC_MARKET = 'pc_market',
  ANDROID_ORIGIN = 'android_origin',
  ANDROID_MARKET = 'android_market',
  IOS_ORIGIN = 'ios_origin',
  IOS_MARKET = 'ios_market',
}

interface BannerType {
  actionType: ActionType;
  img: string;
}

const DownLoadApp = () => {
  const router = useRouter();
  const { isMobile } = useMediaBreak();

  /**
   * ANCHOR: 배너 리스트 아이템
   * android, ios 및 자체앱, 스토어앱 구분처리
   */
  const bannerList = useMemo((): BannerType[] => {
    const baseUrl = '/images/downLoadApp/light/homeBanner';
    if (isIOS && isMobile) {
      return [
        {
          actionType: ActionType.IOS_MARKET,
          img: `${baseUrl}/store_app_ios_mo.png`,
        },
        {
          actionType: ActionType.IOS_ORIGIN,
          img: `${baseUrl}/download_app_ios_mo.png`,
        },
      ];
    } else if (isAndroid && isMobile) {
      return [
        {
          actionType: ActionType.ANDROID_MARKET,
          img: `${baseUrl}/store_app_android_mo.png`,
        },
        {
          actionType: ActionType.ANDROID_ORIGIN,
          img: `${baseUrl}/download_app_android_mo.png`,
        },
      ];
    } else {
      // pc
      return [
        {
          actionType: ActionType.PC_MARKET,
          img: `${baseUrl}/store_app_default_pc.png`,
        },
        {
          actionType: ActionType.PC_ORIGIN,
          img: `${baseUrl}/download_app_default_pc.png`,
        },
      ];
    }
  }, [isAndroid, isIOS, isMobile]);

  // ANCHOR: 구글 스토어 이동 함수
  const moveAndroidMarket = useCallback(() => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.topco.toptoonglobal.iap',
    );
  }, []);

  // ANCHOR: 애플 스토어 이동 함수
  const moveIosMarket = useCallback(() => {
    window.open('https://apps.apple.com/app/daycomics/id6470585655');
  }, []);

  // ANCHOR:
  const action = useCallback(
    (item: BannerType) => {
      switch (item.actionType) {
        case ActionType.IOS_ORIGIN:
        case ActionType.ANDROID_ORIGIN:
        case ActionType.PC_ORIGIN: {
          router.push('/download/app');
          break;
        }
        case ActionType.ANDROID_MARKET: {
          moveAndroidMarket();
          break;
        }

        case ActionType.IOS_MARKET: {
          moveIosMarket();
          break;
        }
        default: {
          break;
        }
      }
    },
    [router, moveIosMarket, moveAndroidMarket],
  );

  const isApp = useMemo(() => {
    return AppManager.getInstance().isApp();
  }, []);

  // ANCHOR: app에서 비노출
  if (isApp) return null;

  return (
    <section
      className={cn(
        'banner group block mx-auto my-0 max-w-[1240px] px-[30px] relative w-full',
        'table:hidden',
        'middle:px-[15px] mo:m-[0_auto_22px] mo:px-[12px] mo:block',
      )}
    >
      <Swiper
        loop
        slidesPerView={1}
        spaceBetween={8}
        navigation={{ prevEl: '#downLoadBnrPrev', nextEl: '#downLoadBnrNext' }}
        modules={[Navigation]}
        breakpoints={{
          0: {
            slidesPerView: 1.1,
          },
          767: {
            slidesPerView: 1,
          },
        }}
      >
        {bannerList.map((item, index) => {
          return (
            <SwiperSlide key={`${item.actionType}-${index}`}>
              <button
                type="button"
                onClick={e => {
                  e.preventDefault();
                  action(item);
                }}
                className="relative"
              >
                <img src={item.img} alt="" />

                <MarketPcBannerBadge
                  isMobile={isMobile}
                  actionType={item.actionType}
                  moveAndroidMarket={moveAndroidMarket}
                  moveIosMarket={moveIosMarket}
                />
              </button>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <ArrowBtn.Prev
        id="downLoadBnrPrev"
        setPosition="swiper-btn-prev -translate-y-[15%] left-0 middle:!block mo:hidden mo:-translate-y-[30%]"
      />
      <ArrowBtn.Next
        id="downLoadBnrNext"
        setPosition="swiper-btn-next -translate-y-[15%] right-0 middle:!block mo:hidden mo:-translate-y-[30%]"
      />
    </section>
  );
};

/**
 * ANCHOR: 스토어앱 PC용 이미지에 들어가는 이미지 뱃지
 * android, ios 서로 다른 스토어 페이지 이동 시키기 위함
 * @param param0
 * @returns
 */
const MarketPcBannerBadge = ({
  moveAndroidMarket,
  moveIosMarket,
  isMobile,
  actionType,
}: {
  isMobile: boolean;
  actionType: ActionType;
  moveAndroidMarket: () => void;
  moveIosMarket: () => void;
}) => {
  if (isMobile || actionType === ActionType.PC_ORIGIN) return null;

  const commonCss = 'inline-block h-[52px]';
  return (
    <div className="absolute right-[4%] flex items-center bottom-[20%] gap-x-[10px]">
      <span className={commonCss} onClick={moveIosMarket}>
        <img
          className="w-full h-full"
          src="/images/downLoadApp/light/homeBanner/download_app_default_item_ios_pc.png"
          alt=""
        />
      </span>
      <span className={commonCss} onClick={moveAndroidMarket}>
        <img
          className="w-full h-full"
          src="/images/downLoadApp/light/homeBanner/download_app_default_item_android_pc.png"
          alt=""
        />
      </span>
    </div>
  );
};

Banner.StaticDaily = StaticDaily;
Banner.DownLoadApp = DownLoadApp;
export default Banner;
