import cn from 'clsx';

interface PropTypes {
  title: string;
  type?: string;
}

const TitleComponent = (props: PropTypes) => {
  const { title, type = '' } = props;
  return (
    <strong
      className={cn(` text-black block`, {
        ['text-[1.1rem] mb-[20px] text-center font-medium']: type === 'lg',
        ['text-[18px] text-center font-semibold mb-[15px] leading-[21px]']:
          type === '',
        ['w-full h-[21px] leading-[21px] inline-block text-[#333] text-[18px] font-semibold']:
          type === 'snsTit',
      })}
    >
      {title}
    </strong>
  );
};

export default TitleComponent;
