/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import cn from 'classnames';
import useTestApi from './useTestTool';
import { UserDefault, UserSession } from '@src/lib';
import Cookies from 'js-cookie';
import useStore from './useTestRouter';
import useLogin from '@src/hooks/useLogin';
import ChangeAccountCookie from './ChangeAccountCookie';

type Props = {};

const TestToolBox = (props: Props) => {
  const { logout } = useLogin();
  const { token } = UserSession.getUserInfo();
  const { testSignup, accountUse } = useTestApi();
  const store = useStore();

  const SubMenu = [
    {
      isShow:
        token === '' && accountUse.getter().id && accountUse.getter().password,
      name: '빠른 가입',
      position: 'right-[-60px]',
      bgcolor: '#ccc',
      action: () => testSignup(),
    },
    {
      isShow: token.length > 0,
      name: '로그아웃',
      position: 'right-[-60px]',
      bgcolor: '#ff323a',
      action: () => (logout ? logout() : {}),
    },
    {
      isShow: token === '',
      name: '빠른 가입 설정창',
      position:
        accountUse.getter().id && accountUse.getter().password
          ? 'right-[-120px]'
          : 'right-[-60px]',
      bgcolor: '#ddd',
      action: () => store.toggleIsAccountForm(),
    },
  ];
  return (
    <div className="fixed bottom-8 left-8 bg-[#29292C] rounded-md flex text-[.8rem] cursor-pointer drop-shadow-md">
      <span
        className="flex items-center justify-center w-[40px] h-[40px] p-[10px]"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          store.toggleIsMenuOpen();
        }}
      >
        O
      </span>

      {store.isMenuOpen &&
        SubMenu.map(item => {
          return item.isShow ? (
            <div
              key={item.name}
              className={cn(
                `absolute z-10 ${item.position} w-[50px] h-[50px] flex items-center text-[0.55rem] justify-center rounded-md text-[#000] font-[600] text-center bg-[${item.bgcolor}] leading-[1rem]`,
              )}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                item.action();
              }}
            >
              {item.name}
            </div>
          ) : (
            <></>
          );
        })}
      {store.isAccountFormOpen && (
        <ChangeAccountCookie
          changeAccount={store.changeAccount}
          account={accountUse.getter}
        />
      )}
    </div>
  );
};

export default TestToolBox;
