import { closeConfirmModal, openConfirmModal } from '@src/view/modal/payment';

import ApiPayment from '@toptoon-developers/global.toptoonplus.common.lib/dist/apiV2/client/V1/ApiPayment';
import AppManager from '@src/lib/app/AppManager';
import { PaymentContext } from '../context';
import { PaymentPrepareRequest } from '@toptoon-developers/global.toptoonplus.common.lib/dist/apiV2/client/V1/ApiPayment';
import { PaymentWindowModeType } from '../data/_constants';
import { TypeCheck } from '@toptoon-developers/global.toptoonplus.common.lib';
import { UserDefault } from '@src/lib';

export interface PaypalConfirmType {
  accessToken: any;
  pg: string;
  orderId: string;
  referenceId: string;
  userId: any;
}
export namespace PaypalPurchase {
  export const paypalSelector = async (
    props: PaymentContext,
    payMethod: string,
    isUseLastPayMethod: boolean,
  ) => {
    const { token, selectProductItem, windowMode, isMobile = false } = props;
    if (!selectProductItem) return;

    const request: PaymentPrepareRequest.PaymentPrepareType = {
      accessToken: token,
      productId: selectProductItem?.productId || 0,
      pg: 'xsolla',
      latestLocation: `${window.location.href}`,
      viewType: windowMode ?? PaymentWindowModeType.LARGE,
      paymentMethodId: payMethod,
      isUseLastPayMethod,
    };

    const res = await new ApiPayment(
      UserDefault.getUserSession(),
    ).paymentPrepare(request);

    if (TypeCheck.itemsByPath(res, 'data.data.message')) {
      const key = 'paymentFailModal';
      openConfirmModal({
        key,
        msg: `${res.data.data.message}`,
        callback: {
          onClose(e) {
            closeConfirmModal(key);
            if (AppManager.getInstance().isIosApp()) {
              AppManager.getInstance().action.close();
            }
            window.close();
          },
          onConfirm(e) {
            closeConfirmModal(key);
            if (AppManager.getInstance().isIosApp()) {
              AppManager.getInstance().action.close();
            }
            window.close();
          },
        },
      });
      return;
    }

    const { data } = res;
    const resData = TypeCheck.itemsByPath(data, 'data');

    /**
     * GTM - 결제 정보 로컬스토리지에 저장
     */
    const pay_info = {
      id: resData.orderId ?? '',
      value: Number(resData.product.price) ?? 0,
      currency: resData.product.currencyCode ?? '',
    };
    UserDefault.gtmPayInfo.setter(pay_info);

    if (resData) {
      openXsollaNewWindow(res.data.data.xsollaToken, isMobile);
      // openmodal
    } else {
      // todo:결제 선택 가능하게 변경
      /* PaymentController.setPendingBlock(dispatch, false); */
      const key = 'confirmModal';
      openConfirmModal({
        key,
        msg: 'Your Purchase Could Not Be Completed',
        callback: {
          onClose(e) {
            closeConfirmModal(key);
            if (AppManager.getInstance().isApp()) {
              AppManager.getInstance().action.close();
            }
          },
          onConfirm(e) {
            closeConfirmModal(key);
            if (AppManager.getInstance().isApp()) {
              AppManager.getInstance().action.close();
            }
          },
        },
      });
    }
  };

  // ANCHOR: 멤버십용 정기결제 수단 추가용, 호출 api만 다름. paymentPrepareRecurring
  export const paypalRecurringSelector = async (
    props: PaymentContext,
    payMethod: string,
    isUseLastPayMethod: boolean,
  ) => {
    const { token, selectProductItem, windowMode, isMobile = false } = props;
    if (!selectProductItem) return;

    const request: PaymentPrepareRequest.PaymentPrepareType = {
      accessToken: token,
      productId: selectProductItem?.productId || 0,
      pg: 'xsolla',
      latestLocation: `${window.location.href}`,
      viewType: windowMode ?? PaymentWindowModeType.LARGE,
      paymentMethodId: payMethod,
      isUseLastPayMethod,
    };

    const res = await new ApiPayment(
      UserDefault.getUserSession(),
    ).paymentPrepareRecurring(request);

    if (TypeCheck.itemsByPath(res, 'data.data.message')) {
      const key = 'paymentFailModal';
      openConfirmModal({
        key,
        msg: `${res.data.data.message}`,
        callback: {
          onClose(e) {
            closeConfirmModal(key);
            if (AppManager.getInstance().isIosApp()) {
              AppManager.getInstance().action.close();
            }
            window.close();
          },
          onConfirm(e) {
            closeConfirmModal(key);
            if (AppManager.getInstance().isIosApp()) {
              AppManager.getInstance().action.close();
            }
            window.close();
          },
        },
      });
      return;
    }

    const { data } = res;
    const resData = TypeCheck.itemsByPath(data, 'data');

    /**
     * GTM - 결제 정보 로컬스토리지에 저장
     */
    const pay_info = {
      id: resData.orderId ?? '',
      value: Number(resData.product.price) ?? 0,
      currency: resData.product.currencyCode ?? '',
    };
    UserDefault.gtmPayInfo.setter(pay_info);

    if (resData) {
      openXsollaNewWindow(res.data.data.xsollaToken, isMobile);
      // openmodal
    } else {
      // todo:결제 선택 가능하게 변경
      /* PaymentController.setPendingBlock(dispatch, false); */
      const key = 'confirmModal';
      openConfirmModal({
        key,
        msg: 'Your Purchase Could Not Be Completed',
        callback: {
          onClose(e) {
            closeConfirmModal(key);
            if (AppManager.getInstance().isApp()) {
              AppManager.getInstance().action.close();
            }
          },
          onConfirm(e) {
            closeConfirmModal(key);
            if (AppManager.getInstance().isApp()) {
              AppManager.getInstance().action.close();
            }
          },
        },
      });
    }
  };

  // xsolla 결제창
  export const openXsollaNewWindow = (token: string, isMobile: boolean) => {
    if (window === undefined) {
      throw new Error('not support window');
    }

    // 요청시 코인 정보 리셋
    UserDefault.coinResetManual();

    const w = 748;
    const h = 775;

    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;

    const url = `${process.env.REACT_APP_ENVIRONMENT}`.includes('production')
      ? `https://secure.xsolla.com/paystation3/?access_token=${token}`
      : `https://sandbox-secure.xsolla.com/paystation3/?access_token=${token}`;

    if (!isMobile) {
      const newWindow = window.open(
        url,
        'xsolla',
        `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `,
      );

      newWindow?.focus();
    } else if (
      // 앱이고, 앱버전이 73이상이면 (native에선 window.open이 안먹혀서)
      AppManager.getInstance().isApp() &&
      AppManager.getInstance().getAppVersion() > 72
    ) {
      AppManager.getInstance().action.paymentPopup(
        `accessToken=${token}&redirectUri=${url}&pgString=xsolla`,
      );
    } else {
      setTimeout(() => {
        window.open(url)?.focus();
      });
    }
  };
}
