import { TimeSliceType } from '../viewModel';
import cn from 'clsx';

interface CountDownProps {
  timeSliceData: TimeSliceType;
  dotImgPath: string;
  className: string;
}
/**
 *
 * @param props timeSliceData, dotImgPath, className
 * @returns
 * 2022.12.21 - MOON
 * leading(line-height)의 중복선언 부분 수정
 * ANCHOR: timeSliceData {leftItem, centerItem, rightItem}
 * ANCHOR: leftItem: hour || minute, centerItem: minute || null, rightItem: second
 * *  1시간 이상 [시 / 분 / 초], 이하 - [분 / 초] 노출 조건
 */

const CountDown = (props: CountDownProps) => {
  const { timeSliceData, dotImgPath, className } = props;
  const { leftItem, rightItem, centerItem } = timeSliceData;
  const figureStyle = cn(
    'figure relative float-left first:mr-[3px] bg-white rounded-[3px] shadow-crazyBannerFigure overflow-hidden',
    "after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-full after:h-[50%] after:z-[1] after:border-t-[1px] after:border-solid after:border-black after:border-opacity-10",
    {
      ['h-[38px] w-[26px] mo:h-[26px] mo:w-[18px]']: className === 'mainTop',
      ['h-[26px] w-[18px]']:
        className === 'episodeWeb' || className === 'episodeMo',
      ['h-[42px] leading-[42px] w-[34px] mo:h-[26px] mo:leading-[26px] mo:w-[20px]']:
        className === 'paymentTop',
      ['text-[20px] h-[26px] leading-[26px] w-[20px]']:
        className === 'paymentMiniTop',
      ['h-[23px] w-[15px]']: className === 'sideMenu',
      ['h-[42px] w-[30px] mo:h-[26px] mo:leading-[26px] mo:w-[20px]']:
        className === 'viewer',
    },
  );

  const timeStyle = cn('block text-black text-center font-bold', {
    ['text-[31px] leading-[41px] mo:text-[20px] mo:leading-[28px]']:
      className === 'mainTop',
    ['text-[20px] leading-[28px]']:
      className === 'episodeWeb' || className === 'episodeMo',
    ['text-[36px] leading-[41px] mo:text-[23px] mo:leading-[27px]']:
      className === 'paymentTop',
    ['text-[17px] leading-[25px]']: className === 'sideMenu',
    ['text-[34px] leading-[42px] mo:text-[20px] mo:leading-[26px]']:
      className === 'viewer',
  });

  const dotImgStyle = cn('imgHalf float-left ', {
    ['h-[34px] mo:h-[17px]']: className === 'mainTop',
    ['h-[17px]']: className === 'episodeWeb' || className === 'episodeMo',
    ['h-[34px] tablet:h-[38px] mo:h-[22px]']: className === 'paymentTop',
    ['h-[15px]']: className === 'sideMenu',
    ['h-[28px]']: className === 'viewer',
  });

  return (
    <div
      className={cn('countDown grid-in-countDown inline-block w-auto', {
        ['']: className === 'mainTop',
      })}
    >
      <div className="flex items-center h-full countDownInner">
        <div className="float-left text-center clock-time">
          <div className={figureStyle}>
            <span className={timeStyle}>{leftItem.first}</span>
          </div>
          <div className={figureStyle}>
            <span className={timeStyle}>{leftItem.second}</span>
          </div>
        </div>
        {centerItem && (
          <>
            <img className={dotImgStyle} src={dotImgPath} alt="dot_img" />
            <div className="float-left text-center clock-time">
              <div className={figureStyle}>
                <span className={timeStyle}>{centerItem.first}</span>
              </div>
              <div className={figureStyle}>
                <span className={timeStyle}>{centerItem.second}</span>
              </div>
            </div>
          </>
        )}
        {dotImgPath && (
          <img className={dotImgStyle} src={dotImgPath} alt="dot_img" />
        )}
        <div className="clock-time float-lefttext-center">
          <div className={figureStyle}>
            <span className={timeStyle}>{rightItem.first}</span>
          </div>
          <div className={figureStyle}>
            <span className={timeStyle}>{rightItem.second}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CountDown;
