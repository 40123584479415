import { ModalWrapper } from '@src/lib/CustomModal';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import AppManager from '@src/lib/app/AppManager';

const key = 'appUpdateModal';

export const openAppUpdateModadl = () => {
  ModalInstance.getInstance().push({
    key,
    shouldCloseOnOverlayClick: false,
    component: <AppUpdateModal />,
  });
};

export const closeAppUpdateModal = () => {
  ModalInstance.getInstance().delete(key);
};

/**
 * 자체앱이고, 안드로이드앱인 버전 98이전의 유저들에게 업데이트 하라고 알려주는 모달
 * @returns
 */
const AppUpdateModal = () => {
  return (
    <ModalWrapper.Default addClassName="w-[88vw] max-w-[400px] max-h-[500px] bg-white">
      <div className="py-[30px] px-[20px] flex flex-col items-center justify-between gap-x-[20px]">
        <div className="text-black font-[500] text-[1rem] leading-[30px]">
          Latest Version Update (ver.98)
        </div>
        <div className="text-[#666] text-[0.8rem] leading-[20px] text-center mt-[10px]">
          A new version of Day Comics has been updated.
        </div>
        <button
          id="buyButton"
          className={
            'btnKeep bg-primaryRed rounded-[50px] text-white text-[17px] h-[44px] mt-[10px] w-full block max-w-[80%] text-center mx-auto'
          }
          onClick={() => {
            AppManager.getInstance().action.openDownlodadPage();
          }}
        >
          update
        </button>
      </div>
    </ModalWrapper.Default>
  );
};

export default AppUpdateModal;
