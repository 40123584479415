/**
 * 성인인증 체박스 노출 파트너코드
 */
export const isViewCheckboxList = [
  'dv04',
  'dv10',
  'dv14',
  'dv19',
  'dv26',
  'dv31',
  'dv44',
  'dv47',
  'dv66',
  'dv68',
  'dv71',
  'dv83',
  'dv84',
  'dv86',
  'dv88',
  'dv90',
  'dv91',
  'dv92',
  'dv93',
];
