import { CrazyBannerType, TimeSliceVM } from './viewModel';
import React, { useEffect, useState } from 'react';

import Countdown from 'react-countdown';
import { CrazyTimerMain } from './viewItems';
import { UserSession } from '@src/lib';
import { openMiniPaymentModal } from '@src/view/modal/payment';
import { showClickActionModal } from '@src/view/modal/crazyClickActionModal/ClickActionModal';
import useLogin from '@src/hooks/useLogin';
import { useRouter } from 'next/router';
import useUserCoin from '@src/hooks/useUserCoin';

interface PropsType {
  children: React.ReactNode;
  banner?: CrazyBannerType | null;
  className: string;
}
/**
 * 미친결제 배너
 * @param props : children, banner, className
 * @returns mainImg, subImg, countDown
 */
const CrazyTimer = (props: PropsType) => {
  const { children, banner, className } = props;
  const [isFinsh, setIsFinish] = useState(false); // Countdown이 끝나면 true
  const [isViewCrazyTimer, setIsViewCrazyTimer] = useState(false); //Crazy Timer를 보여줄지 유무
  const { showJoinAndLoginModal } = useLogin();
  const router = useRouter();
  const { getUserData } = useUserCoin();

  // Crazy Timer를 보여줄지 유무를 정하는 hook
  useEffect(() => {
    if (!banner) return;
    setIsViewCrazyTimer(
      banner.mainImgPath.length > 0 &&
        // isBetweenCheck(banner.availableAt, banner.expiredAt) &&
        !isFinsh,
    );
  }, [banner, isFinsh]);

  useEffect(() => {
    /**
     * 미친결제 완료시 getCurrentCoin 호출 및 페이지 새로고침
     */
    if (isFinsh) {
      const { token } = UserSession.getUserInfo();
      if (!token) return;

      getUserData();

      // 뷰어체크
      // getCurrentCoin
      const isViewer =
        router.pathname === '/comic/[comicId]/[episodeId]' ||
        router.pathname === '/content/[comicId]/[episodeId]' ||
        router.pathname === '/comicprologue/[comicId]/[episodeId]';

      if (!isViewer) {
        setTimeout(() => {
          window.location.reload();
        }, 300);
      }
    }
  }, [isFinsh]);

  if (!banner) {
    return <>{children}</>;
  }

  const handleBannerClick = (className: string) => {
    if (location.pathname.startsWith('/payment')) return;

    if (banner.link && banner.link.length > 0) {
      window.location.href = banner.link;
      return;
    }

    if (banner.clickActionLayer && banner.clickActionLayer.length > 0) {
      return showClickActionModal({
        imgPath: banner.clickActionLayer,
        onClose: () => {},
      });
    }

    const { token } = UserSession.getUserInfo();

    if (!token) {
      showJoinAndLoginModal('/payment');
    } else if (
      className === 'episodeMo' ||
      className === 'episodeWeb' ||
      className === 'viewer'
    ) {
      openMiniPaymentModal();
    } else {
      window.location.href = '/payment';
    }
  };

  const renderer = ({
    total,
    days,
    hours,
    minutes,
    seconds,
    completed,
    api,
  }: any) => {
    // Countdown이 끝나면
    if (completed) {
      return <></>;
    }

    const timeSliceData = new TimeSliceVM(hours + 24 * days, minutes, seconds); // local로 바꾸니 시간 잘못들어와서 임시로 처리

    const CrazyTimerBannerProps = {
      timeSliceData: timeSliceData.getTimeSliceData(),
      background: banner.background,
      mainImgPath: banner.mainImgPath,
      subImgPath: banner.subImgPath,
      dotImgPath: banner.dot,
      bgColor: banner.colorCode,
      handleBannerClick: handleBannerClick,
      className, // mainTop episodeWeb, episodeMo, paymentTop, paymentMiniTop 에 따라 스타일을 다르게 함.
      isTimer: banner.isTimer,
    };

    /**
     * common : mainTop, episodeWeb, episodeMo 에서 사용
     * payment : paymentTop, paymentMiniTop 에서 사용
     */
    return <CrazyTimerMain {...CrazyTimerBannerProps} />;
  };

  return (
    <>
      {isViewCrazyTimer ? (
        <Countdown
          key={banner.type}
          date={banner.expiredAt}
          renderer={renderer}
          onComplete={e => {
            setIsFinish(true);
          }}
        />
      ) : (
        children
      )}
    </>
  );
};

export default CrazyTimer;
