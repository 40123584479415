import { AtomKey } from '../_constants';
import { atom } from 'recoil';

export interface GnbMenuItemType {
  name: string;
  link: string;
  isActive: boolean;
  mature: number;
}

export interface GnbMenuType {
  main: GnbMenuItemType[];
  sub: GnbMenuItemType[];
}

/**
 * mature : 0(비성인), 1(성인), 2(전체)
 */

const initeGnbMenu: GnbMenuItemType[] = [];

export const GnbMenuState = atom<GnbMenuItemType[]>({
  key: AtomKey.GNB_MENU,
  default: initeGnbMenu,
});
