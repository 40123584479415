import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import { ModalWrapper } from '@src/lib/CustomModal';
import { TopcoImg } from '@src/view/common';
import { useMemo } from 'react';

interface PropsType {
  provider: string;
}

const key = 'LoginErrorNotificationModal';

enum LoginProvider {
  GOOGLE = 'google',
  APPLE = 'apple',
  TWITTER = 'twitterX',
  EMAIL = 'email',
}

export const closeLoginErrorNotificationModal = () => {
  ModalInstance.getInstance().delete(key);
};

export const openLoginErrorNotificationModal = (props: PropsType) => {
  ModalInstance.getInstance().push({
    key,
    shouldCloseOnOverlayClick: true,
    component: <LoginErrorNotificationModal {...props} />,
  });
};

/**
 * 로그인 에러 대비 안내 모달,
 * 대상: applge, google, twitter, email
 */
const LoginErrorNotificationModal = (props: PropsType) => {
  const { provider } = props;
  const loginMethod = useMemo(() => {
    switch (provider) {
      case 'google': {
        return LoginProvider.GOOGLE;
      }
      case 'apple': {
        return LoginProvider.APPLE;
      }
      case 'twitter': {
        return LoginProvider.TWITTER;
      }
      case 'email': {
        return LoginProvider.EMAIL;
      }
    }
  }, [provider]);

  return (
    <ModalWrapper.Default addClassName="w-[88vw] max-w-[350px] p-[35px_19px_40px]">
      <div className="contentCon flex flex-col gap-y-[16px] items-center justify-center">
        <div className="thumbCon w-[86px]">
          <TopcoImg src="/images/error/login/img_login_error.png" />
        </div>
        <h1 className="text-base font-medium mainTxt">We are sorry...</h1>
        <div className="des text-[12px] font-['Popins'] text-black text-center">
          <p className="leading-normal">
            The following {loginMethod} login is currently unavailable.
          </p>
          <p className="leading-normal">
            Please try a different login method or try again later.
          </p>
        </div>
        <button
          type="button"
          className="bg-black w-full max-w-[129px] rounded text-white h-[33px]"
          onClick={closeLoginErrorNotificationModal}
        >
          Got it
        </button>
      </div>
    </ModalWrapper.Default>
  );
};

export default LoginErrorNotificationModal;
