import {
  DomainForm,
  LoginAndJoinBtn,
  PasswordForm,
  PreAuthCheckBox,
  TitleComponent,
} from '../common';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

import { CloseBtn } from '@src/view/common/closeBtn';
import { ErrorMsgV2 } from '@src/lib/utils/userUtils';
import { Event } from '@toptoon-developers/global.toptoonplus.common.lib/dist/gtm/Gtm';
import { GlobalGtm } from '@toptoon-developers/global.toptoonplus.common.lib/dist/gtm/global';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import { SnsBtnTypeCode } from '@src/view/components/ui/snsButtons/constants/SnsBtnTypeCode';
import { SnsButtons } from '@src/view/components/ui/';
import TopcoPageHistory from '@src/lib/utils/topcoPageHistory';
import { TopcoPartner } from '@src/lib/partner';
import { UserBanner } from '@src/view/common/banner';
import { UserDefault, UserSession } from '@src/lib';
import cn from 'clsx';
import { openLoginModal } from '../login/LoginComponent';
import useLogin from '@src/hooks/useLogin';
import { useRouter } from 'next/router';
import { isViewCheckboxList } from '../common/_constants';
import usePreAuth from '@src/hooks/usePreAuth';

const key = 'joinModal';

export const openJoinModal = () => {
  ModalInstance.getInstance().push({
    key,
    shouldCloseOnOverlayClick: false,
    component: <JoinComponent />,
  });
};

export const closeJoinModal = () => {
  ModalInstance.getInstance().delete(key);
};

let doing = false;

/**
 * @TODO: 회원가입 후 동작 정리
 * - 회원가입시 현재페이지 유지
 * - 회원가입완료 레이어 노출 하도록 함
 * @returns
 */
const JoinComponent = () => {
  const { isAuthCheck, isShowForceSignUpForm } = usePreAuth();
  const [message, setMessage] = useState('');
  const [isSubmitBtn, setIsSubmitBtn] = useState(true);

  // signUp 버튼 활성화/비활성화
  const [signUp, setSignUp] = useState(isShowForceSignUpForm);

  const router = useRouter();

  const { emailJoin, loginAfterAction, blockJoinAction } = useLogin();

  // Sign up with email 버튼 클릭시
  const signUpBtnClick = () => {
    blockJoinAction({
      onPossibleJoin: () => {
        setSignUp(true);
      },
      onClose: () => {
        closeJoinModal();
      },
    });
  };

  const initState = () => {
    setMessage('');
  };

  const noticeMessage = (action: string) => {
    switch (action) {
      case 'exist':
        return setMessage('auth fail');
      // TODO: 수정 봇 처리
      case 'robot':
        return setMessage(
          'An error has occurred. Please contact customer support.',
        );
      default:
        return setMessage('unknown');
    }
  };

  const onSubmit = (values: any, actions: any) => {
    if (!signUp) return;

    // GTM - 가입 버튼 클릭
    try {
      GlobalGtm.getInstance().setter({
        event: Event.JOIN_BUTTON_CLICK,
        pCode: TopcoPartner.getter(),
      });
    } catch {
      console.error('gtm');
    }

    setIsSubmitBtn(false);
    if (doing) return;
    doing = true;
    // 회원가입 플래그 체크
    UserDefault.clickSignUpEvent();
    actions.setSubmitting(true);

    const loginId = `${values.email}@${values.domain}`;

    // 회원가입 처리
    emailJoin(loginId, values.password, {
      onLogin() {
        doing = false;
        initState();
        setIsSubmitBtn(true);
        closeJoinModal();
        loginAfterAction();
      },
      onError(action) {
        doing = false;
        noticeMessage(action);
        setIsSubmitBtn(true);
      },
    });
  };

  return (
    <div className="animation_fadein bg-white z-200 max-w-[400px] w-[88vw] max-h-[90vh] overflow-hidden overflow-y-auto scrollbar-hide">
      <div className="relative popupInner">
        <CloseBtn
          imgPath="/images/alert/close/ico_close_white.png"
          className="absolute top-[2px] right-[2px] w-[35px] p-[5px]"
          onClick={(e: any) => {
            TopcoPageHistory.getInstance().clearFix();
            const { action } = router.query;
            if (action && action === 'login') {
              router.replace('/', undefined, { shallow: true });
            } else {
              router.replace(router.asPath, undefined, { shallow: true });
            }
            closeJoinModal();
            setSignUp(false);
          }}
        />

        {/* topBanner */}
        <UserBanner.LoginAndJoin type="join" />
        {/* topBanner */}

        {/* contentArea */}
        <div className="contentCon">
          <Formik
            initialValues={{
              email: '',
              domain: '',
              password: '',
              adCheck: false,
            }}
            validationSchema={ErrorMsgV2()}
            onSubmit={onSubmit}
          >
            {({ values, errors, touched, handleChange }) => {
              return (
                <Form className="p-[0_25px_30px] mo:p-[0.2rem_25px_1.7rem] underMo:p-[1rem] max-w-[350px] mx-auto text-[.8rem] text-[#666] leading-[1.1rem]">
                  {/* snsArea */}
                  <div className={`text-center relative mb-[10px]`}>
                    <TitleComponent
                      title="One-click social media Sign Up"
                      type="snsTit"
                    />
                  </div>
                  <SnsButtons type={SnsBtnTypeCode.Join} />

                  {/* Sign up with email 클릭전 */}
                  {!signUp && (
                    <div>
                      <div className="flex">
                        <div
                          className={cn(
                            "before:content-['']  before:grow-[1] before:mx-[16px] before:h-[1px] before:text-[0px] before:leading-0 before:bg-[#EEEEEE]",
                            'flex basis-full items-center text-[12px] my-[8px]',
                            "after:content-[''] after:grow-[1] after:mx-[16px] after:h-[1px] after:text-[0px] after:leading-0 after:bg-[#EEEEEE]",
                          )}
                        >
                          OR
                        </div>
                      </div>

                      <div className="btnLogin mt-[5px]">
                        <button
                          id="signUpButton"
                          className={cn(
                            `disabled:bg-[#eee] disabled:shadow-none shadow-logInAndJoin max-w-[165px] h-[35px] text-white text-[12px] font-medium rounded-[50px] text-center w-full mx-auto flex justify-center items-center bg-primaryRed`,
                          )}
                          onClick={signUpBtnClick}
                        >
                          Sign up with email
                        </button>
                      </div>
                      {/* 사전인증 체크박스 */}
                      <PreAuthCheckBox isAuthCheck={isAuthCheck} />
                      {/* 사전인증 체크박스 */}
                    </div>
                  )}

                  {/* Sign up with email 클릭후 */}
                  {signUp && (
                    <div>
                      <h1 className="socialLine bg-[#f2f2f5] w-full h-[1px] my-[15px]" />
                      {/* snsArea */}
                      <TitleComponent title="Sign up without vertification" />
                      {/* DomainForm */}
                      <DomainForm
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        type={'join'}
                      />
                      {/* DomainForm */}
                      {/* PasswordForm */}
                      <PasswordForm
                        values={values}
                        message={message}
                        handleChange={handleChange}
                      />
                      <PreAuthCheckBox isAuthCheck={isAuthCheck} />
                      {/* PasswordForm */}
                      {/* joinBtn */}
                      <LoginAndJoinBtn
                        disabled={
                          !(
                            errors.email === undefined &&
                            errors.domain === undefined &&
                            errors.password === undefined &&
                            values.email &&
                            values.domain &&
                            values.password &&
                            isSubmitBtn
                          )
                        }
                        title="Sign Up"
                      />
                      {/* joinBtn */}
                    </div>
                  )}

                  {/* (23.07.19 기획팀 요청으로 체크박스 아예 없앰) */}
                  <div className={cn('adultCheck mt-[10px]')}>
                    <div className="text-[12px] -tracking-[0.2px] leading-[19px] ml-2 text-[#aaa]">
                      By creating this account, you agree to our
                      <br />
                      <a
                        href={`${process.env.REACT_APP_PUBLIC_URL}/terms`}
                        className="text-[#5b96cf]"
                      >
                        Terms of Service
                      </a>{' '}
                      &{' '}
                      <a
                        href={`${process.env.REACT_APP_PUBLIC_URL}/privacy`}
                        className="text-[#5b96cf]"
                      >
                        Privacy Policy
                      </a>
                      .
                    </div>
                  </div>

                  {/* subBtn */}
                  <div
                    className={cn(
                      'sbtLogin overflow-hidden mt-[8px] text-center',
                    )}
                  >
                    <button
                      className="text-black font-semibold underline text-[16px] py-[2px]"
                      type="button"
                      onClick={() => {
                        openLoginModal();
                        closeJoinModal();
                      }}
                    >
                      Sign In
                    </button>
                  </div>
                  {/* subBtn */}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default JoinComponent;
