import _ from 'lodash';
import advanced from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);
dayjs.extend(isSameOrAfter); // 특정 시간이 같거나 이후인지 체크
dayjs.extend(isSameOrBefore); // 특정 시간이 같거나 이전인지 체크
dayjs.extend(isBetween);

export const OPEN_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

/**
 * ANCHOR: 에피소드, 만화 오픈날짜와 종료날짜를 계산해 동일한 시간(전세계 모두)에 노출, 비노출
 * @param data
 * @param publishdAtKey
 * @param closedAtKey
 * @returns
 */
export const isOpenCheck = (
  data: any,
  startAtKey: string,
  endAtKey?: string,
) => {
  // 시작 시간이 없으면 무조건 true
  // 시작 시간이 있으면 현재 시간이 시작시간과 종료시간 사이 인지 비교
  if (!data[startAtKey]) return true;

  const startDate = _.has(data[startAtKey], 'date')
    ? data[startAtKey].date
    : data[startAtKey];

  if (!startDate) return true;
  const start = dayjs(startDate).utc().format(OPEN_DATE_FORMAT);

  let end = null;
  // end없으면 현재시간이 start를 지났는지 비교
  if (!endAtKey || !data[endAtKey]) {
    return dayjs().isSameOrAfter(start);
  }

  end = dayjs(data[endAtKey]).utc().format(OPEN_DATE_FORMAT);

  // 현재 시간이 start와 end 사이에 존재하는지 확인
  return dayjs().isBetween(start, end, undefined, '[]');
};

/**
 * ANCHOR: 만화, 에피소드에 isUpdate 뱃지 체크
 * openAt부터 27시간 동안 유지 (기본로직은 위의 isOpenCheck 함수랑 동작 유사함 end만 +27시간 처리된걸로 변경)
 * @param data
 * @param key
 * @returns
 */
export const isUpdateCheck = (data: any) => {
  if (!data) return false;

  const updateDate = dayjs(data.date).utc().format(OPEN_DATE_FORMAT);

  const limitUpdateTime = dayjs(data.date).add(28, 'hour').utc().format();

  if (!limitUpdateTime) {
    return dayjs().isSameOrAfter(updateDate);
  }
  return dayjs().isBetween(updateDate, limitUpdateTime, undefined, '[]');
};

/**
 * ANCHOR: daily(ongoing), 회차리스트 페이지에서 카테고리를 이용한 완결 체크
 * @param data
 * @param key
 * @returns
 */
export const isCompleteCheck = (data: any, key: string) => {
  if (!data) return false;
  return _.has(data, key)
    ? _.filter(data[key], { depth1: 'complete' }).length > 0
    : false; // 완결확인
};

export const getOpenDate = (data: any, timezone: string) => {
  const { displayPublishedAt, publishedAt } = data;

  const day = (displayPublishedAt ?? '') || (publishedAt.date ?? '');

  // const date = dayjs(data.publishedAt.date).tz(timezone);

  if (timezone === 'Asia/Tokyo') {
    return dayjs(day).tz(timezone).format('MM月 DD,YYYY');
  } else {
    return dayjs(day).utc().format('MMMM DD,YYYY');
  }
};

/**
 * 남은 기간 계산 함수 D 이후는 H M 병행 표기
 * @param t : 목표 날짜 (UTC)
 */
export const remainingDateForDHM = (t: string): string => {
  if (t === null || t === '') return '';

  const now = dayjs().utc();
  const date = dayjs(t).utc();

  let leftTimeDays = '';
  const day = date.diff(now, 'days');
  if (day >= 1) {
    leftTimeDays = `${day} D`;
  }

  let leftTimeHour = '';
  let leftTimeMin = '';
  const hour = dayjs.duration(date.diff(now)).hours();
  if (hour >= 1 && hour < 24) {
    leftTimeHour = ` ${hour} H`;
  }

  if (leftTimeDays === '') {
    const min = dayjs.duration(date.diff(now)).minutes();
    if (min >= 1 && min < 60) {
      leftTimeMin = ` ${min} M`;
    }
  }

  return `${leftTimeDays}${leftTimeHour}${leftTimeMin}`;
};

/**
 * 날짜 포맷 'MM-DD-YYYY',
 * myPage / membershipInfo && AutoPayInfo 사용중
 * @param t, timeZone, fromat
 * @returns
 */
export const remainingDateForMDY = (
  t: string,
  timeZone: string,
  format: string,
): string => {
  if (t === '0' || !t) return '';
  const date = dayjs.tz(t, timeZone);

  return date.format(format);
};

/**
 * ANCHOR: 코인소진 및 문의내역 날짜 영역 포맷용 'MMM DD, YYYY'
 * @param t
 * @param timeZone
 * @returns
 */
export const remainingDateForMMMDDYYY = (t: string) => {
  if (!t) return '';
  const date = dayjs.tz(t, 'EST').format('MMM DD,YYYY');
  return date;
};

/**
 * 기다빵 남은 시간 출력
 * @param time 기다빵 이용 가능한 다음 시간 (nextAt)
 * @param type 사용하는 곳 => 위치에 따라 문구가 달라서 구분자 사용
 * @returns
 */
export const remainingDateForFaw = (
  time: string,
  type: 'episodeList' | 'comicList' = 'episodeList',
): string => {
  const now = dayjs().utc();
  const date = dayjs(time).utc();
  const hour = dayjs.duration(date.diff(now)).hours();
  const min = dayjs.duration(date.diff(now)).minutes();

  // 만화리스트 페이지 (/event)
  if (type === 'comicList') {
    if (hour > 1) return `${hour} hours remaining`; // 복수
    if (hour === 1) return `${hour} hour remaining`; // 단수
    if (min > 1) return `${min} minutes remaining`; // 복수
    return `${min} minute remaining`; // 단수
  }
  // 회차리스트 페이지
  else if (type === 'episodeList') {
    if (hour > 1) return `Free after ${hour} hours`; // 복수
    if (hour === 1) return `Free after ${hour} hour`; // 단수
    if (min > 1) return `Free after ${min} minutes`; // 복수
    return `Free after ${min} minute`; // 단수
  }

  return '';
};

/**
 * 날짜 timestamp로 변환 함수
 * 현재 Genres, Binge, HashTag lastEpisode.publishedAt.date에 사용중
 * @param data
 * @returns
 */
export const getUtcTimestamp = (data: any): number => {
  // if (!TypeCheck.isValidString(data)) return 0;
  if (!data || data === '') return 0;

  return dayjs(`${data}`).utc().valueOf();
};

/**
 * 로컬시간 기준 남은 기간 계산 함수 (day / hour / min 순으로 표기)
 * @param t : 목표 날짜
 */
export const remainingLocalDateForLongDesc = (t: string) => {
  if (t === null || t === '') return '';

  // 백엔드에서 넘어옴 시간 utc format 설정 2022-11-30T17:00:00Z'
  const convertFormat = (time: string) => {
    return dayjs(time).utc().format(OPEN_DATE_FORMAT);
  };
  const now = dayjs().utc();
  const date = dayjs(convertFormat(t));

  const day = date.diff(now, 'days');
  if (day >= 1) {
    return `${day} ${Number(day) === 1 ? 'Day' : 'Days'}`;
  }

  const hour = dayjs.duration(date.diff(now)).hours();
  if (hour >= 1) {
    return `${hour} ${Number(hour) === 1 ? 'Hour' : 'Hours'}`;
  }

  const min = dayjs.duration(date.diff(now)).minutes();
  if (min >= 1) {
    return `${min} Min`;
  }
  return '1 Min';
};

/**
 * myPage membership구독 갱신일 현지 시간으로 표기.
 * @param date
 * @param format
 * @returns
 */
export const remainingLocalDateFormat = (date: string, format: string) => {
  if (!date || date === '') return '';

  return dayjs(date).utc().format(format);
};
