import { atom, selector } from 'recoil';

import { AtomKey } from '../_constants';

export const ShowToolbarState = atom<boolean>({
  key: AtomKey.VIEWER_TOOLBAR,
  default: true,
});

export const ShowEpisodeListState = atom<boolean>({
  key: AtomKey.VIEWER_EPISODE,
  default: false,
});

export const toggleEpisodeList = selector({
  key: AtomKey.VIEWER_EPISODE_TOGGLE,
  get: ({ get }) => {
    return get(ShowEpisodeListState);
  },
  set: ({ set }, newValue) => {
    if (newValue) {
      set(ShowToolbarState, false);
    }
    set(ShowEpisodeListState, newValue);
  },
});
