import { EpisodeType, Reading } from '.';

import { isMemberShip } from './../../lib/utils/utils';

/**
 * ANCHOR: thumnail 데이터 타입
 */
export interface ThumbnailType {
  width: number;
  height: number;
  path: string;
}

/**
 * ANCHOR: 배너용 데이터 타입
 * 이벤트 페이지, 플로팅, 이벤트 배너
 */
export interface BaseBannerType {
  id: number;
  comicId: number;
  images: {
    pc: ThumbnailType | null;
    mobile: ThumbnailType | null;
    default: ThumbnailType | null;
  };
  eventThemeId: number;
  link: string;
  target: string;
}

/**
 * 유저 배너용 타입
 * 메인, 결제, 회차리스트, 뷰어
 */
export interface UserBannerType {
  id: number;
  type: string;
  availableAt: string;
  expireAt: string;
  colorCode: string;
  images: {
    pc: ThumbnailType | null;
    mobile: ThumbnailType | null;
    default: ThumbnailType | null;
  };
  link: string;
  clickActionLayer: ActionLayerType.ActionLayer | null;
}

/**
 * ANCHOR: 만화공용 데이터 타입
 */
export interface BaseComicType {
  comicId: number;
  comicType: number;
  title: string;
  author: string;
  thumbnail: ThumbnailType; // 기존 titleThumbnail key이름 thumbnail로 변경
  isTop10: boolean;
  isUncensored: boolean; // 고수위 작품
  isSale50: boolean; // 고수위 작품
  isFree: boolean;
  isOrigin: boolean;
  isBasedOnNovel: boolean;
  isFreeTicket: boolean;
  isContract: number;
  isNew: boolean;
  isFAW: boolean;
  isUserView: boolean;
  isMature: boolean;
  isUpdate: boolean;
  isAdvertisement: boolean;
  isComplete: boolean;
  viewCount: number;
  likeCount: number;
  firstEpisodeId: number;
  lastEpiTitle: string; // lastEpisode내의 type값에 따라 title값 변경함. 1: 일반 || 3: 에필로그 || null
  readingType: Reading;
  isMembership: boolean;
}

export interface LastEpisodeType {
  lastEpiTitle: string;
  lastEpiType: EpisodeType; //  2: 프롤로그 || 3: 에필로그 || null
}

/**
 * 액션레이어 관련 타입 ========================================
 */

export namespace ActionLayerType {
  export interface ActionLayer {
    actionLayerId: number;
    closeOnOutsideClick: boolean;
    imageUrl: string;
    closeBtnDisplay: boolean;
    closeBtnColorCode: string;
    isCloseLogging: boolean;
    btnWidth: string;
    btnHeight: string;
    btnFontSize: string;
    btnLineHeight: string;
    btnBottom: string;
    btnGap: string;
    buttonList: ActionLayerType.Button[];
  }
  export interface Button {
    buttonId: number;
    action: string;
    url: string;
    isLogging: boolean;
    text: string;
    textColorCode: string;
    bgColorCode: string;
  }
}
