import { ProductBenefit, ProductCoin, ProductPrice } from '.';
import React, { useEffect, useState } from 'react';

import { PaymentWindowModeType } from '../../../data/_constants';
import { ProductItemType } from '../../../context';
import _ from 'lodash';
import cn from 'clsx';

interface PropsType {
  isSelect?: boolean;
  productItem: ProductItemType;
  windowMode: PaymentWindowModeType;
  payType?: string; // 결제수단 paypal에서 문구 제거용 : 모바일에서 너무 비좁아서 기획쪽 요청
  groupType?: string; // 결제수단 paypal에서 문구 제거용 : 모바일에서 너무 비좁아서 기획쪽 요청
  periodValue?: number; // membership 상품 PLAN 개월수 (ex. 1 / 3 / 6)
  periodUnit?: string; // membership 상품 PLAN 개월명 (= MONTHS)
}

const ItemContent = (props: PropsType) => {
  const { productItem, isSelect, windowMode, payType, groupType } = props;
  const {
    coin,
    originalPrice,
    price,
    currencyCode,
    bonusCoin,
    comment,
    description,
    cycle,
    periodUnit,
    periodValue,
  } = productItem;

  return (
    <div
      className={cn(
        'grid min-h-[60px] border-x-[1px] border-b-[1px] border-primary-border dark:border-[#3f3f3f]',
        {
          'bg-[#FFF2F2] dark:bg-[#411C1C]': isSelect && groupType === 'NORMAL',
          'bg-[#F2FFF2] dark:bg-[#172922]':
            isSelect && groupType === 'MEMBERSHIP',
          'bg-primary-bg dark:bg-[#1E1F21]': !isSelect,
          'grid-cols-[30%_30%_1fr] mo:grid-cols-[75px_94px_minmax(160px,_1fr)] forPaymentItem:grid-cols-[23%_23%_1fr]':
            windowMode === PaymentWindowModeType.LARGE,
          'grid-cols-[22%_25%_1fr]': windowMode === PaymentWindowModeType.MINI,
        },
        {
          ['border-t-[2px] border-primary-border dark:border-[#3f3f3f] grid-cols-[22%_1fr] border-l-0']:
            payType === 'paypal',
        },
      )}
    >
      {/* priceArea */}
      {groupType === 'MEMBERSHIP' && (
        <ProductPrice.Membership
          price={Number(price)}
          originPrice={Number(originalPrice)}
          currencyCode={currencyCode}
          windowMode={windowMode}
          payType={payType}
          cycle={cycle}
          periodValue={periodValue}
          periodUnit={periodUnit}
        />
      )}
      {/* priceArea */}

      {/* priceArea */}
      {groupType === 'NORMAL' && (
        <ProductPrice.Normal
          price={Number(price)}
          originPrice={Number(originalPrice)}
          currencyCode={currencyCode}
          windowMode={windowMode}
          payType={payType}
        />
      )}
      {/* priceArea */}

      {/* benefits */}
      {groupType === 'MEMBERSHIP' && (
        <ProductBenefit.Membership
          comment={comment}
          description={description}
          windowMode={windowMode}
          price={price}
          periodValue={periodValue}
          periodUnit={periodUnit}
          groupType={groupType}
        />
      )}
      {/* benefits */}

      {/* benefits */}

      {groupType === 'NORMAL' && (
        <ProductBenefit.Normal
          comment={comment}
          description={description}
          windowMode={windowMode}
          groupType={groupType}
        />
      )}
      {/* benefits */}
      {/* coinArea */}
      {groupType === 'MEMBERSHIP' && (
        <ProductCoin.Membership
          isSelect={isSelect}
          bonusCoin={bonusCoin}
          coin={coin}
          windowMode={windowMode}
          payType={payType}
          price={price}
          cycle={cycle}
          groupType={groupType}
          description={description}
        />
      )}
      {/* coinArea */}

      {/* coinArea */}
      {groupType === 'NORMAL' && (
        <ProductCoin.Normal
          isSelect={isSelect}
          bonusCoin={bonusCoin}
          coin={coin}
          windowMode={windowMode}
          payType={payType}
          groupType={groupType}
          description={description}
        />
      )}
      {/* coinArea */}
    </div>
  );
};

export default ItemContent;
