import { UserSession } from '@src/lib';
import { TopcoPartner } from '@src/lib/partner';
import { isViewCheckboxList } from '@src/view/modal/loginAndJoin/common/_constants';
import React, { useCallback, useMemo } from 'react';

const usePreAuth = () => {
  /**
   * TODO: 정책 유동적
   * 성인인증 체박 노출 조건
   * 사전인증 미인증이고,
   * 사전인증 대상이 아니지만 성인 매체 광고하고있는 파트너 코드 타고 들어온 회원일때
   */
  const isAuthCheck = useMemo(() => {
    if (UserSession.getPreAuthToken()) return false;
    if (
      !UserSession.isUsedPreAuth() &&
      isViewCheckboxList.includes(TopcoPartner.getter())
    ) {
      return true;
    }
    return false;
  }, []);

  /**
   * TODO: 로그인 form 강제 노출 유무 판단
   */
  const isShowForceSignUpForm = useMemo(() => {
    if (
      !UserSession.isUsedPreAuth() &&
      isViewCheckboxList.includes(TopcoPartner.getter())
    ) {
      return true;
    }
    return false;
  }, []);

  return { isAuthCheck, isShowForceSignUpForm };
};

export default usePreAuth;
