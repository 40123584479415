/**
 * 멤버쉽 상수
 */
export enum MembershipCode {
  NOT_SET = 'NOT_SET',
  BASIC = 'MEMBERSHIP_BASIC',
  STANDARD = 'MEMBERSHIP_STANDARD',
  PREMIUM = 'MEMBERSHIP_PREMIUM',
  PEEKIN = 'MEMBERSHIP_PEEK_IN',
}

export enum MembershipType {
  NOT_SET = 'not_set',
  RECURRING = 'recurring', // 정기결제
  NORMAL = 'normal', // 일반 상품 구매 (이용권)
  PEEKIN = 'peekIn', // 시즌패스
}
