import { ErrorMessage, Field, Form, Formik } from 'formik';

import { CloseBtn } from '@src/view/common/closeBtn';
import { ErrorMsg } from '@src/lib/utils/userUtils';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import { TitleComponent } from '../common';
import { UserSession } from '@src/lib';
import cn from 'clsx';
import useAccount from '@src/hooks/useAccount';
import { useToasts } from 'react-toast-notifications';
import { useState } from 'react';

const key = 'resetPasswordModal';

export const openResetPasswordModal = () => {
  ModalInstance.getInstance().push({
    key,
    shouldCloseOnOverlayClick: true,
    component: <ResetPasswordComponent />,
  });
};

export const closeResetPAsswordModal = () => {
  ModalInstance.getInstance().delete(key);
};

const ResetPasswordComponent = () => {
  const { findPassword } = useAccount();
  const { addToast } = useToasts();
  const [isSubmit, setIsSubmit] = useState(false); // 중복 클릭 방지

  const onSubmit = (values: any, actions: any) => {
    findPassword(values.email, {
      onComplete: data => {
        addToast('Email send completed');
        closeResetPAsswordModal();
      },
      onError: err => {
        console.warn(err);
        setIsSubmit(false); // 다시 클릭 가능하게
        if (err.response.status === 500) {
          actions.setFieldError(
            'email',
            'Please enter a correct email address',
          );
        }
      },
    });
  };

  return (
    <div className=" bg-white z-200 max-w-[400px] w-[88vw] max-h-[90vh] overflow-hidden">
      <div className="popupInner relative py-[15px]">
        <CloseBtn
          imgPath="/images/alert/close/ico_close.png"
          className="absolute top-[4px] right-[4px] w-[35px]"
          onClick={closeResetPAsswordModal}
        />

        {/* contentArea */}
        <div className="contentCon">
          <Formik
            initialValues={{
              email: UserSession.getLastUserEmail(),
              password: '',
            }}
            validationSchema={ErrorMsg()}
            onSubmit={(values: any, actions: any) => {
              if (isSubmit) return; // 이미 클릭 했으면 api 통신 X
              else setIsSubmit(true); // 처음 클릭이면 true로 설정

              onSubmit(values, actions);
            }}
          >
            {({ values, errors, touched, handleChange }) => {
              return (
                <Form className="px-[20px] py-[1.2rem] max-w-[350px] mx-auto text-[.8rem] text-[#666] leading-[1.1rem]">
                  <TitleComponent title="Forgot your password?" type="lg" />

                  {/* EmailForm */}
                  <div className={`emailArea`}>
                    <Field
                      className={`emailInput border border-solid border-[#eee] bg-white py-[10px] px-[12px] rounded-[5px] text-[#333] h-[40px] text-[13px] w-full focus:border-primaryRed`}
                      name="email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      placeholder="Enter your email address"
                      autoFocus
                    />
                  </div>
                  {/* EmailForm */}

                  {/* sendBtn */}
                  <div className="btnLogin mt-[10px]">
                    <button
                      id="signButton"
                      className={cn(
                        `disabled:bg-[#eee] disabled:shadow-none shadow-logInAndJoin h-[47px] text-white text-[17px] font-medium rounded-[50px] text-center w-full mx-auto flex justify-center items-center bg-primaryRed`,
                      )}
                      type="submit"
                      disabled={!(errors.email === undefined && values.email)}
                    >
                      Send
                    </button>
                  </div>
                  {errors.email && touched.email && (
                    <ErrorMessage
                      component="p"
                      name="email"
                      className={cn(
                        `erroMsg warning_msg text-primaryRed text-[12px] text-center mt-[4px] mb-[2px] leading-[17px]`,
                      )}
                    />
                  )}
                  {/* sendBtn */}

                  <p className="resetPassDes text-[#333] text-[13px] mt-[15px] leading-[1.1rem] text-center">
                    If you are using a social media account, please reset your
                    password directly on the respective platform.
                  </p>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordComponent;
