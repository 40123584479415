import {
  PaymentWindowModeType,
  ProductGroupType,
} from '../../../data/_constants';

import { ItemContent } from '.';
import { ProductItemType } from '../../../context';
import React from 'react';

interface PropsType {
  isSelect: boolean;
  item: ProductItemType;
  groupType?: ProductGroupType;
  windowMode: PaymentWindowModeType;
  payType?: string; // 결제수단 paypal에서 문구 제거용
  setSelectItem?: (item: ProductItemType) => void;
}
/** 모든 상품 깜싸는 그룹 */
const ProductItem = (props: PropsType) => {
  const { isSelect, item, groupType, windowMode, setSelectItem, payType } =
    props;

  const scrollToPaymentGroup = () => {
    const getPaymentGroup = document.getElementById('paymethodGroup');
    if (!getPaymentGroup) return;
    getPaymentGroup.scrollIntoView({
      block: 'center', // 수직
    });
  };

  return (
    <button
      className="w-full"
      title="product-item"
      type="button"
      onClick={() => {
        setSelectItem && setSelectItem(item);
        scrollToPaymentGroup();
      }}
    >
      <ItemContent
        isSelect={isSelect}
        productItem={item}
        windowMode={windowMode}
        payType={payType}
        groupType={groupType}
      />
    </button>
  );
};
export default ProductItem;
