import { useEffect, useState } from 'react';
import { UserSession } from '../lib/user/UserSession';
import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { TopcoPartner } from '@src/lib/partner';

interface RedirectType {
  pathname: string;
  query: ParsedUrlQuery | null;
}

export const usePartner = (router: NextRouter) => {
  const [redirectUrl, setRedirectUrl] = useState<RedirectType | null>(null);

  useEffect(() => {
    const { token = '' } = UserSession.getUserInfo();

    TopcoPartner.setter(token)
      .then(res => {
        if (res) {
          setRedirectUrl({
            pathname: router.pathname,
            query: router.query,
          });
        } else {
          setRedirectUrl(null);
        }
      })
      .catch(err => {
        setRedirectUrl(null);
      });
  }, []);

  return {
    redirectUrl,
  };
};
