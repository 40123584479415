import {
  ItemMatcherType,
  ProductItemType,
} from '@src/view/components/payment/context';
import {
  PaymentWindowModeType,
  ProductGroupType,
} from '../../../data/_constants';
import React, { useMemo } from 'react';

import { ItemContents } from '../ItemV2';
import { ItemHeader } from '../item';
import { ProductDetail } from '../ProductDetail';
import ProductItem from '../item/ProductItem';
import { UserBanner } from '@src/view/common/banner';
import { UserBannerType } from '@src/middleware/model';
import _ from 'lodash';
import cn from 'clsx';

type ProductItemsType = ProductItemType[] | [];

interface ProductItemsObjType {
  [key: string]: ProductItemsType;
}

interface PropsType {
  windowMode: PaymentWindowModeType;
  groupType: ProductGroupType;
  selectProductItem?: ProductItemType | null;
  productItems: ProductItemsObjType | ProductItemType[] | [];
  userBanner: UserBannerType | null;
  setSelectItem?: (item: ProductItemType) => void;
  itemMatcher: ItemMatcherType[];
}

const ProductList = (props: PropsType) => {
  const {
    productItems,
    groupType,
    windowMode,
    selectProductItem,
    setSelectItem,
    userBanner,
    itemMatcher,
  } = props;

  if (Array.isArray(productItems)) {
    // 맴버십체험 상품인지 체크 "ableFreeTrial":boolean 대상자가 아니면 key값이 없음
    const ableFreeTrialBanner =
      _.findIndex(productItems, 'ableFreeTrial') !== -1;

    if (productItems.length === 0) return null;
    return (
      <>
        {/* productTit */}
        <ProductTit groupType={groupType} windowMode={windowMode}>
          <ProductDetail windowMode={windowMode} groupType={groupType} />
        </ProductTit>
        {/* productTit */}
        {/* banner */}
        <Banner
          userBanner={userBanner}
          groupType={groupType}
          windowMode={windowMode}
          ableFreeTrialBanner={ableFreeTrialBanner}
        />
        {/* banner */}
        <ItemContents
          windowMode={windowMode}
          groupType={groupType}
          selectProductItem={selectProductItem}
          productItems={productItems}
          setSelectItem={setSelectItem}
          itemMatcher={itemMatcher}
        />
        {/* <div className="productCon">
          <ItemHeader groupType={groupType} windowMode={windowMode} />

          {productItems.map(c => {
            return (
              <ProductItem
                key={`product-item-${c.id}`}
                isSelect={selectProductItem?.id === c.id}
                windowMode={windowMode}
                groupType={groupType}
                item={c}
                setSelectItem={setSelectItem}
              />
            );
          })}
        </div> */}
      </>
    );
  } else {
    const productGroups: [string, ProductItemType[]][] =
      Object.entries(productItems);
    return (
      <>
        {/* productTit */}
        <ProductTit groupType={groupType} windowMode={windowMode}>
          <ProductDetail windowMode={windowMode} groupType={groupType} />
        </ProductTit>
        {/* productTit */}

        {/* banner */}
        <Banner
          userBanner={userBanner}
          groupType={groupType}
          windowMode={windowMode}
        />
        {/* banner */}

        {productGroups.map(([key, productItems]) => {
          if (!productItems || productItems?.length === 0)
            return <React.Fragment key={key}></React.Fragment>;

          return (
            <React.Fragment key={key}>
              <ItemContents
                windowMode={windowMode}
                groupType={groupType}
                selectProductItem={selectProductItem}
                productItems={productItems}
                setSelectItem={setSelectItem}
                itemMatcher={itemMatcher}
              />
              {/* <div className="productCon">
                <ItemHeader groupType={key} windowMode={windowMode} />
                {productItems.map(c => {
                  return (
                    <ProductItem
                      key={`product-item-${c.id}`}
                      isSelect={selectProductItem?.id === c.id}
                      windowMode={windowMode}
                      groupType={groupType}
                      item={c}
                      setSelectItem={setSelectItem}
                    />
                  );
                })}
              </div> */}
              {key === ProductGroupType.refill && (
                <div className="mb-[30px] mo:mb-[22px]" />
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  }
};

export default ProductList;

interface ProductTitPropsType {
  windowMode: PaymentWindowModeType;
  groupType: ProductGroupType;
  children?: React.ReactNode;
}
/**
 * ANCHOR: 상품 그룹별 title 명 설정, normal or membership
 * membership상품 없을때 일반상품의 title도 비노출 처리
 */
const ProductTit = (props: ProductTitPropsType) => {
  const { groupType, windowMode, children } = props;
  const tit = useMemo(() => {
    switch (groupType) {
      case ProductGroupType.normal: {
        return 'single payment';
      }
      case ProductGroupType.membership: {
        return 'premium membership';
      }
    }
  }, [groupType]);

  return (
    <div className={cn('flex items-center justify-between mb-[10px]')}>
      <h3
        className={cn('text-[1.1rem] font-[500] uppercase', 'mo:text-[16px]', {
          'text-[16px]': windowMode === PaymentWindowModeType.MINI,
        })}
      >
        {tit}
      </h3>
      {children}
    </div>
  );
};

interface BannerPropsType {
  windowMode: PaymentWindowModeType;
  groupType: ProductGroupType;
  userBanner: UserBannerType | null;
  ableFreeTrialBanner?: boolean;
}
/**
 * ANCHOR: 상품 그룹별 다른 배너 노출, normal or membership
 * NOTE: 운영팀 요청으로 멤버십 상품 배너 노출 조건 수정 함
 * 기존 상품의 "ableFreeTrial" 키값 유무에 따라 서로 다른 이미지 보여 줬으나
 * 해당 키값 안보고 한가지 이미지만 보여주기로 함
 * @returns
 */
const Banner = (props: BannerPropsType) => {
  const { windowMode, groupType, userBanner, ableFreeTrialBanner } = props;
  switch (groupType) {
    case ProductGroupType.normal: {
      return (
        <UserBanner.PaymentTop
          banner={userBanner}
          className={
            windowMode === PaymentWindowModeType.MINI
              ? 'paymentMiniTop'
              : 'paymentTop'
          }
        />
      );
    }
    case ProductGroupType.membership: {
      return <UserBanner.PaymentFreeTrialBanner />;
      // if (ableFreeTrialBanner) {
      //   return <UserBanner.PaymentFreeTrialBanner />;
      // }
      // return <UserBanner.PaymentStaticBanner />;
    }
    default: {
      return null;
    }
  }
};
