import { AtomKey, AtomStorageKey } from '../_constants';

import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export enum AutoScrollModeType {
  OFF = 'off',
  ON = 'on',
  PAUSE = 'pause',
}

export interface AutoScrollType {
  autoScrollMode: AutoScrollModeType;
  autoScrollSpeed: number;
}

const localStorage =
  typeof window !== 'undefined' ? window.localStorage : undefined;

const { persistAtom } = recoilPersist({
  key: AtomStorageKey.VIEWER_AUTO_SCROLL,
  storage: localStorage,
});

export const AutoScrollModeState = atom<AutoScrollModeType>({
  key: AtomKey.VIEWER_AUTO_SCROLL_MODE,
  default: AutoScrollModeType.OFF,
  effects_UNSTABLE: [persistAtom],
});

export const AutoScrollSpeedState = atom<number>({
  key: AtomKey.VIEWER_AUTO_SCROLL_SPEED,
  default: 2,
  effects_UNSTABLE: [persistAtom],
});
