import { CrazyBannerBaseType } from '../../../view/common/crazyTimer/viewModel/CrazyBannerVM';
import { CrazyBannerType } from '@src/view/common/crazyTimer/viewModel';
import { GnbMenuItemType } from '@src/atom';
import { TopcoGlobalProcess } from '../data/TopcoGlobalProcess';
import { UserBannerType } from '@src/middleware/model';

export interface GlobalProcessContext {
  eventCode: string;
  crazyEvent: CrazyEventType | null;
  sidemenuUserBanner: UserBannerType | null;
  loginModalUserBanner: UserBannerType | null;
  topUserBanner: UserBannerType | null;
  baseCrazyBanner: CrazyBannerBaseType | null;
  mainCrazyBanner: CrazyBannerType | null;
  episodeCrazyBanner: CrazyBannerType | null;
  paymentCrazyBanner: CrazyBannerType | null;
  serverCheckTime: GlobalServerCheckTime | null;
  globalProcess: TopcoGlobalProcess;
  isMatureView: number;
  logoPath: string;
  newNoticeCount: number;
}

export interface CrazyEventType {
  crazyEventId: number;
  crazyEventImg: string;
  isLayerTimer: boolean;
}
export interface GlobalServerCheckTime {
  startDate: string;
  endDate: string;
}

export interface UserEventTimeLines {
  id: string;
  userId: string;
  eventId: string;
  eventAt: string;
}

/**
 * 미친결제 레이어 타입 구분 값
 */
export enum CrazyEventCode {
  CRAZY_DURATION = 1, // 정기
  CRAZY_EVENT = 2, // 이벤트 발동
}

export enum CrazyEventCodeType {
  CRAZY_DURATION = 'CRAZY_DURATION',
  CRAZY_EVENT = 'CRAZY_EVENT',
}
