import { PaymentWindowModeType } from '@src/view/components/payment/data/_constants';
import React from 'react';
import { membershipUnit } from '@src/lib/utils/utils';
import cn from 'clsx';
interface PlanProps {
  windowMode: PaymentWindowModeType;
  periodValue?: number; // membership 상품 PLAN 개월수 (ex. 1 / 3 / 6)
  periodUnit?: string; // membership 상품 PLAN 개월명 (= MONTHS)
}

const Plan = (props: PlanProps) => {
  const { periodUnit, periodValue, windowMode } = props;
  return (
    <div
      className={cn(
        'priceCon text-center flex mo:flex-col items-center justify-center font-medium',
        'mo:text-[12px] mo:leading-[12px]',
        {
          'text-[20px] leading-[20px]':
            windowMode === PaymentWindowModeType.LARGE,
          'text-[12px] leading-[12px]':
            windowMode === PaymentWindowModeType.MINI,
        },
      )}
    >
      {/* PLAN */}
      {periodUnit && periodValue && (
        <div
          className={cn('price', {
            // 'text-[26px]': windowMode === PaymentWindowModeType.LARGE,
            // 'text-[24px]': windowMode === PaymentWindowModeType.MINI,
          })}
        >
          {periodValue} {membershipUnit(periodValue, true)}
        </div>
      )}
      {/* PLAN */}
    </div>
  );
};

export default Plan;
