import { useEffect, useState } from 'react';

export const WINDOW_SIZE = {
  LARGE: 1180, // DESKTOP 이상 영역, 이벤트 영역의 사이즈 전환문제로 추가함
  PC: 1024,
  TABLET: 768,
  MOBILE: 767, // 이하
  ENOM: 300,
};

/**
 * window size에 대한 custom hook
 */
const useMediaBreak = () => {
  const [isLargeDesktop, setIsLargeDesktop] = useState(true);
  const [isDesktop, setIsDesktop] = useState(true);
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const windowSizeCheck = () => {
    if (window.innerWidth >= WINDOW_SIZE.LARGE) {
      setIsLargeDesktop(true);
      setIsDesktop(true);
      setIsTablet(false);
      setIsMobile(false);
    } else if (window.innerWidth >= WINDOW_SIZE.PC) {
      setIsLargeDesktop(false);
      setIsDesktop(true);
      setIsTablet(false);
      setIsMobile(false);
    } else if (window.innerWidth >= WINDOW_SIZE.TABLET) {
      setIsLargeDesktop(false);
      setIsDesktop(false);
      setIsTablet(true);
      setIsMobile(false);
    } else {
      setIsLargeDesktop(false);
      setIsDesktop(false);
      setIsTablet(false);
      setIsMobile(true);
    }
  };

  const handle = (e: any) => {
    windowSizeCheck();
  };

  useEffect(() => {
    windowSizeCheck();
    window.addEventListener('resize', handle);
    return () => {
      window.removeEventListener('resize', handle);
    };
  }, []);

  return { isDesktop, isMobile, isTablet, isLargeDesktop };
};

export default useMediaBreak;
