import { ActionLayerType, BaseBannerType, UserBannerType, imgParser } from '.';

import _ from 'lodash';
import { isOpenCheck } from '@src/lib/utils/timeUtils';

/**
 * ANCHOR: 배너 데이터 리스트 형
 * @param data
 * @returns
 */
export const convertBanners = (data: any): BaseBannerType[] => {
  if (!data) return [];

  return _.transform(
    data,
    (result: BaseBannerType[], c) => {
      const banner = convertBanner(c);
      if (!banner) return;
      if (!isOpenCheck(c, 'availableAt', 'expireAt')) return null;

      result.push(banner);
    },
    [],
  );
};

/**
 * ANCHOR: 배너 데이터 처리 기본
 * 이벤트 페이지, 플로팅, 이벤트 배너
 * @param data
 * @returns
 */
export const convertBanner = (data: any): BaseBannerType | null => {
  if (!data || !_.has(data, 'images')) return null;

  const { images, link } = data;

  const result: BaseBannerType = {
    id: data.id ?? 0,
    comicId: data.comicId ?? 0,
    images: {
      pc: imgParser(images.pc, 'webp'),
      mobile: imgParser(images.mobile, 'webp'),
      default: imgParser(images.default, 'webp'),
    },
    eventThemeId: data.eventThemeId ?? 0,
    target: data.target === 1 ? '_self' : '_blank',
    link: link.web ?? '',
  };

  return result;
};

/**
 * ANCHOR: 충전소 배너용
 * 배열로 되어 있어 별도로 추가함.
 * @param data
 * @param index
 * @returns
 */
// export const convertUserBanner = (
//   data: any,
//   index: number = 0,
// ): BaseBannerVM | null => {
//   if (!data[index] || !_.has(data[index], 'images')) return null;

//   const { images, link, id, comicId, eventThemeId, target } = data[index];

//   const result: BaseBannerVM = {
//     id: id ?? 0,
//     comicId: comicId ?? 0,
//     images: {
//       pc: imgParser(images.pc, 'webp'),
//       mobile: imgParser(images.mobile, 'webp'),
//       default: imgParser(images.default, 'webp'),
//     },
//     eventThemeId: eventThemeId ?? 0,
//     target: target === 1 ? '_self' : '_blank',
//     link: link.web ?? '',
//   };

//   return result;
// };

/**
 * 메인, 결제, 회차리스트, 뷰어
 * @param data
 * @returns
 */
export const convertUserBanner = (data: any): UserBannerType | null => {
  if (!data || !_.has(data, 'images')) return null;

  const { images } = data;

  const result: UserBannerType = {
    id: data.bannerId,
    type: data.type,
    images: {
      pc: imgParser(images.pc, 'webp'),
      mobile: imgParser(images.mobile, 'webp'),
      default: imgParser(images.default, 'webp'),
    },
    availableAt: data.availableAt ?? '',
    expireAt: data.expireAt ?? '',
    colorCode: data.colorCode,
    clickActionLayer: convertActionLayer(data.clickActionLayer),
    link: data.link ?? '',
  };

  return result;
};

const convertActionLayer = (data: any): ActionLayerType.ActionLayer | null => {
  if (!data || !data.imageUrl) return null;

  const convertBtn = (buttonList: any) => {
    if (!buttonList) return [];
    return _.transform(
      buttonList,
      (result: ActionLayerType.Button[], c) => {
        if (!c.text) return;
        result.push({
          buttonId: c.buttonId,
          action: c.action,
          url: c.url,
          isLogging: c.isLogging === 1,
          text: c.text,
          textColorCode: c.textColorCode || '#FFFFFF',
          bgColorCode: c.bgColorCode || '#000000',
        });
      },
      [],
    );
  };

  const result: ActionLayerType.ActionLayer = {
    actionLayerId: data.actionLayerId ?? 0,
    closeOnOutsideClick: data.closeOnOutsideClick === 1,
    imageUrl: data.imageUrl,
    closeBtnDisplay: data.closeBtnDisplay === 1,
    closeBtnColorCode: data.closeBtnColorCode,
    isCloseLogging: data.isCloseLogging === 1,
    btnWidth: data.btnWidth || '40%',
    btnHeight: data.btnHeight || '44px',
    btnFontSize: data.btnFontSize || '1rem',
    btnLineHeight: data.btnLineHeight || '1.125rem',
    btnBottom: data.btnBottom || '6%',
    btnGap: data.btnGap || '5px',
    buttonList: convertBtn(data.buttonList),
  };

  return result;
};
