import { CoinRow, CoinRowType } from '@src/view/modal/snbMenu/data/_constants';
import {
  REFRESH_COOKIE_AUTOREFILL_LAYER,
  getNowTimeStamp,
  getSSRCookie,
  getUtcTimeStampByTarget,
} from '../utils/utils';
import { TicketInGetCurrentCoin, UserCoinType, UserInfoType } from './types';
import { TopcoStorage, UserDefault } from '@src/lib';
import {
  TypeCheck,
  _days,
} from '@toptoon-developers/global.toptoonplus.common.lib';

import AppManager from '../app/AppManager';
import Cookies from 'js-cookie';
import { MembershipCode } from '@src/constants';
import { TopcoPartner } from '../partner';
import _ from 'lodash';

export const initUserStorage: UserInfoType = {
  token: '',
  loginId: '',
  provider: '',
  userId: 0,
  mature: 0,
  auth: 0,
};

export const initCoinStorage: UserCoinType = {
  coinInfo: {
    coin: 0,
    bonusCoin: 0,
  },
  refreshAt: '',
  goods: null,
  paymentCount: 0,
  userSubscribe: null,
  waitFreeComic: null,
  loginCount: 0,
  isRefillUser: null,
};

export class UserSession {
  private static USER_SESSION_KEY = 'userSession';
  private static COIN_SESSION_KEY = 'coinSession';
  private static USER_LAST_LOGIN_EMAIL = 'lastUserEmail';
  private static USER_LAST_LOGIN_PROVIDER = 'lastProvider';
  private static PERSIST = 'persist:topco';
  // 로그인 카운트 수집용 cookie
  private static LOGIN_COUNT_CHECK: string = 'login_count_check';

  // 타임 이벤트 수집용 localstorage
  private static GWORKS_LIST: string = 'gworks_list';

  /**
   * ANCHOR: User Fingerprint KEy V3
   * 쿠키로 저장하는 방식으로 처리함
   */
  private static USER_FINGER_PRINT_KEY_V3 = 'u_fp_v3'; // 기존
  private static USER_FINGER_PRINT_KEY_V4 = 'u_fp_v4'; // 암호화(2024.07.18)
  private static VISITOR_ID_NEED_VARIDATE_V4 = 'u_fp_v_v4';
  private static VISITOR_ID_REFRESH_V4 = 'u_fp_r_v4';

  // 자동로그인 localstorage Key
  private static USER_AUTO_LOGIN_KEY = 'u_a';
  // 사전인증토큰
  private static PRE_AUTH_TOKEN = 'pa_t';

  private static UnicodeKeyVisitorId = 'visitorUnicode';

  /*********************************************************************************
   * => ANCHOR : User Info
   *********************************************************************************/

  /**
   * Login 정보 저장
   * @param data
   */
  static setUserInfo = (data: UserInfoType) => {
    const encodeResult = JSON.stringify(data).unicode().encodeString('test');
    this.setLastLoginProvider(data.provider);
    this.clearPreAuthToken(); // 사전인증 토큰 삭제
    UserDefault.PreAuthModalAutoFlag.updateCheck(true); // 자동으로 사전인증 모달 뜨게하는 기능 비활성화
    UserDefault.preAuthCheckBox.clear(); // 사전인증체크박스 상태 삭제
    Cookies.set(this.USER_SESSION_KEY, encodeResult, { expires: 30 });
  };

  /**
   * Cookie에 저장된 userInfo 가져오기
   * @returns {UserLoginInfoType} token, loginId, provider, userId, mature, auth
   */
  static getUserInfo = (): UserInfoType => {
    const objString: string | undefined = Cookies.get(this.USER_SESSION_KEY);

    /**!SECTION
     * 사전인증 가능한 상태이고 사전인증 토큰이 있을 경우 mature 1로 강제 치환
     */
    if (!objString) {
      let mature = initUserStorage.mature;
      if (!AppManager.getInstance().isStoreApp() && !TopcoPartner.getter()) {
        mature = this.getPreAuthToken() ? 1 : 0;
      }

      return {
        ...initUserStorage,
        mature,
      };
    }

    const loginInfo: UserInfoType = JSON.parse(objString.decodeString('test'));

    let mature = Number(loginInfo.mature);
    if (
      !loginInfo.token &&
      !AppManager.getInstance().isStoreApp() &&
      !TopcoPartner.getter()
    ) {
      mature = this.getPreAuthToken() ? 1 : 0;
    }

    return {
      token: loginInfo.token,
      loginId: loginInfo.loginId,
      provider: loginInfo.provider,
      userId: Number(loginInfo.userId),
      mature,
      auth: Number(loginInfo.auth),
    };
  };

  static setMature = (mature: number) => {
    const loginInfo = this.getUserInfo();

    if (loginInfo.token === '') return;

    this.setUserInfo({
      ...loginInfo,
      mature: mature,
    });
  };

  /**
   * 자동 인증 처리 함수
   * @param flag
   */
  static setAutoAuth = (flag: boolean) => {
    const loginInfo = this.getUserInfo();

    if (loginInfo.token === '' || !flag) return;

    this.setUserInfo({
      ...loginInfo,
      auth: 1,
      mature: 1,
    });
  };

  /**
   * Cookie에 저장된 userInfo(token) 리셋
   */
  static clearUserSession = () => {
    const encodeResult = JSON.stringify(initUserStorage)
      .unicode()
      .encodeString('test');
    Cookies.set(this.USER_SESSION_KEY, encodeResult);
    Cookies.remove(REFRESH_COOKIE_AUTOREFILL_LAYER); // 자동충전상품 유도 레이어 쿠키(24시간 만료, AutoRefillModal.tsx)
    this.clearUserCoin();
    this.removeLoginCountCookie();
    this.removeUserEventTimeLines();
    this.clearPreAuthToken(); // 사전인증 토큰 삭제
    UserDefault.signupBenefitModalFlag.dontShowAgain();
  };

  /*********************************************************************************
   * => ANCHOR : 사전인증
   *********************************************************************************/
  /**
   * 사전 인증 (사전 성인 인증) 동작 가능한 대상인지
   */
  static isUsedPreAuth = (): boolean => {
    const { token } = this.getUserInfo();
    if (token) return false;

    if (AppManager.getInstance().isStoreApp()) return false;

    if (TopcoPartner.getter()) return false;

    return true;
  };

  /**
   * 사전인증 토큰 저장(암호화)
   * @param preAuthToken
   */
  static setPreAuthToken = (preAuthToken: string) => {
    if (!preAuthToken) return;
    const encodeResult = JSON.stringify(preAuthToken)
      .unicode()
      .encodeString('test');
    Cookies.set(this.PRE_AUTH_TOKEN, encodeResult);
  };

  /**
   * 사전인증 토큰 가져오기
   * @returns
   */
  static getPreAuthToken = (): string => {
    // 사전인증 토큰 사용가능한 대상자가 아니면 사전인증토큰 삭제.
    if (!this.isUsedPreAuth) {
      this.clearPreAuthToken();
      return '';
    }
    const objString: string | undefined = Cookies.get(this.PRE_AUTH_TOKEN);

    if (!objString) return '';

    const preAuthToken = JSON.parse(objString.decodeString('test'));
    return preAuthToken;
  };

  /**
   * 사전인증 토큰 초기화
   * @returns
   */
  static clearPreAuthToken = () => {
    Cookies.set(this.PRE_AUTH_TOKEN, '');
  };

  /*********************************************************************************
   * => ANCHOR : Coin Info
   *********************************************************************************/

  static setCoinInfo = (data: UserCoinType) => {
    const encodeResult = JSON.stringify(data).unicode().encodeString('test');
    TopcoStorage.setItem(this.COIN_SESSION_KEY, encodeResult);
  };

  /**
   * Cookie에 저장된 coinInfo 가져오기
   */
  static getCoinInfo = (): UserCoinType => {
    const objString: string | null | undefined = TopcoStorage.getItem(
      this.COIN_SESSION_KEY,
    );
    if (!objString) return initCoinStorage;

    const coinInfo: UserCoinType = JSON.parse(objString.decodeString('test'));

    return coinInfo;
  };

  /**
   * LocalStorage 저장된 Coin 리셋
   */
  static clearUserCoin = () => {
    const encodeResult = JSON.stringify(initCoinStorage)
      .unicode()
      .encodeString('test');
    localStorage.setItem(this.COIN_SESSION_KEY, encodeResult);
  };

  static getCoinRowList = () => {
    const list: CoinRowType[] = [];

    const { coinInfo, waitFreeComic, userSubscribe, refreshAt } =
      this.getCoinInfo();

    const coin = coinInfo.coin;
    const bCoin = coinInfo.bonusCoin;
    const fawCount = waitFreeComic?.usableCount;

    list.push({
      type: CoinRow.Coin,
      value: `${coin}`,
      subText: '',
    });

    if (refreshAt) {
      list.push({
        type: CoinRow.Bcoin,
        value: `${bCoin}`,
        subText: _days.remainingDateForDHM(refreshAt ?? ''),
      });
    } else {
      list.push({
        type: CoinRow.Bcoin,
        value: `${bCoin}`,
        subText: '',
      });
    }
    if (userSubscribe?.code !== MembershipCode.NOT_SET) {
      list.push({
        type: CoinRow.MemberShip,
        value: userSubscribe?.code ?? '',
        subText: _days.remainingDateForDHM(userSubscribe?.expiredAt ?? ''),
      });
    }

    return list;
  };

  static getFreeTicketIds = (
    goods: TicketInGetCurrentCoin.RootObject,
  ): number[] => {
    if (!goods.ticket) return [];

    const tempArr: number[] = [];
    // 수령 대기
    let comicIdsRegister = TypeCheck.itemsByPath(goods, 'ticket.register');
    // 수령
    let comicIdsReserve = TypeCheck.itemsByPath(goods, 'ticket.reserve');

    if (comicIdsRegister !== null) {
      const comicIds = TypeCheck.itemsByPath(comicIdsRegister[0], 'comicId');
      if (typeof comicIds === 'string') {
        tempArr.push(Number(comicIds));
      }

      if (typeof comicIds === 'number') {
        tempArr.push(Number(comicIds));
      }
    } else {
      comicIdsRegister = [];
    }

    if (comicIdsReserve !== null) {
      const comicIds = TypeCheck.itemsByPath(comicIdsReserve[0], 'comicId');
      if (typeof comicIds === 'string') {
        tempArr.push(Number(comicIds));
      }

      if (typeof comicIds === 'number') {
        tempArr.push(Number(comicIds));
      }
    } else {
      comicIdsReserve = [];
    }

    const origin = comicIdsRegister.concat(comicIdsReserve);

    const transformIds = _.transform(
      origin,
      (result: number[], n) => {
        const items = TypeCheck.itemsByPath(n, 'comicId.in');

        if (items) {
          items.map((v: number) => {
            return result.push(v);
          });
        }
      },
      [],
    );
    return transformIds;
  };

  /**
   * 헤더 선물함 아이콘에 표시할 개수 정보 수정
   * 기존 register와 reserve내의 'quantity'값 대신
   * register와 reserve의 length값의 합계로 대체 함.
   * @param goods
   * @returns number
   */
  static getFreeTicketCount = (goods: TicketInGetCurrentCoin.RootObject) => {
    if (!goods.ticket) return 0;

    const register = TypeCheck.itemsByPath(goods, 'ticket.register');
    const reserve = TypeCheck.itemsByPath(goods, 'ticket.reserve');

    const ticketItemCount = (
      ticketItem: TicketInGetCurrentCoin.Reserve[] | null,
    ) => {
      if (!ticketItem) return 0;
      return ticketItem.reduce((a, b) => a + (b['quantity'] || 0), 0);
      // return tickeItem.length;
    };
    return ticketItemCount(register) + ticketItemCount(reserve);
  };

  /*********************************************************************************
   * => ANCHOR : Login Info
   *********************************************************************************/

  /**
   * 마지막 로그인한 UserId 저장
   * @param userId
   * @returns
   */
  static setLastUserEmail = (userId: string | undefined | null) => {
    if (!userId || !TopcoStorage.isUsed()) return;

    Cookies.remove(this.USER_LAST_LOGIN_PROVIDER);
    Cookies.set(this.USER_LAST_LOGIN_EMAIL, userId, {
      expires: 30,
    });
  };

  /**
   * 마지막 로그인한 user email
   * @returns aaa@bbbb.xxcccx || ''
   */
  static getLastUserEmail = (): string => {
    return Cookies.get(this.USER_LAST_LOGIN_EMAIL) || '';
  };

  /**
   * 마지막 로그인한 user Id
   * @returns aaa || ''
   */
  static getLastUserId = (): string => {
    const email = Cookies.get(this.USER_LAST_LOGIN_EMAIL) || '';
    if (email === '') return '';
    return email.split('@')[0];
  };

  /**
   * 마지막 로그인한 user domain
   * @returns bbbb.ccc || ''
   */
  static getLastUserDomain = (): string => {
    const email = Cookies.get(this.USER_LAST_LOGIN_EMAIL) || '';
    if (email === '') return '';
    return email.split('@')[1];
  };

  /**
   * 마지막 로그인 provider
   * @param provider
   * @returns
   */
  static setLastLoginProvider = (provider: string | null) => {
    if (!provider || !TopcoStorage.isUsed()) return;
    Cookies.set(this.USER_LAST_LOGIN_PROVIDER, provider, {
      expires: 30,
    });
  };

  /**
   * 마지막 로긍인한 타입
   * @returns
   */
  static getLastLoginProvider = (): string => {
    return Cookies.get(this.USER_LAST_LOGIN_PROVIDER) || '';
  };

  /**********************************************************************************************
   * SSR Function
   **********************************************************************************************/

  static getUserInfoBySSR = (context: any): UserInfoType | null => {
    const cookies = getSSRCookie(context, this.USER_SESSION_KEY);

    try {
      if (cookies) {
        return JSON.parse(cookies.decodeString('test'));
      } else {
        return null;
      }
    } catch (e) {
      console.warn(e);
      return null;
    }
  };

  static getUserIdBySSR(context: any): string {
    const info = this.getUserInfoBySSR(context);
    if (info == null) return '';

    return info.userId === 0 ? '' : `${info.userId}`;
  }

  static getUserInfoTokenBySSR = (context: any): string => {
    const loginInfo: UserInfoType | null = this.getUserInfoBySSR(context);

    if (loginInfo) {
      return loginInfo.token;
    } else {
      return '';
    }
  };

  /**
   * cta=>fe로 이관시 persist(userInfo, coinInfo) 관련 작업
   * 비로그인 상태일때만 cra의 persist를 새로 set합니다.
   * @returns
   */
  static getPersistInfo() {
    const persist = TopcoStorage.getItem(this.PERSIST);
    if (persist) return JSON.parse(persist);
    return null;
  }

  static getPersistUserToken() {
    if (this.getPersistInfo()) {
      const persist = this.getPersistInfo();
      const persist_user = JSON.parse(persist.user);
      return persist_user.accessToken;
    }
    return null;
  }

  static setPersistInfo() {
    try {
      if (this.getPersistInfo()) {
        const persist = this.getPersistInfo();
        const persist_user = JSON.parse(persist.user);
        const persist_mature = JSON.parse(persist.mature);
        const persist_coinInfo = JSON.parse(persist.coinInfo);

        const userInfo: UserInfoType = {
          token: persist_user.accessToken ?? '',
          loginId: persist_user.loginId ?? '',
          provider: persist_user.provider ?? '',
          userId: persist_user.userId,
          mature: persist_mature.matureState,
          auth: persist_mature.auth,
        };
        const coinInfo: UserCoinType = {
          coinInfo: {
            coin: persist_coinInfo.coinInfo.coin,
            bonusCoin: persist_coinInfo.coinInfo.bonusCoin,
          },
          goods: persist_coinInfo.goods,
          refreshAt: persist_coinInfo.refreshAt,
          paymentCount: persist_coinInfo.paymentCount,
          userSubscribe: {
            ...persist_coinInfo.userSubscribe,
            isMembershipCancel: false,
          },
          waitFreeComic: persist_coinInfo.waitFreeComic,
          loginCount: persist_coinInfo.loginCount,
          isRefillUser: persist_coinInfo.isRefillUser,
        };

        this.setCoinInfo(coinInfo);
        this.setUserInfo(userInfo);
      }
    } catch {
      throw new Error('error persist setter');
    }
  }

  static removePersistInfo() {
    if (TopcoStorage.getItem(this.PERSIST))
      TopcoStorage.removeItem(this.PERSIST);
  }

  /**********************************************************************************************
   * 통계용
   **********************************************************************************************/
  /**
   * ANCHOR 로그인 카운트 수집용 쿠키
   * 쿠키가 없을 경우 쿠키를 생성하고 api를 보냄
   * accessToken이 있을때만 동작 (로그인 상태에서만)
   */
  static isSendLoginApi = (token: string): boolean => {
    if (!token || Cookies.get(this.LOGIN_COUNT_CHECK)) {
      return false;
    }
    // 4시간 기준으로 호출 하도록 함.
    const inFifteenMinutes = new Date(
      new Date().getTime() + 4 * 60 * 60 * 1000,
    );

    Cookies.set(this.LOGIN_COUNT_CHECK, 'set', { expires: inFifteenMinutes });
    return true;
  };

  /**
   * 로그인 시점에 해당 쿠키 삭제
   */
  static removeLoginCountCookie = () => {
    Cookies.remove(this.LOGIN_COUNT_CHECK);
  };

  /**
   * 로그인 실패 하거나 로그 아웃시 로컬스토리지의 gworks_list key 삭제
   * gworks_list는 user data sync를 처리하기 위한 로컬스토리지 데이터
   */
  static removeUserEventTimeLines = () => {
    TopcoStorage.removeItem(this.GWORKS_LIST);
  };

  /*********************************************************************************
   * => ANCHOR : User 고유 값
   *********************************************************************************/
  static getFingerPrintId = (): string => {
    try {
      const cookieData = Cookies.get(this.USER_FINGER_PRINT_KEY_V3) || '';
      if (cookieData) return cookieData;

      const encodeData = Cookies.get(this.USER_FINGER_PRINT_KEY_V4) || '';
      if (encodeData) {
        const decodeData = encodeData.decodeString(this.UnicodeKeyVisitorId);
        const data = JSON.parse(decodeData);
        return data;
      }
      return '';
    } catch {
      return '';
    }
  };

  static setFingerPrintId = (value: string) => {
    try {
      Cookies.remove(this.USER_FINGER_PRINT_KEY_V3);
      const encodeUserInfo = JSON.stringify(value)
        .unicode()
        .encodeString(this.UnicodeKeyVisitorId);

      Cookies.set(this.USER_FINGER_PRINT_KEY_V4, encodeUserInfo, {
        expires: 30,
      });
    } catch {
      console.warn('error set cookie visitor id');
    }
  };

  static removeFingerPrintId = () => {
    Cookies.remove(this.USER_FINGER_PRINT_KEY_V3);
    Cookies.remove(this.USER_FINGER_PRINT_KEY_V4);
    Cookies.remove(this.VISITOR_ID_REFRESH_V4);
  };

  // visitorId 검증 api 호출 할것인지
  static isRefreshVisitorId = {
    check: () => {
      const expireTimeStamp = TopcoStorage.getItem(this.VISITOR_ID_REFRESH_V4);
      const nowTimeStamp = getNowTimeStamp();
      // 유효시간이 지나지 않은
      if (nowTimeStamp < Number(expireTimeStamp)) {
        return true;
      }
      // 유효시간이 지남
      TopcoStorage.removeItem(this.VISITOR_ID_REFRESH_V4);
      return false;
    },
    setter: (expireAt: string) => {
      const expires = getUtcTimeStampByTarget(expireAt);
      TopcoStorage.setItem(this.VISITOR_ID_REFRESH_V4, JSON.stringify(expires));
    },
    remove: () => {
      TopcoStorage.removeItem(this.VISITOR_ID_REFRESH_V4);
    },
  };

  /**
   * visitor id 검증 flag(visitor id 발급시 생성)
   * * 1분간 466에러 액션 무효화
   */
  static isNeedValidateVisitorId = {
    getter: () => {
      return Cookies.get(this.VISITOR_ID_NEED_VARIDATE_V4);
    },
    setter: () => {
      const inMinute = new Date(new Date().getTime() + 1 * 60 * 1000);
      Cookies.set(this.VISITOR_ID_NEED_VARIDATE_V4, '1', {
        expires: inMinute,
      });
    },
    remove: () => {
      Cookies.remove(this.VISITOR_ID_NEED_VARIDATE_V4);
    },
  };

  /*********************************************************************************
   * => ANCHOR : 자동 로그인용
   *********************************************************************************/

  /**
   * 자동로그인을 위한 revokeToken 저장
   * @param revokeToken
   */
  static setRevokeToken = (revokeToken: string) => {
    const encodeData = JSON.stringify(revokeToken)
      .unicode()
      .encodeString('test');
    TopcoStorage.setItem(this.USER_AUTO_LOGIN_KEY, encodeData);
  };

  static getRevokeToken = (): string => {
    try {
      const decodeData = TopcoStorage.getItem(this.USER_AUTO_LOGIN_KEY);
      if (!decodeData) return ''; // decodeString은 빈 스트링일시 에러남.

      return JSON.parse(decodeData.decodeString('test')) || '';
    } catch (e) {
      console.log('error', e);
      return '';
    }
  };
  static removeRevokeToken = () => {
    TopcoStorage.removeItem(this.USER_AUTO_LOGIN_KEY);
  };
}
