/**
 * localStorage 처리 class
 */
export class TopcoStorage {
  constructor() {}

  static isUsed() {
    return typeof window !== 'undefined';
  }

  static setItem(key: string, item: string): boolean {
    if (this.isUsed()) {
      localStorage.setItem(key, item);
      return true;
    }
    return false;
  }

  static getItem(key: string): string | null {
    if (this.isUsed()) {
      return localStorage.getItem(key);
    }
    return null;
  }

  static removeItem(key: string): boolean {
    if (this.isUsed()) {
      localStorage.removeItem(key);
      return true;
    }
    return false;
  }

  static setSessionItem(key: string, item: string): boolean {
    if (this.isUsed()) {
      sessionStorage.setItem(key, item);
      return true;
    }
    return false;
  }

  static getSessionItem(key: string): string | null {
    if (this.isUsed()) {
      return sessionStorage.getItem(key);
    }
    return null;
  }

  static removeSessionItem(key: string): boolean {
    if (this.isUsed()) {
      sessionStorage.removeItem(key);
      return true;
    }
    return false;
  }
}
