interface propTypes {
  children: any;
  onDismiss: any;
}

const TopcoCenterToast = ({ children, onDismiss }: propTypes) => {
  return (
    <div className="toast_ep w-full mo:w-[88vw] max-w-[410px] flex items-center justify-center">
      <div className="text-[1rem] w-full text-[#fff] leading-normal traking-[0.2]">
        {children}
      </div>
    </div>
  );
};

export default TopcoCenterToast;
