import { useEffect } from 'react';
import { isMobileSafari } from 'react-device-detect';

interface UseSafariSyncProps {
  isActive: boolean;
}

/**
 * IOS safari 레이어 노출시 URL 영역 고정용
 * @param props
 */
const useSafariSync = (isActive: boolean) => {
  const syncHeight = () => {
    document.documentElement.style.setProperty(
      'height',
      `${window.innerHeight}px`,
    );
  };

  useEffect(() => {
    const body = document.querySelector('body');
    const root = document.getElementById('__next');
    const html = document.querySelector('html');
    const scrollPosition = window.scrollY;
    if (!isMobileSafari || !body || !root || !html) return;

    if (isActive) {
      body.style.position = 'fixed';
      body.style.top = `-${scrollPosition}px`;
      body.style.overflow = 'hidden';
      body.style.width = '100%';
      body.style.height = `${window.innerHeight}px`;

      root.style.overflow = 'hidden';
      html.style.height = `${window.innerHeight}px`;
      window.addEventListener('resize', syncHeight);
    } else {
      body.style.removeProperty('position');
      body.style.removeProperty('top');
      body.style.removeProperty('overflow');
      body.style.removeProperty('width');
      body.style.removeProperty('height');

      root.style.removeProperty('overflow');
      html.style.removeProperty('height');
      window.scrollTo(0, scrollPosition);
      window.removeEventListener('resize', syncHeight);
    }
  }, [isActive]);

  return { isActive };
};

export default useSafariSync;
