import {
  BadgeKey,
  BaseComicType,
  Reading,
  ThumbnailKey,
  ThumbnailMode,
  ThumbnailType,
  imgParser,
  initThumb,
} from '@src/middleware/model';
import { UserCoinType, UserInfoType } from '@src/lib/user/types';
import { UserSession, isOpenCheck, isUpdateCheck } from '@src/lib';

import { MembershipCode } from '@src/constants';
import _ from 'lodash';

/**
 * comicItem용 class
 */
export class BaseModel<T> {
  protected userSession: UserInfoType;
  protected userCoinInfo: UserCoinType;
  protected isMembership: boolean;

  private context: T | null = null;

  constructor() {
    this.userSession = UserSession.getUserInfo();
    this.userCoinInfo = UserSession.getCoinInfo();

    const { token } = this.userSession;
    const { userSubscribe } = this.userCoinInfo;

    if (token === '' || !userSubscribe) this.isMembership = false;

    if (!userSubscribe) this.isMembership = false;
    else if (userSubscribe.code === MembershipCode.PREMIUM)
      this.isMembership = true;
    else this.isMembership = false;
  }

  /**
   * ANCHOR: 만화 데이터 배열 가공(복수)
   * @param data
   * @returns
   */
  protected getComicItems = (data: any): BaseComicType[] => {
    const list = _.transform(
      data,
      (result: BaseComicType[], c) => {
        const comicData = this.convertComicItem(c);

        if (comicData) result.push(comicData);
      },
      [],
    );

    return list;
  };

  /**
   * ANCHOR: 만화 데이터 기본 가공(단수)
   * @param data
   * @returns
   */
  protected convertComicItem = (data: any): BaseComicType | null => {
    const { token, mature } = this.userSession;
    if (!data) return null;

    const { information, author, lastEpisode } = data;

    if (!isOpenCheck(information, 'publishedAt')) return null;

    const badges = data.labels ?? [];

    /**
     * ANCHOR: 멤버십 뱃지 노출 조건 수정
     * 기존 유저정보 + readingType 값을 참조하는 대신
     * 백엔드에서 노출 조건 체크하여 isMembershipComic이라는 키값 주는걸로 변경됨
     */
    let isMemberShip = false;
    if (_.has(data, 'isMembershipComic')) isMemberShip = data.isMembershipComic;

    try {
      const result: BaseComicType = {
        comicId: data.comicId,
        comicType: information.comicType ?? 0,
        title: information.title ?? '',
        author: author.join(' & '),
        thumbnail: this.convertComicThumbnail(
          data,
          ThumbnailKey.THUMBNAIL,
          ThumbnailMode.NORMAL,
        ),
        isTop10: badges.includes(BadgeKey.TOP10),
        isUncensored: badges.includes(BadgeKey.UNCENSORED),
        isSale50: badges.includes(BadgeKey.SALE50),
        isFree: badges.includes(BadgeKey.FREE),
        isOrigin: badges.includes(BadgeKey.ORIGIN),
        isBasedOnNovel: badges.includes(BadgeKey.BASED_ON_NOVEL),
        isFreeTicket: badges.includes(BadgeKey.FreePass),
        isContract: information.isContract ?? 1,
        isNew: badges.includes(BadgeKey.NEW),
        isFAW: badges.includes(BadgeKey.FAW),
        isUserView: data.userView === 1,
        isMature: information.isMature === 1,
        isUpdate: isUpdateCheck(lastEpisode.publishedAt),
        isAdvertisement: data.isAdvertisement ?? false,
        isComplete: badges.includes(BadgeKey.COMPLETE),
        viewCount: information.viewCount ?? 0,
        likeCount: information.likeCount ?? 0,
        firstEpisodeId: data.firstEpisodeId ?? 0,
        lastEpiTitle: this.convertLastEpiTitle(
          lastEpisode.shortTitle, // 기존에 title 바라보던 것 > 관리자화 되면서 shortTitle로 변경됨 (title은 더이상 받아오지 않음)
          lastEpisode.type,
        ),
        isMembership: isMemberShip,
        readingType: information.readingTypeBit ?? Reading.COIN,
      };
      return result;
    } catch (e) {
      console.warn(e);
      return null;
    }
  };

  /**
   * ANCHOR: daily(ongoing), 회차리스트 페이지에서 카테고리를 이용한 완결 체크
   * @deprecated 카테고리말고 label에서 체크하기로함 23.11.1 현정
   * @param data
   * @param key
   * @returns
   */
  protected isCheckComplete = (data: any, key: string) => {
    return _.has(data, key)
      ? _.filter(data[key], { depth1: 'complete' }).length > 0
      : false; // 완결확인
  };

  /**
   * ANCHOR: 작품 썸네일 가공
   * 현재 검색부분만 17금 썸네일 사용중
   * @param props
   * @param key
   * @param mode 1: 강제 비성인 / 2: 강제 성인 / 3: mature 값에 따라 적용
   * @returns
   */
  convertComicThumbnail = (
    data: any,
    key: string,
    // is17?: boolean,
    mode: ThumbnailMode, // 강제 비성인(is17)만 있었는데, 마케팅용 강제 성인이 추가되어 매개변수 변경
  ): ThumbnailType => {
    const { mature } = this.userSession;

    const thumbnail = imgParser(data[`${key}`], 'webp');
    const thumbnail17 = imgParser(data[`${key}17`], 'webp');

    switch (mode) {
      case ThumbnailMode.NON_ADULT: // 강제 비성인
        if (thumbnail17) return thumbnail17;
        return thumbnail ?? initThumb;
      case ThumbnailMode.ADULT: // 강제 성인
        return thumbnail ?? initThumb;
      case ThumbnailMode.NORMAL: // mature 값에 따라 적용
      default:
        if (mature) return thumbnail ?? initThumb;
        if (thumbnail17) return thumbnail17;
        return thumbnail ?? initThumb;
    }
  };

  /**
   * 관리자에서 설정된 값을 가져옴
   * type이 없으면 빈 스트링 리턴
   */
  convertLastEpiTitle = (title: any, type: any) => {
    if (!title || !type) return '';
    return title;
  };

  protected setContext = (context: T) => {
    this.context = context;
  };

  public getContext = (): T => {
    if (this.context) return this.context;
    else throw new Error('exception context data is null');
  };
}
