import { TopcoStorage } from '@src/lib';
import _ from 'lodash';
import alasql from 'alasql';

export interface PageHistoryItemType {
  path: string;
  scroll: number;
  fix: string;
  idx?: number;
}

const TABLE_HISTORY = 'page_history_v1';
const EXCLUDE_LIST = ['/auth', '/complete/xsolla', '/api', '/?action'];

const PageHistoryInit: PageHistoryItemType = {
  path: '/',
  scroll: 0,
  fix: '',
};

export default class TopcoPageHistory {
  private static instance: TopcoPageHistory;

  public constructor() {
    // db 없으면, db 생성
    alasql(`CREATE localStorage DATABASE IF NOT EXISTS toptoonplus;
    ATTACH localStorage DATABASE toptoonplus;
    USE toptoonplus;
    `);
    // 버전 변경 시, 테이블 삭제
    if (this.checkVersion()) {
      this.clearTable();
    }
    this.initTable();
  }

  public static getInstance(): TopcoPageHistory {
    return this.instance || (this.instance = new this());
  }

  /**
   * 테이블 생성 함수
   */
  public initTable() {
    // 테이블 생성
    alasql(
      `CREATE TABLE IF NOT EXISTS ${TABLE_HISTORY} (idx INT AUTO_INCREMENT, path STRING, scroll INT, fix STRING)`,
    );
  }

  /**
   * 테이블 삭제
   */
  public clearTable() {
    alasql(`DROP TABLE  IF EXISTS ${TABLE_HISTORY}`);
  }

  /**
   * 버전이 변경되면 테이블 초기화 시켜주는 함수.
   * TODO: version check 다른 방법 있으면 수정 가능.
   * @returns boolean
   */
  private checkVersion(): boolean {
    const newVersion = `${process.env.REACT_APP_VERSION}`;
    const oldVersion = TopcoStorage.getItem('version') || '';
    const isInit = TopcoStorage.getItem('isInit') || '';

    const old = _.split(oldVersion, '.');
    const now = _.split(newVersion, '.');

    if (oldVersion.length === 0) {
      TopcoStorage.setItem('version', newVersion);
      return false;
    } else if (old[1] !== now[1] || isInit) {
      TopcoStorage.setItem('version', newVersion);
      TopcoStorage.removeItem('isInit');
      return true;
    }
    return false;
  }

  /**
   * 최근 count번째로 저장된 history 가져오는 함수.
   * 1 => 현재 페이지
   * 2 => 이전 페이지
   * @param count
   * @returns {path, scroll, fix} | null
   */
  private getLatestOrderItem(count: number = 1): PageHistoryItemType | null {
    if (count <= 0) return null;

    const item = alasql(
      `SELECT * FROM ${TABLE_HISTORY} ORDER BY idx DESC LIMIT ${count}`,
    );

    if (item.length < count) {
      return null;
    }

    return item[count - 1];
  }

  /**
   * 페이지 history 저장, 최근 방문한 페이지만 중복 저장 안 함.
   * @param page
   */
  public setter(page: PageHistoryItemType) {
    // 제외된 path일 시, 저장하지 않음.
    if (EXCLUDE_LIST.find((i: string) => page.path.startsWith(i))) {
      return;
    }

    if (page.fix.length > 0) {
      // fix로 강제 저장 시, 최근 2번째 페이지가 중복이면 update;
      const last = this.getLatestOrderItem(2);
      if (last && last.path.includes(page.path)) {
        alasql(
          `UPDATE ${TABLE_HISTORY} SET scroll = ?, path = ?, fix = ? WHERE idx = ${last.idx}`,
          [page.scroll, page.path, page.fix],
        );
        return;
      }
    } else {
      // 저장시 최근 방문한 페이지가 중복이면 저장 안 함.
      const last = this.getLatestOrderItem();
      if (last && last.path === page.path) return;
    }

    alasql(`INSERT INTO ${TABLE_HISTORY} VALUES ?`, [page]);
  }

  /**
   * 저장된 history list 가져오기
   * @returns []
   */
  public getter() {
    return alasql(`SELECT * FROM ${TABLE_HISTORY}`);
  }

  /**
   * 가장 최근 저장된 history 가져오기
   * @returns {path, scroll, fix}
   */
  public getLastHistory(): PageHistoryItemType {
    return this.getLatestOrderItem() ?? PageHistoryInit;
  }

  /**
   * 이전에 방문한 history 정보 가져오기(= back)
   * @returns {path, scroll, fix}
   */
  public referer(): PageHistoryItemType {
    return this.getLatestOrderItem(2) ?? PageHistoryInit;
  }

  /**
   * fix가 key인 history 가져오기
   * @param key
   * @returns  {path, scroll, fix} | null
   */
  public getFixHistory(key: string): PageHistoryItemType | null {
    const items = alasql(`SELECT * FROM ${TABLE_HISTORY} WHERE fix = ?`, [key]);

    if (items.length > 0) return items[0];
    else return null;
  }

  /**
   * 페이지 이동 시, 현재 페이지(이동 전 페이지)의 마지막 스크롤 위치를 update
   * @param page
   */
  public updateScroll(page: PageHistoryItemType) {
    const last = this.getLatestOrderItem();

    if (!last) return;
    if (last.idx === 0 || last.fix !== '') return;

    alasql(`UPDATE ${TABLE_HISTORY} SET scroll = ? WHERE idx = ${last.idx}`, [
      page.scroll,
    ]);
  }

  /**
   * 저장된 history 전체 삭제
   */
  public clear() {
    alasql(`TRUNCATE TABLE ${TABLE_HISTORY}`);
  }

  /**
   * fix한 history 삭제
   */
  public clearFix() {
    alasql(`DELETE FROM ${TABLE_HISTORY} WHERE NOT fix = ''`);
  }
}
