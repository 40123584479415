import React from 'react';
import {
  PaymentWindowModeType,
  ProductGroupType,
} from '../../../data/_constants';
import cn from 'clsx';
import { ItemMatcherType } from '../../../context';

interface PropsType {
  windowMode: PaymentWindowModeType;
  itemMatcher: ItemMatcherType[];
  groupType?: ProductGroupType;
}

const ProductHeader = (props: PropsType) => {
  const { windowMode, itemMatcher, groupType } = props;
  return (
    <div
      className={cn(
        'border-t-black border-primary-border bg-[#F6F6F6] grid text-center font-[500] border-solid border-[1px] border-t-[2px]',
        'dark:border-t-[#fff] dark:border-[#3f3f3f] dark:bg-[#2C2D30]',
        'mo:text-[0.85rem] mo:leading-[20px]',

        {
          'grid-rows-[minmax(52px,1fr)] mo:grid-rows-[minmax(40px,1fr)] grid-cols-[30%_30%_1fr] mo:grid-cols-[22%_25%_1fr] text-[18px] leading-[27px]':
            windowMode === PaymentWindowModeType.LARGE &&
            groupType === 'NORMAL',
          'grid-rows-[minmax(40px,1fr)] grid-cols-[22%_25%_1fr] text-[0.85rem] leading-[20px]':
            windowMode === PaymentWindowModeType.MINI && groupType === 'NORMAL',
          'grid-rows-[minmax(52px,1fr)] mo:grid-rows-[minmax(40px,1fr)] grid-cols-[25%_1fr_25%] mo:grid-cols-[22%_1fr_25%] text-[18px] leading-[27px]':
            windowMode === PaymentWindowModeType.LARGE &&
            groupType === 'MEMBERSHIP',
          'grid-rows-[minmax(40px,1fr)] grid-cols-[22%_1fr_25%] text-[0.85rem] leading-[20px]':
            windowMode === PaymentWindowModeType.MINI &&
            groupType === 'MEMBERSHIP',
        },
        '[&>*:nth-child(2)]:border-x-[1px]',
      )}
    >
      {itemMatcher.map(c => {
        return (
          <div key={c.id} className={cn('flex items-center justify-center')}>
            {c.title}
          </div>
        );
      })}
    </div>
  );
};

export default ProductHeader;
