import { useCallback, useEffect, useState } from 'react';

import { CrazyTimer } from '../crazyTimer';
import { GlobalProcessContext } from '@src/responsive/globalProcess/context';
import { TopcoLink } from '../link';
import { UserBannerType } from '@src/middleware/model';
import { UserSession } from '@src/lib';
import cn from 'clsx';
import { globalProcessState } from '@src/atom';
import { useMediaBreak } from '@src/hooks';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';
import useUserBannerClick from './hook/useUserBannerClick';

const UserBanner = (children: React.ReactNode) => {
  return <>{children}</>;
};

/**
 * 메인 이외에는 띄우지 않음.
 * @returns
 */
const MainTop = () => {
  const globalProcessContext = useRecoilValue<GlobalProcessContext | null>(
    globalProcessState,
  );

  const router = useRouter();
  const { bannerClickAction } = useUserBannerClick();
  const { pathname } = router;

  /**
   * 메인 이외에는 띄우지 않음
   * globalProcessContext 가 null이면 띄우지 않음
   */
  if (pathname !== '/' || !globalProcessContext) return null;

  const { mainCrazyBanner, topUserBanner } = globalProcessContext;

  return (
    <CrazyTimer banner={mainCrazyBanner} className="mainTop">
      {topUserBanner && (
        <div
          style={{
            background: topUserBanner.colorCode,
          }}
          className={`mainTop m-0 h-full relative max-h-[66px] overflow-hidden mo:max-h-[100px]`}
        >
          <TopcoLink.Parent
            isPrefetch={false}
            href="/payment"
            onClick={() => {
              // 클릭 이벤트
              bannerClickAction(topUserBanner);
            }}
            classNames="block h-full"
          >
            <picture
              className={`my-0 mx-auto  aspect-[1180/66] mo:aspect-[750/100]`}
            >
              <source
                srcSet={topUserBanner.images.mobile?.path || ''}
                media="(max-width:767px)"
              />
              <img
                src={topUserBanner.images.pc?.path || ''}
                alt=""
                className="mx-auto my-0"
              />
            </picture>
          </TopcoLink.Parent>
        </div>
      )}
    </CrazyTimer>
  );
};

const Gift = ({ banner }: { banner: UserBannerType | null }) => {
  if (!banner || !banner.images.pc) return null;

  return (
    <section className="w-full max-w-[920px] mt-[0] mx-[auto] tablet:mt-[10px]">
      <TopcoLink.Parent href="/payment" onClick={() => {}}>
        <picture className="aspect-[920/180] mo:aspect-[750/160] flex items-center">
          <source
            srcSet={banner.images.mobile?.path}
            media="(max-width: 767px)"
          />
          <img className="w-full" src={banner.images.pc?.path} alt="" />
        </picture>
      </TopcoLink.Parent>
    </section>
  );
};

const Episode = ({
  banner,
  className,
}: {
  banner: UserBannerType | null;
  className: string;
}) => {
  const bannerImg = banner?.images.default;
  const globalProcessContext = useRecoilValue<GlobalProcessContext | null>(
    globalProcessState,
  );
  const { bannerClickAction } = useUserBannerClick();

  // 클릭 이벤트
  const handleClick = useCallback(() => {
    if (!banner) return;
    bannerClickAction(banner, true);
  }, [banner]);

  return (
    <CrazyTimer
      banner={globalProcessContext?.episodeCrazyBanner}
      className={className}
    >
      {bannerImg && (
        <div
          style={{
            aspectRatio: `${bannerImg?.width}/${bannerImg?.height}`,
          }}
          className="lg:block forMoEpisode:block mo:block w-[100%] hidden relative"
          onClick={handleClick}
        >
          <img
            alt={'payment_banner'}
            src={bannerImg?.path}
            className="w-full mo:overflow-hidden"
          />
        </div>
      )}
    </CrazyTimer>
  );
};

/**
 * 에피소드 내의 모달창
 */
const EpisodeMiniBanner = ({
  banner,
  onClose,
}: {
  banner: UserBannerType | null;
  onClose: (e: any) => void;
}) => {
  const bannerImg = banner?.images.default;
  const globalProcessContext = useRecoilValue<GlobalProcessContext | null>(
    globalProcessState,
  );
  const { bannerClickAction } = useUserBannerClick();

  // 클릭 이벤트
  const handleClick = useCallback(
    (e: any) => {
      if (!banner) return;
      if (banner.type !== 'normal') {
        onClose(e);
      }
      bannerClickAction(banner, true);
    },
    [banner],
  );

  return (
    <CrazyTimer
      banner={globalProcessContext?.episodeCrazyBanner}
      className="episodeMo"
    >
      {bannerImg && (
        <div
          style={{
            aspectRatio: `${bannerImg?.width}/${bannerImg?.height}`,
          }}
          className="lg:block forMoEpisode:block mo:block w-[100%] hidden relative"
          onClick={handleClick}
        >
          <img
            alt={'payment_banner'}
            src={bannerImg?.path || ''}
            className="w-full mo:overflow-hidden"
          />
        </div>
      )}
    </CrazyTimer>
  );
};

const PaymentTop = ({
  banner,
  className,
}: {
  banner: UserBannerType | null | null;
  className: string;
}) => {
  const globalProcessContext = useRecoilValue<GlobalProcessContext | null>(
    globalProcessState,
  );
  const { isMobile } = useMediaBreak();

  return (
    <CrazyTimer
      banner={
        className === 'paymentTop'
          ? globalProcessContext?.paymentCrazyBanner
          : globalProcessContext?.episodeCrazyBanner
      }
      className={className}
    >
      {banner && (
        <div
          className={cn(
            'emptyLoading bgContain w-full block relative',
            'mo:bgContain',
          )}
          style={{
            aspectRatio: isMobile
              ? `${banner.images.mobile?.width || ''}/${
                  banner.images.mobile?.height || ''
                }`
              : `${banner.images.pc?.width || ''}/${
                  banner.images.pc?.height || ''
                }`,
          }}
        >
          <picture className="absolute top-0">
            <source
              srcSet={banner.images.mobile?.path || ''}
              media="(max-width: 767px)"
            />
            <img src={banner.images.pc?.path || ''} alt="" />
          </picture>
        </div>
      )}
    </CrazyTimer>
  );
};

interface SideMenuProps {
  isLoginAfter: boolean;
}
const SideMenu = (props: SideMenuProps) => {
  const { isLoginAfter = true } = props;
  const [token, setToken] = useState(UserSession.getUserInfo().token);
  const globalProcessContext = useRecoilValue<GlobalProcessContext | null>(
    globalProcessState,
  );
  const { bannerClickAction } = useUserBannerClick();

  useEffect(() => {
    const { token } = UserSession.getUserInfo();
    setToken(token);
  }, []);

  // 클릭 이벤트
  const handleClick = useCallback(() => {
    if (!globalProcessContext) return;
    if (!globalProcessContext.sidemenuUserBanner) return;

    bannerClickAction(globalProcessContext.sidemenuUserBanner);
  }, [globalProcessContext]);

  if (!globalProcessContext) return null;

  const { sidemenuUserBanner, mainCrazyBanner } = globalProcessContext;

  const bannerImg = sidemenuUserBanner?.images.default;

  return sidemenuUserBanner || mainCrazyBanner ? (
    <div
      className={cn(
        'absolute -bottom-[23px] py-0 px-[13px] w-full max-h-[36px] cursor-pointer overflow-hidden',
        // { 'top-[200px]': !isLoginAfter },
      )}
    >
      <CrazyTimer banner={mainCrazyBanner} className="sideMenu">
        <button
          type="button"
          onClick={handleClick}
          style={{
            display: 'block',
            aspectRatio: `${bannerImg?.width}/${bannerImg?.height}`,
          }}
        >
          {bannerImg && (
            <img
              // style={{
              //   aspectRatio: 274 / 37,
              // }}
              className={'rounded-[8px]'}
              src={bannerImg.path || ''}
              alt="pay-banner"
            />
          )}
        </button>
      </CrazyTimer>
    </div>
  ) : (
    <></>
  );
};

const ViewerBottom = ({ banner }: { banner: UserBannerType | null }) => {
  const globalProcessContext = useRecoilValue<GlobalProcessContext | null>(
    globalProcessState,
  );
  const { bannerClickAction } = useUserBannerClick();

  const bannerImg = banner?.images.default;

  // 클릭 이벤트
  const handleClick = useCallback(() => {
    if (!banner) return;
    bannerClickAction(banner, true);
  }, [globalProcessContext]);

  return (
    <CrazyTimer
      banner={globalProcessContext?.mainCrazyBanner}
      className="viewer"
    >
      {bannerImg && (
        <img
          style={{
            aspectRatio: `${bannerImg.width}/${bannerImg.height}`,
          }}
          className={cn('m-[10px_auto_0] w-full cursor-pointer')}
          src={bannerImg.path}
          alt="payment_banner"
          onClick={handleClick}
        />
      )}
    </CrazyTimer>
  );
};

interface LoginAndJoinPropTypes {
  type: string;
}

const LoginAndJoin = (props: LoginAndJoinPropTypes) => {
  const globalProcessContext = useRecoilValue<GlobalProcessContext | null>(
    globalProcessState,
  );

  return (
    <>
      <div className="loginAndJoinBanner aspect-[720/193] w-full">
        {globalProcessContext && globalProcessContext.loginModalUserBanner && (
          <img
            src={
              globalProcessContext.loginModalUserBanner.images.default?.path ??
              '/images/banner/signin/bnr_top_login_v11.png'
            }
            alt=""
          />
        )}
      </div>
    </>
  );
};

/**
 * 충전소 정적배너, membership 상품그룹 표시용
 * @returns
 */

const PaymentStaticBanner = () => {
  const imgPath = {
    pc: '/images/banner/payment/freeTrial/payment_nonFreeTrial_bnr_pc.png',
    mo: '/images/banner/payment/freeTrial/payment_nonFreeTrial_bnr_mo.png',
  };
  return (
    <div
      className={cn(
        'emptyLoading bgContain w-full aspect-[920/120] ',
        'mo:bgContain mo:aspect-[750/150]',
      )}
    >
      <picture className="">
        <source srcSet={imgPath.mo} media="(max-width: 767px)" />
        <img src={imgPath.pc} alt="" />
      </picture>
    </div>
  );
};

const PaymentFreeTrialBanner = () => {
  const imgPath = {
    pc: '/images/banner/payment/freeTrial/payment_freeTrial_bnr_pc.png',
    mo: '/images/banner/payment/freeTrial/payment_freeTrial_bnr_mo.png',
  };
  return (
    <div
      className={cn(
        'emptyLoading bgContain w-full aspect-[920/120] ',
        'mo:bgContain mo:aspect-[750/150]',
      )}
    >
      <picture className="">
        <source srcSet={imgPath.mo} media="(max-width: 767px)" />
        <img src={imgPath.pc} alt="" />
      </picture>
    </div>
  );
};

/**
 * 기다빵 배너
 * @returns
 */
const WaitFree = ({ banner }: { banner: UserBannerType | null }) => {
  const { isMobile } = useMediaBreak();
  if (!banner || !banner.images.pc) return null;

  return (
    <div
      className={cn(
        'emptyLoading bgContain w-full block relative mt-[30px]',
        'tablet:mt-[10px]',
        'mo:bgContain',
      )}
      style={{
        aspectRatio: isMobile
          ? `${banner.images.mobile?.width || ''}/${
              banner.images.mobile?.height || ''
            }`
          : `${banner.images.pc?.width || ''}/${
              banner.images.pc?.height || ''
            }`,
      }}
    >
      <picture className="absolute top-0">
        <source
          srcSet={banner.images.mobile?.path || ''}
          media="(max-width: 767px)"
        />
        <img src={banner.images.pc?.path || ''} alt="" />
      </picture>
    </div>
  );
};

UserBanner.MainTop = MainTop;
UserBanner.Gift = Gift;
UserBanner.Episode = Episode;
UserBanner.EpisodeMiniBanner = EpisodeMiniBanner;
UserBanner.SideMenu = SideMenu;
UserBanner.PaymentTop = PaymentTop;
UserBanner.ViewerBottom = ViewerBottom;
UserBanner.LoginAndJoin = LoginAndJoin;
UserBanner.PaymentStaticBanner = PaymentStaticBanner;
UserBanner.PaymentFreeTrialBanner = PaymentFreeTrialBanner;
UserBanner.WaitFree = WaitFree;

export default UserBanner;
