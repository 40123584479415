import { useEffect, useState } from 'react';

interface PropsType {
  id: string;
}

const useDomInfo = (props: PropsType) => {
  const { id } = props;

  // id dom에 대한 height
  const [targetHeight, setTargetHeight] = useState<number>(0);

  // document 높이에서 id를 제외하고 남은 영역에 대한 높이
  const [remaingHeight, setRemaingHeight] = useState<number>(0);

  const searchInfo = () => {
    const f = document.getElementById(id);
    if (f) {
      setTargetHeight(f.offsetHeight);
      setRemaingHeight(window.innerHeight - f.offsetHeight);
    }
  };

  const resizeHandle = (e: any) => {
    searchInfo();
  };

  useEffect(() => {
    searchInfo();
    window.addEventListener('resize', resizeHandle);
    return () => {
      window.removeEventListener('resize', resizeHandle);
    };
  }, []);

  return { remaingHeight, targetHeight };
};

export default useDomInfo;
