import { ErrorMessage, Field, FormikErrors, FormikTouched } from 'formik';
import cn from 'clsx';
import { ChangeEvent } from 'react';

interface PropsTypes {
  values: {
    email: string;
  };
  errors: FormikErrors<{
    email: string;
  }>;

  touched: FormikTouched<{
    email: string;
  }>;

  placeholder?: string;
  handleChange: (e: ChangeEvent<any>) => void;
}
const EmailForm = (props: PropsTypes) => {
  const { values, errors, touched, handleChange, placeholder } = props;
  return (
    <div className={`emailArea`}>
      <Field
        className={`emailInput border border-solid border-[#eee] bg-white py-[10px] px-[12px] rounded-[5px] text-[#333] h-[40px] text-[13px] w-full focus:border-primaryRed focus:ring-0 placeholder:text-[11px]`}
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        placeholder={placeholder}
        autoFocus
      />

      {errors.email && touched.email && (
        <ErrorMessage
          component="p"
          name="email"
          className={cn(
            `erroMsg warning_msg text-primaryRed text-[12px] text-left mt-[4px] mb-[2px] leading-[17px]`,
          )}
        />
      )}
    </div>
  );
};
export default EmailForm;
