import { useEffect, useState } from 'react';

import { BonusCoinType } from '../../../context';
import { PaymentWindowModeType } from '../../../data/_constants';
import cn from 'clsx';
import { membershipUnit } from '@src/lib/utils/utils';

interface ProductCoinPropTypes {
  coin: number;
  price?: string;
  cycle?: string;
  bonusCoin: BonusCoinType[];
  windowMode: PaymentWindowModeType;
  isSelect: boolean | undefined;
  payType?: string; // 결제수단 paypal에서 문구 제거용 : 모바일에서 너무 비좁아서 기획쪽 요청
  groupType?: string;
  description: string;
}

const ProductCoin = (child: React.ReactNode) => {
  return <>{child}</>;
};

const Normal = (props: ProductCoinPropTypes) => {
  const { coin, bonusCoin, windowMode, isSelect, payType, groupType } = props;

  return (
    <div
      className={cn(
        'border-primary-border dark:border-x-[#3f3f3f] font-medium flex justify-between items-center',
        {
          'border-x-[1px]': groupType !== 'NORMAL',
          'border-x-none': groupType === 'NORMAL',
          'px-[20px] mo:px-[10px]': windowMode === PaymentWindowModeType.LARGE,
          'px-[10px]': windowMode === PaymentWindowModeType.MINI,
        },
      )}
    >
      <div className="flex items-center">
        <div className="flex items-center justify-center">
          <img
            className={cn({
              'w-[18px] mo:w-[15px] inline-block mr-[6px] mo:mr-[4px]':
                windowMode === PaymentWindowModeType.LARGE,
              'mr-[4px] w-[15px]': windowMode === PaymentWindowModeType.MINI,
            })}
            src="/images/payment/icon_coin.png"
            alt="coin"
          />
        </div>
        <div
          className={cn({
            'text-[0.9rem] leading-[20px] mo:text-[0.78rem] mo:leading-[17px]':
              windowMode === PaymentWindowModeType.LARGE,
            'text-[13px] mo:text-[0.78rem] leading-[18px] forPaymentItemMINI:text-left':
              windowMode === PaymentWindowModeType.MINI,
          })}
        >
          <span>{coin} </span>
          {bonusCoin.map((bCoin, index) => {
            return (
              <span
                key={`bCoin-item-${index}`}
                className={cn({
                  ['text-[#64B5FF]']: index === 0,
                  ['text-[#FF323A]']: index === 1,
                })}
              >
                {` + ${bCoin.coin}`}
              </span>
            );
          })}
        </div>
      </div>
      <div
        className={cn(
          ' text-[#fff] flex items-center justify-center leading-[24px] text-center rounded-[3px]',
          'mo:min-w-[40px] mo:min-h-[25px]',
          {
            'bg-primary-button-bg-point dark:bg-[#FF323A]': isSelect,
            'bg-primary-button-bg-disabled dark:bg-[#666]': !isSelect,
            'min-w-[66px] min-h-[30px] text-[16px] mo:text-[11px]':
              windowMode === PaymentWindowModeType.LARGE,
            'min-w-[40px] min-h-[25px] text-[11px]':
              windowMode === PaymentWindowModeType.MINI,
            ['hidden']: payType === 'paypal',
          },
        )}
      >
        Buy
      </div>
    </div>
  );
};

const Membership = (props: ProductCoinPropTypes) => {
  const { price, cycle, windowMode, isSelect, payType, description } = props;
  const cycleString = Number(cycle?.substring(0, 1));

  const [descMessage, setDescMessage] = useState('');

  // Details에 들어가는 상품별 메세지 출력 set
  useEffect(() => {
    switch (cycleString) {
      case 1: {
        setDescMessage('Monthly');
        break;
      }
      case 3: {
        setDescMessage('Quarterly');
        break;
      }
      case 6: {
        setDescMessage('Semi-annual');
        break;
      }
    }
  }, []);

  return (
    <div
      className={cn(
        'border-primary-border dark:border-x-[#3f3f3f] price flex justify-between items-center gap-x-[3px] text-left py-[14px]',
        {
          'text-[14px] px-[20px] mo:text-[12px] mo:px-[10px]':
            windowMode === PaymentWindowModeType.LARGE,
          'text-[12px] px-[10px]': windowMode === PaymentWindowModeType.MINI,
        },
      )}
    >
      {/* <div className="leading-[1rem]">
        <ul
          className={cn({
            'px-[15px] mo:px-[10px]  break-keep':
              windowMode === PaymentWindowModeType.LARGE,
            'px-[10px] break-keep': windowMode === PaymentWindowModeType.MINI,
          })}
        > */}
      {/* ANCHOR: 기획팀 요청으로 비노출 처리 */}
      {/* <li className="list-disc marker:text-[4px] break-keep">
            Unlimited Access
          </li> */}
      {/* <li className="list-disc marker:text-[4px] break-keep">
            Billed ${price} / Every {cycleString} {membershipUnit(cycleString)}{' '}
          </li> */}
      {/* TODO: 두번째 li부터 margin-top: 3px 추가 */}
      {/* <li className="list-disc marker:text-[4px] mt-[3px] break-keep">
            {descMessage} subscription
          </li>
        </ul>
      </div> */}
      <div dangerouslySetInnerHTML={{ __html: description }}></div>

      <div
        className={cn(
          'flex items-center justify-center text-[#fff] leading-[24px] text-center rounded-[3px]',
          'mo:min-w-[40px] mo:min-h-[25px]',
          {
            'bg-[#3C9D68]': isSelect,
            'bg-primary-button-bg-disabled dark:bg-[#666]': !isSelect,
            'min-w-[66px] min-h-[30px] text-[16px] mo:text-[11px]':
              windowMode === PaymentWindowModeType.LARGE,
            'min-w-[40px] min-h-[25px] text-[11px]':
              windowMode === PaymentWindowModeType.MINI,
            ['hidden']: payType === 'paypal',
          },
        )}
      >
        Join
      </div>
    </div>
  );
};

ProductCoin.Normal = Normal;
ProductCoin.Membership = Membership;
export default ProductCoin;
