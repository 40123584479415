import { BonusCoinType, ProductItemType } from '../../../context';
import {
  PaymentWindowModeType,
  ProductGroupType,
} from '../../../data/_constants';

import React from 'react';
import _ from 'lodash';
import cn from 'clsx';

interface PropsType {
  windowMode: PaymentWindowModeType;
  groupType?: string;
}

const ItemHeader = (props: PropsType) => {
  const { windowMode, groupType } = props;

  switch (groupType) {
    case ProductGroupType.normal:
    case ProductGroupType.membership: {
      return (
        <>
          {/* item-Header */}
          <div
            className={cn(
              'border-t-black border-primary-border bg-[#F6F6F6] grid text-center font-[500] border-solid border-[1px] border-t-[2px]',
              'dark:border-t-[#fff] dark:border-[#3f3f3f] dark:bg-[#2C2D30]',
              'mo:text-[0.85rem] mo:leading-[20px]',
              {
                'grid-rows-[minmax(52px,1fr)] mo:grid-rows-[minmax(40px,1fr)] grid-cols-[30%_30%_1fr] text-[18px] leading-[27px] mo:grid-cols-[75px_94px_minmax(160px,_1fr)]':
                  windowMode === PaymentWindowModeType.LARGE,
                'grid-rows-[minmax(40px,1fr)] grid-cols-[22%_25%_1fr] text-[0.85rem] leading-[20px]':
                  windowMode === PaymentWindowModeType.MINI,
              },
            )}
          >
            <div className="flex items-center justify-center">
              {groupType === 'NORMAL' ? 'Pricing' : 'PLAN'}
            </div>
            <div className="flex items-center justify-center border-x-[1px]">
              {groupType === 'NORMAL' ? 'Benefit' : 'PRICE'}
            </div>
            <div className="border-x-primary-border dark:border-x-[#3f3f3f] flex items-center justify-center min-h-[47px]  mo:min-h-[40px]">
              {groupType === 'NORMAL' ? 'Coin + Bonus' : 'BENEFITS'}
            </div>
          </div>
          {/* item-Header */}
        </>
      );
    }
    case ProductGroupType.refill: {
      return (
        <div
          className={cn(
            'bg-[#FFF9D6] dark:bg-[#3E3817] border-t-black flex items-center py-[12px] px-[18px] border-[1px] border-t-[2px] mo:py-[10px] mo:px-[6px]',
            {
              'py-[10px] px-[6px]': windowMode === PaymentWindowModeType.MINI,
            },
          )}
        >
          <div className="flex items-center justify-center">
            <img
              className="w-[26px]"
              src="/images/payment/light/icon_auto.png"
              alt="ico_auto"
            />
          </div>
          <div
            className={cn(
              'flex ml-[5px]',
              {
                'items-center mo:items-start mo:flex-col':
                  windowMode === PaymentWindowModeType.LARGE,
              },
              {
                'items-start flex-col':
                  windowMode === PaymentWindowModeType.MINI,
              },
            )}
          >
            <div className={cn('flex items-center')}>
              <div
                className={cn(
                  'font-[600] text-[#FFA825] dark:text-[#FFCF26] ',
                  {
                    'text-[18px] mo:text-[13px]':
                      windowMode === PaymentWindowModeType.LARGE,
                  },
                  {
                    'text-[13px]': windowMode === PaymentWindowModeType.MINI,
                  },
                )}
              >
                Auto Reload:{' '}
              </div>
              <div
                className={cn(
                  'text-primary-text dark:text-[#fff] ml-[5px]',
                  {
                    'text-[18px] mo:text-[13px] font-[600]':
                      windowMode === PaymentWindowModeType.LARGE,
                  },
                  {
                    'text-[13px]': windowMode === PaymentWindowModeType.MINI,
                  },
                )}
              >
                Hassle free!{' '}
              </div>
            </div>
            <div
              className={cn(
                'text-primary-text-info dark:text-[#8F8756]',
                {
                  'text-[15px] mo:text-[11px] mo:leading-[11px] mo:font-[500] ml-[5px] mo:ml-0':
                    windowMode === PaymentWindowModeType.LARGE,
                },
                {
                  'text-[11px] leading-[11px] font-[500] ml-0':
                    windowMode === PaymentWindowModeType.MINI,
                },
              )}
            >
              Auto reloads when below 10 coins
            </div>
          </div>
        </div>
      );
    }

    default: {
      return <></>;
    }
  }
};

export default ItemHeader;
