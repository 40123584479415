import React, { useMemo } from 'react';
import {
  ProductItemType,
  ItemMatcherType,
} from '@src/view/components/payment/context';
import { UserBannerType } from '@src/middleware/model';
import {
  PaymentWindowModeType,
  ProductGroupType,
} from '../../../data/_constants';
import ProductItem from './ProductItem';
import cn from 'clsx';
import { Plan, Price, Benefit } from './Piece';
import ProductHeader from './ProductHeader';
import RefillHeader from './RefillHeader';

type ProductItemsType = ProductItemType[] | [];

interface ProductItemsObjType {
  [key: string]: ProductItemsType;
}

interface PropsType {
  windowMode: PaymentWindowModeType;
  groupType: ProductGroupType;
  selectProductItem?: ProductItemType | null;
  productItems: ProductItemType[] | [];
  setSelectItem?: (item: ProductItemType) => void;
  itemMatcher: ItemMatcherType[];
}
const ItemContents = (props: PropsType) => {
  const {
    productItems,
    groupType,
    windowMode,
    selectProductItem,
    setSelectItem,
    itemMatcher,
  } = props;

  if (productItems.length === 0) return null;
  return (
    <div className={cn('productCon', '[&_.size_match]:text-[#e5e5e5]')}>
      {productItems[0].groupString === 'REFILL' ? (
        <RefillHeader windowMode={windowMode} />
      ) : (
        <ProductHeader
          windowMode={windowMode}
          itemMatcher={itemMatcher}
          groupType={groupType}
        />
      )}
      {productItems.map(c => {
        return (
          <ProductItem
            key={`product-item-${c.id}`}
            isSelect={selectProductItem?.id === c.id}
            windowMode={windowMode}
            groupType={groupType}
            item={c}
            setSelectItem={setSelectItem}
            itemMatcher={itemMatcher}
          />
        );
      })}
    </div>
  );
};

export default ItemContents;
