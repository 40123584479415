import { UserDefault, UserSession } from '@src/lib';
import { closeConfirmModal, openConfirmModal } from './ConfirmModal';

import ApiPayment from '@toptoon-developers/global.toptoonplus.common.lib/dist/apiV2/client/V1/ApiPayment';
import AppManager from '@src/lib/app/AppManager';
import { CloseBtn } from '@src/view/common/closeBtn';
import Cookies from 'js-cookie';
import { Event } from '@toptoon-developers/global.toptoonplus.common.lib/dist/gtm/Gtm';
import { GlobalGtm } from '@toptoon-developers/global.toptoonplus.common.lib/dist/gtm/global';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import { ModalWrapper } from '@src/lib/CustomModal';
import { PaymentWindowModeType } from '@src/view/components/payment/data/_constants';
import PaypalBtn from './PaypalBtn';
import { ProductItem } from '@src/view/components/payment/view/product/item';
import { ProductItemType } from '@src/view/components/payment/context';
import { TopcoPartner } from '@src/lib/partner';
import { TypeCheck } from '@toptoon-developers/global.toptoonplus.common.lib';
import _ from 'lodash';
import { closeMiniPaymentModal } from './MiniPaymentModal';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { Marketing } from '@src/lib/utils/Marketing';

const key = 'payPalModal';
interface openPaypalPropTypes {
  orderId: string;
  product: ProductItemType;
  windowMode: PaymentWindowModeType | undefined;
}

export const openPaypalModal = (props: openPaypalPropTypes) => {
  ModalInstance.getInstance().push({
    key,
    shouldCloseOnOverlayClick: true,
    component: <PaypalModal {...props} />,
  });
};

export const closePaypalModal = () => {
  ModalInstance.getInstance().delete(key);
};

const PaypalModal = (props: openPaypalPropTypes) => {
  const { orderId, product, windowMode } = props;

  const router = useRouter();

  const confirmAction = useCallback(async () => {
    // 코인 정보 갱신
    Cookies.remove('coinRefresh');

    // GTM - 결제완료
    const count = await new ApiPayment(UserDefault.getUserSession())
      .getCountByUserId()
      .then((res: any) => {
        const payCount = _.has(res, 'paymentCount') ? res.paymentCount + 1 : 1;
        return payCount;
      })
      .catch(err => {
        console.error(err);
        return 1;
      });

    GlobalGtm.getInstance().setter({
      event: Event.PURCHASE,
      pCode: TopcoPartner.getter(),
      params: {
        purchase: {
          id: orderId,
          value: product.price,
          currency: product.currencyCode,
          count,
        },
        user: {
          loginId: UserSession.getUserInfo().loginId,
        },
      },
    });
    //trafficJunky 매체 결제 완료
    Marketing.TrafficJunky.sendPurchaseApi({
      id: orderId,
      value: Number(product.price),
      currency: product.currencyCode,
    });

    if (AppManager.getInstance().isApp() === true) {
      AppManager.getInstance().action.reload();
    } else if (windowMode === PaymentWindowModeType.LARGE) {
      router.back();
    } else {
      // 확인 모달 닫음
      closeConfirmModal('confirmModal');

      // close miniPayment
      closeMiniPaymentModal();

      router.reload();
    }
  }, [product, orderId]);

  const failureAction = useCallback(() => {
    closePaypalModal();

    const key = 'paymentFailModal';

    openConfirmModal({
      key,
      msg: 'Your Purchase Could Not Be Completed',
      callback: {
        onClose(e) {
          closeConfirmModal(key);
        },
        onConfirm(e) {
          closeConfirmModal(key);
        },
      },
    });
  }, []);

  const onResponse = (res: any, message: string) => {
    const status = TypeCheck.itemsByPath(res, 'status');
    const info = res.data.data.order;
    const currency = info.currencyCode;
    const price = info.amount;
    const { orderId } = info;

    if (status === 200) {
      closePaypalModal();
      const key = 'confirmModal';
      openConfirmModal({
        key,
        msg: 'Thank You for Your Purchase!',
        callback: {
          onConfirm(e) {
            confirmAction();
          },
          onClose(e) {
            confirmAction();
          },
        },
      });
      return;
    }
    failureAction();
  };

  return (
    <ModalWrapper.Default
      id="payPalModalCon"
      addClassName="bg-white w-[88vw] max-w-[450px] relative bg-secondaryBlack"
    >
      <CloseBtn
        imgPath="/images/alert/close/ico_close_white.png"
        className="w-[20px] mo:w-[16px] absolute right-[10px] top-[10px]"
        onClick={() => {
          closePaypalModal();
        }}
      />

      <div className="payPalContent p-[40px_10px_20px] flex flex-col gap-y-[10px]">
        <div className="contentInner mt-[10px]">
          <ProductItem
            isSelect={false}
            item={product}
            windowMode={PaymentWindowModeType.MINI}
            payType="paypal"
          />
        </div>

        <PaypalBtn
          product={product}
          orderId={orderId}
          onResponse={onResponse}
          onCancel={closePaypalModal}
        />
      </div>
    </ModalWrapper.Default>
  );
};

export default PaypalModal;
