import {
  LastPaymentMethodType,
  PaymentContext,
  PgType,
  ProductItemType,
} from '../context';
import {
  PayLetterPurchase,
  PaymentWallPurchase,
  PaypalPurchase,
} from '../action';
import { PaymentWindowModeType, ProductGroupType } from '../data/_constants';
import {
  REFRESH_COOKIE_AUTOREFILL_LAYER,
  getRefreshCookie,
  setRefreshCookie,
} from '@src/lib/utils/utils';
import { UserDefault, UserSession } from '@src/lib';
import {
  closeAutoRefillModal,
  openAutoRefillModal,
} from '@src/view/modal/payment';
import { useCallback, useEffect, useMemo } from 'react';

import AppManager from '@src/lib/app/AppManager';
import Cookies from 'js-cookie';
import { EximbayPurchase } from '../action/EximbayPurchase';
import { PaymentWallRefillPurchase } from '../action/PaymentWallRefillPurchase';
import { StripePurchase } from '../action/StripePurchase';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { usePaymentData } from '../data';
import useStore from '../data/usePaymentReducer';

interface WithPaymentHocProps {
  windowMode: PaymentWindowModeType;
  isStoreApp: boolean;
  WrappedComponent: React.ComponentType<PaymentContext>;
}

const WithPaymentHoc: React.FC<WithPaymentHocProps> = props => {
  const { WrappedComponent, isStoreApp, windowMode } = props;

  const store = useStore();
  const { data: paymentContext } = usePaymentData();

  // 관리자에서 기본으로 설정한 default 상품이 있다면, 처음 진입시 선택되도록 설정.
  useEffect(() => {
    if (!paymentContext || !paymentContext.defaultProduct) return;
    store.setSelectItem(paymentContext.defaultProduct);
  }, [paymentContext]);

  /**
   * ANCHOR: 자동충전 상품 유도 레이어 및 기타 상품 PG 연결 함수
   */
  const purchaseAction = useCallback(
    (
      pgItem: PgType.MethodItemType,
      context: PaymentContext,
      isUseLastPayMethod: boolean, // 마지막 결제 수단 버튼을 클릭해서 prepare 요청하는지 구분 키 (멤버십은 구분x)
    ) => {
      if (!store.selectItem) return;
      const { linkedProduct, groupString } = store.selectItem;
      /**
       * ANCHOR: 자동충전 상품 유도 레이어 노출조건
       * 일반상품 + linkedProduct + pgItem==='peymentwall' + "_r_c_e_d" 쿠키 없어야 함
       */
      const isCheckAutoRefillLayer =
        groupString === ProductGroupType.normal &&
        linkedProduct &&
        pgItem.pg === 'paymentwall' &&
        !getRefreshCookie(REFRESH_COOKIE_AUTOREFILL_LAYER);

      if (isCheckAutoRefillLayer) {
        openAutoRefillModal({
          selectItem: store.selectItem,
          callback: {
            onConfirm: () => {
              closeAutoRefillModal();
              PaymentWallRefillPurchase.paymentWallRefillPurchase(
                context,
                pgItem.method,
                store.selectItem?.linkedProduct ?? null,
              );
            },
            onClose: () => {
              setRefreshCookie(REFRESH_COOKIE_AUTOREFILL_LAYER);
              closeAutoRefillModal();
              store.selectItem &&
                prucahsesActionProc(
                  pgItem,
                  context,
                  store.selectItem,
                  isUseLastPayMethod,
                );
            },
          },
        });
      } else {
        prucahsesActionProc(
          pgItem,
          context,
          store.selectItem,
          isUseLastPayMethod,
        );
      }
    },
    [store.selectItem],
  );

  /**
   * ANCHOR: 상품 결제 PG 함수
   * @param pgItem
   * @param context
   * @param isUseLastPayMethod
   * @returns
   */
  const prucahsesActionProc = useCallback(
    (
      pgItem: PgType.MethodItemType,
      context: PaymentContext,
      selectItem: ProductItemType,
      isUseLastPayMethod: boolean, // 마지막 결제 수단 버튼을 클릭해서 prepare 요청하는지 구분 키 (멤버십은 구분x)
    ) => {
      const { groupString } = selectItem;
      Cookies.remove('coinRefresh');
      AppManager.getInstance().currentPage.setter();

      /**
       * ANCHOR: 기존 결제 함수들을 상품그룹별로 하단에서 함수로 분리 처리함
       */
      switch (groupString) {
        case ProductGroupType.membership: {
          // 멤버십 상품 결제
          prucahsesActionProcMembership(pgItem, context, isUseLastPayMethod);
          break;
        }
        case ProductGroupType.refill: {
          // 자동충전 상품 결제
          prucahsesActionProcRefill(pgItem, context, selectItem);
          break;
        }
        default: {
          // 일반 상품 결제
          prucahsesActionProcNormal(pgItem, context, isUseLastPayMethod);
          break;
        }
      }
    },
    [],
  );

  /**
   * ANCHOR: 멤버십 상품 결제
   */
  const prucahsesActionProcMembership = useCallback(
    (
      pgItem: PgType.MethodItemType,
      context: PaymentContext,
      isUseLastPayMethod: boolean, // 마지막 결제 수단 버튼을 클릭해서 prepare 요청하는지 구분 키 (멤버십은 구분x)
    ) => {
      switch (pgItem.pg) {
        case 'eximbay': {
          EximbayPurchase.selectorForPopupRecurring(context);
          break;
        }
        case 'payletter': {
          PayLetterPurchase.payLetterAutoPurchase(context);
          break;
        }
        case 'paymentwall': {
          PaymentWallPurchase.paymentWallRecurringPurchase(
            context,
            pgItem.method,
          );
          break;
        }
        case 'xsolla': {
          if (Cookies.get('topcoTest')) {
            // 테스트중
            UserDefault.nonMatureSiteMove(context, pgItem);
          } else {
            PaypalPurchase.paypalRecurringSelector(
              context,
              pgItem.method,
              isUseLastPayMethod,
            );
          }
          break;
        }
        default: {
          console.warn('not found work', pgItem);
        }
      }
    },
    [],
  );

  /**
   * ANCHOR: 자동충전 상품 결제
   */
  const prucahsesActionProcRefill = useCallback(
    (
      pgItem: PgType.MethodItemType,
      context: PaymentContext,
      selectItem: ProductItemType,
    ) => {
      switch (pgItem.pg) {
        case 'paymentwall': {
          PaymentWallRefillPurchase.paymentWallRefillPurchase(
            context,
            pgItem.method,
            selectItem ?? null,
          );
          break;
        }
        default: {
          console.warn('not found work', pgItem);
        }
      }
    },
    [],
  );

  /**
   * ANCHOR: 일반 상품 결제
   */
  const prucahsesActionProcNormal = useCallback(
    (
      pgItem: PgType.MethodItemType,
      context: PaymentContext,
      isUseLastPayMethod: boolean, // 마지막 결제 수단 버튼을 클릭해서 prepare 요청하는지 구분 키 (멤버십은 구분x)
    ) => {
      switch (pgItem.pg) {
        case 'eximbay': {
          EximbayPurchase.selectorForPopup(
            context,
            pgItem.method,
            isUseLastPayMethod,
          );
          break;
        }
        case 'payletter': {
          PayLetterPurchase.payLetterPurchase(context, isUseLastPayMethod);
          break;
        }
        // case 'paypal': {
        //   PaypalPurchase.paypalSelector(context);
        //   break;
        // }
        case 'xsolla': {
          if (Cookies.get('topcoTest')) {
            // 테스트중
            UserDefault.nonMatureSiteMove(context, pgItem);
          } else {
            PaypalPurchase.paypalSelector(
              context,
              pgItem.method,
              isUseLastPayMethod,
            );
          }
          break;
        }
        case 'stripe': {
          StripePurchase.stripeSeletor(context, isUseLastPayMethod);
        }
        case 'paymentwall': {
          PaymentWallPurchase.paymentWallPurchase(
            context,
            pgItem.method,
            isUseLastPayMethod,
          ); // google과 applePay는 바로가기 기능 없음
        }
        default: {
          console.warn('not found work', pgItem);
        }
      }
    },
    [],
  );

  /**
   * ANCHOR: 선택한 상품에 따른 상품 그룹 구분
   */
  const selectPgGroup = useMemo((): PgType.GroupItemType[] => {
    if (!paymentContext || !store.selectItem) return [];
    const { recurring, normal, refill } = paymentContext.pgGroups;
    if (
      store.selectItem.groupString === ProductGroupType.membership &&
      recurring
    ) {
      return recurring;
    } else if (
      store.selectItem.groupString === ProductGroupType.normal &&
      normal
    ) {
      return normal;
    } else if (
      store.selectItem.groupString === ProductGroupType.refill &&
      refill
    ) {
      return refill;
    }

    return [];
  }, [paymentContext, store.selectItem]);

  /**
   * ANCHOR: 마지막 결제수단 처리
   */
  const selectLastPaymentMethod = useMemo((): LastPaymentMethodType | null => {
    // context 생성 안되면 null
    if (!paymentContext || AppManager.getInstance().isStoreApp()) return null;

    // 선택된 상품 없으면 null
    if (!store.selectItem) return null;

    // 맴버쉽이면 마지막 결제수단 처리 비활성화
    if (store.selectItem.groupString === ProductGroupType.membership)
      return null;

    const { lastPaymentMethod } = paymentContext;

    // 마지막 결제 정보 없으면 null
    if (lastPaymentMethod.length === 0) return null;

    if (!store.selectItem.groupString) return null;

    const currentMethod: LastPaymentMethodType[] = _.filter(lastPaymentMethod, {
      groupString: store.selectItem.groupString,
    });

    // 선택한 group에 대한 마지막 결제 수단이 존재하지 않으면 null
    if (currentMethod.length === 0) return null;

    let pgList: PgType.MethodItemType[] = [];

    paymentContext.pgGroups.normal.forEach(c => {
      pgList = pgList.concat(c.pgList);
    });

    const usedMethod = _.filter(pgList, {
      pg: currentMethod[0].pg,
      method: currentMethod[0].method,
    });

    // 마지막 결제수단이 사용가능한 결제 수단인지 판단.
    if (usedMethod.length === 0) return null;

    return currentMethod[0];
  }, [paymentContext, store.selectItem]);

  if (paymentContext) {
    const context: PaymentContext = {
      ...paymentContext,
      selectProductItem: store.selectItem,
      selectPgGroup,
      windowMode,
      isMobile,
      isStoreApp,
      token: UserSession.getUserInfo().token,
      loginInfo: UserSession.getUserInfo(),
      setSelectItem: store.setSelectItem,
      purchaseAction,
      selectLastPaymentMethod,
    };

    return <WrappedComponent {...context} />;
  }

  return null;
};

export default WithPaymentHoc;
