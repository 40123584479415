export enum Reading {
  COIN = 1,
  MEMBERSHIP = 2,
}

/**
 * 썸네일 [KEY] 모음
 */
export enum ThumbnailKey {
  CHARACTER = 'characterImage',
  CHARACTER_17 = 'characterImage17',
  DES_TEXT = 'descriptionTextImage',
  LIST_BANNER = 'listBannerImage',
  LIST_BANNER_17 = 'listBannerImage17',
  MAIN_REAL_TIME = 'mainRealtimeImage',
  SQUARE = 'squareThumbnail',
  SQUARE17 = 'squareThumbnail17',
  THUMBNAIL = 'thumbnailImage',
  THUMBNAIL17 = 'thumbnailImage17',
  TITLE_VERTICAL = 'titleVerticalThumbnail',
  TITLE_VERTICAL_17 = 'titleVerticalThumbnail17',
  VERTICAL = 'verticalThumbnail',
  VERTICAL_17 = 'verticalThumbnail17',
}

export enum BadgeKey {
  NEW = 'NEW',
  TOP10 = 'TOP10',
  FREE = 'FREE',
  ORIGIN = 'ORIGIN',
  FreePass = 'FreePass',
  FAW = 'FAW',
  BASED_ON_NOVEL = 'BASED ON NOVEL',
  UP = 'UP',
  UP_MANUAL = 'UP_MANUAL',
  UNCENSORED = 'UNCENSORED',
  SALE50 = 'SALE',
  COMPLETE = 'COMPLETE',
}

/**
 * 작품 마지막 회차 정보 표시하는 type값
 */
export enum EpisodeType {
  NORMAL = 0,
  PROLOGUE = 2,
  EPILOGUE = 3,
}

/**
 * 썸네일 모드
 */
export enum ThumbnailMode {
  NORMAL = 0, // 일반 (mature 값에 따라)
  NON_ADULT = 1, // 강제 비성인
  ADULT = 2, // 강제 성인
}
