import { PaymentWindowModeType } from '@src/view/components/payment/data/_constants';
import React from 'react';
import _ from 'lodash';
import cn from 'clsx';
import { currencyAndPriceToCurrencySymbol } from '@src/lib/utils/utils';

interface CurrencyAndPriceProps {
  currencyCode: string; // 'ko-KR'
  price: number; // 5, 10, 15
  originalPrice?: number;
  discountPrice?: string | null;
  isOneDollar?: boolean;
  windowMode: PaymentWindowModeType;
  payType?: string; // 결제수단 paypal에서 문구 제거용 : 모바일에서 너무 비좁아서 기획쪽 요청
  cycle?: string;
  periodValue?: number; // membership 상품 PLAN 개월수 (ex. 1 / 3 / 6)
  periodUnit?: string; // membership 상품 PLAN 개월명 (= MONTHS)
}

interface ProductBenefitPropTypes {
  windowMode: PaymentWindowModeType;
  comment: string; // 첫번째 Item
  description: string; // 두번째 Item
  price?: string;
  periodValue?: number; // membership 상품 PLAN 개월수 (ex. 1 / 3 / 6)
  periodUnit?: string; // membership 상품 PLAN 개월명 (= MONTHS)
}

const Price = (child: React.ReactNode) => {
  return <>{child}</>;
};

const Normal = (props: CurrencyAndPriceProps) => {
  const {
    currencyCode,
    price,
    originalPrice = 0,
    discountPrice = null,
    isOneDollar,
    windowMode,
    payType,
  } = props;
  return (
    <div
      className={cn(
        'priceCon text-center flex items-center justify-center',
        'mo:text-[0.825rem] mo:leading-[18px] mo:flex-col',
        {
          'text-[0.9rem] leading-[20px]':
            windowMode === PaymentWindowModeType.LARGE,
          'text-[0.825rem] leading-[18px]':
            windowMode === PaymentWindowModeType.MINI,
        },
        {
          ['border-l-[1px] border-l-[#3f3f3f]']: payType === 'paypal',
        },
      )}
    >
      {/* originPrice */}
      {originalPrice > 0 && (
        <div
          className={cn(
            'originPrice text-[#FF323A] relative font-semibold mr-[8px] mo:mr-0',
            "after:content-[''] after:w-[55px] after:mo:w-[36px] after:absolute after:left-[50%] after:top-[50%] after:translate-x-[-50%] after:translate-y-[-50%] after:inline-block after:border-[#FF323A] after:border-solid after:border-t-[2px] after:rotate-[17deg]",
            {
              'text-[21px] mo:text-[15px]':
                windowMode === PaymentWindowModeType.LARGE,
              'text-[15px] after:w-[36px]':
                windowMode === PaymentWindowModeType.MINI,
            },
          )}
        >
          {currencyAndPriceToCurrencySymbol(
            'ko-KR',
            currencyCode,
            originalPrice,
            isOneDollar,
          )}
        </div>
      )}
      {/* originPrice */}
      {/* salePrice */}
      <div
        className={cn('price font-medium', {
          // 'text-[26px]': windowMode === PaymentWindowModeType.LARGE,
          // 'text-[24px]': windowMode === PaymentWindowModeType.MINI,
        })}
      >
        {currencyAndPriceToCurrencySymbol(
          'ko-KR',
          currencyCode,
          price,
          isOneDollar,
        )}
      </div>
      {/* salePrice */}
    </div>
  );
};

const Membership = (props: ProductBenefitPropTypes) => {
  const { windowMode, price, periodValue, periodUnit } = props;

  return (
    <>
      {periodUnit && periodValue && (
        <div
          className={cn(
            'flex items-center mb-[3px] justify-center mo:text-[13px] leading-[0.9rem] font-[500] mo:flex-col mo:px-3px ',
            {
              'text-[18px]': windowMode === PaymentWindowModeType.LARGE,
              'text-[13px]': windowMode === PaymentWindowModeType.MINI,
            },
          )}
        >
          ${price}
          {/* ${(Number(price) / periodValue).toFixed(2)} */}
          {/* / {''}
          <br className="hidden mo:block" />
          {'month'} */}
        </div>
      )}
    </>
  );
};

Price.Normal = Normal;
Price.Membership = Membership;

export default Price;
