import { Benefit, CoinBonus, Plan, Price } from './ItemV2/Piece';
import { PaymentWindowModeType, ProductGroupType } from '../../data/_constants';

import AppManager from '@src/lib/app/AppManager';
import Cookies from 'js-cookie';
import { ItemContents } from './ItemV2';
import { ProductItemType } from '../../context';
import { ProductList } from './list';
import { TopcoPayment } from '../../data';
import { UserBannerType } from '@src/middleware/model';
import { UserInfoType } from '@src/lib/user/types';
import { UserSession } from '@src/lib';
import cn from 'clsx';

interface PropsType {
  windowMode: PaymentWindowModeType;
  productList: ProductItemType[];
  selectProductItem: ProductItemType | null;
  isStoreApp: boolean;
  loginInfo: UserInfoType;
  token: string;
  userBanner: UserBannerType | null;
  topcoPayment: TopcoPayment;
  setSelectItem?: (item: ProductItemType) => void;
  getProductItems?: (groupType: ProductGroupType) => void;
}

const ProductContent = (props: PropsType) => {
  const {
    topcoPayment,
    windowMode,
    selectProductItem,
    setSelectItem,
    isStoreApp,
    userBanner,
    loginInfo,
    token,
    productList,
  } = props;

  return (
    <>
      {/* <div className="flex items-center justify-between mb-[14px]"> */}
      {/* <div className="text-[.9rem] font-[500] text-black">
          Please select a product
        </div> */}

      {/* membership 없을시 숨김 */}
      {/* {topcoPayment.getGroupByMembership().length && (
          <button
            type="button"
            className="min-w-[17px]"
            onClick={e => {
              openMembershipInfoModadl();
            }}
          >
            <img
              src="/images/payment/ico_detail_gray.svg"
              alt="membership-notice"
            />
          </button>
        )} 
      </div> */}

      {/* 원래 gap-y-[8px] 속성 있었음 - (22.12.26) 리뉴얼로 인해 속성 제거 */}
      <div
        className={cn('flex flex-col', {
          'gap-y-[44px]': windowMode === PaymentWindowModeType.MINI,
          'gap-y-[56px] mo:gap-y-[44px] tablet:pt-[30px]':
            windowMode === PaymentWindowModeType.LARGE,
        })}
      >
        {/* oneDollar 상품 리스트 */}
        {/* {TypeCheck.isValidArray(topcoPayment.getGroupByOneDollar()) && (
            <ProductItemType1 {...props} />
          )} */}
        {/* oneDollar 상품 리스트 */}
        {/* 코인 월 자동충전 상품 리스트 */}
        {/* {TypeCheck.isValidArray(topcoPayment.getGroupByAutoPayment()) && (
            <ProductItemType2 {...props} />
          )} */}
        {/* 코인 월 자동충전 상품 리스트 */}

        {/* 리뉴얼 상품 리스트 (22.12.26) */}
        {/* <ProductList
          groupType={ProductGroupType.membership}
          windowMode={windowMode}
          selectProductItem={selectProductItem}
          productItems={topcoPayment.getGroupByMembership()}
          setSelectItem={setSelectItem}
        /> */}
        {/* 리뉴얼 상품 리스트 (22.12.26) */}

        {/* 멤버십 상품 리스트 */}
        {/* <ProductList
          groupType={ProductGroupType.membership}
          windowMode={windowMode}
          selectProductItem={selectProductItem}
          productItems={topcoPayment.getGroupByMembership()}
          setSelectItem={item => {
            setSelectItem && setSelectItem(item);
            if (isStoreApp) {
              if (!loginInfo) return;
              AppManager.getInstance().currentPage.setter();
              const { userId } = loginInfo;
              AppManager.getInstance().action.inappBilling(
                `pid=${item.productId}&userId=${userId}&accessToken=${token}&storeId=${item.storeId}&type=recurring`,
              );
            }
          }}
        /> */}
        {/* 멤버십 상품 리스트 */}

        {/*Test */}

        {/* 멤버십 상품 리스트 */}
        {/* (23.05.15) */}

        <section
          id="productMembership"
          className={cn({
            hidden: topcoPayment.getGroupByMembership().length === 0,
          })}
        >
          {/* <UserBanner.paymentStaticBanner
            productItems={topcoPayment.getGroupByMembership()}
          /> */}

          <ProductList
            groupType={ProductGroupType.membership}
            windowMode={windowMode}
            userBanner={userBanner}
            selectProductItem={selectProductItem}
            productItems={topcoPayment.getGroupByMembership()}
            itemMatcher={[
              {
                id: 0,
                title: 'PLAN',
                Component: Plan,
              },
              {
                id: 1,
                title: 'BENEFITS',
                Component: Benefit.Membership,
              },
              {
                id: 2,
                title: 'Price',
                Component: Price.Membership,
              },
            ]}
            setSelectItem={item => {
              setSelectItem && setSelectItem(item);

              if (isStoreApp) {
                if (!loginInfo) return;
                AppManager.getInstance().currentPage.setter();
                const { userId } = loginInfo;
                AppManager.getInstance().action.inappBilling(
                  `pid=${item.productId}&userId=${userId}&accessToken=${token}&storeId=${item.storeId}&type=recurring`,
                );
              }
            }}
          />
        </section>
        {/* (23.05.15) */}
        {/* 멤버십 상품 리스트 */}

        {/* 일반 상품 리스트 */}
        {/* 리뉴얼 상품 리스트 (22.12.26) */}
        <section id="productNormal">
          {/* <UserBanner.PaymentTop
            banner={userBanner}
            className={
              windowMode === PaymentWindowModeType.MINI
                ? 'paymentMiniTop'
                : 'paymentTop'
            }
          /> */}

          <ProductList
            groupType={ProductGroupType.normal}
            windowMode={windowMode}
            userBanner={userBanner}
            selectProductItem={selectProductItem}
            productItems={{
              [ProductGroupType.refill]: topcoPayment.getGroupByRefill(),
              [ProductGroupType.normal]: topcoPayment.getGroupByNormal(),
            }}
            itemMatcher={[
              {
                id: 0,
                title: 'Pricing',
                Component: Price.Normal,
              },
              {
                id: 1,
                title: 'Benefit',
                Component: Benefit.Normal,
              },
              {
                id: 2,
                title: 'COIN + BONUS',
                Component: CoinBonus,
              },
            ]}
            setSelectItem={item => {
              setSelectItem && setSelectItem(item);

              if (isStoreApp) {
                if (!loginInfo) return;
                AppManager.getInstance().currentPage.setter();
                const { userId } = loginInfo;

                Cookies.remove('coinRefresh');
                const visitorId = UserSession.getFingerPrintId();
                AppManager.getInstance().action.inappBilling(
                  `pid=${item.productId}&userId=${userId}&accessToken=${token}&storeId=${item.storeId}&type=normal&visitorId=${visitorId}`,
                );
              }
            }}
          />
        </section>

        {/* 리뉴얼 상품 리스트 (22.12.26) */}
        {/* 일반 상품 리스트 */}
      </div>
    </>
  );
};

export default ProductContent;
