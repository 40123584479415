// eslint-disable-next-line import/no-anonymous-default-export
export default {
  env: {
    firebaseAuth: {
      apiKey: 'AIzaSyDcRp9kQgGzWDskMc2Kw75vyOWRdzV4Ct0',
      authDomain: 'toptoonplus.firebaseapp.com',
      projectId: 'toptoonplus',
      storageBucket: 'toptoonplus.appspot.com',
      messagingSenderId: '1069119357520',
      appId: '1:1069119357520:web:7014060d75e44241180755',
      measurementId: 'G-B4QWHP3RP8',
    },
  },
};
