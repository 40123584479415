import cn from 'clsx';

interface PropTypes {
  disabled: boolean;
  title: string;
}

const LoginAndJoinBtn = (props: PropTypes) => {
  const { disabled, title } = props;

  return (
    <div className="btnLogin mt-[15px]">
      <button
        id="signButton"
        className={cn(
          `disabled:bg-[#eee] disabled:shadow-none shadow-logInAndJoin h-[47px] text-white text-[17px] font-medium rounded-[50px] text-center w-full mx-auto flex justify-center items-center bg-primaryRed`,
        )}
        type="submit"
        disabled={disabled}
      >
        {title}
      </button>
    </div>
  );
};

export default LoginAndJoinBtn;
