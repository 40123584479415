
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '../styles/globals.css';
import 'swiper/css';
import 'swiper/css/navigation';

import type { AppProps } from 'next/app';
import { FirebaseOptions } from '@firebase/app-types';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Loading from '@src/view/error/TopcoLoading';
import { ModalContainer } from '@src/lib/CustomModal';
import React from 'react';
import { RecoilRoot } from 'recoil';
import { SeoComponent } from '@src/view/common/seo';
import { TheLayout } from '@src/view/theLayout';
import { ToastProvider } from 'react-toast-notifications';
import { TopcoCenterToast } from '@src/view/common/toast';
import { TopcoScript } from '@src/view/common/script';
import { WithHistory } from '@src/middleware/history';
import firebaseConfig from '@src/auth/appConfig';
// import 'swiper/css/pagination';  // custom pagination 사용을 위한 주석처리
import { initializeApp } from '@firebase/app';
import { Metrics } from '@edgio/rum';

new Metrics({
  token: '415558c2-9080-4000-9354-4d9bd36d4434',
}).collect();

const options: FirebaseOptions = firebaseConfig.env.firebaseAuth;
initializeApp(options);

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      {/* <CommonLoading /> */}
      {/* <Loading.Text /> */}
      <TopcoScript />
      <Loading.LottieLoading />

      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_HCATPCHA_SITEKEY ?? ''}
      >
        <ToastProvider
          autoDismiss
          autoDismissTimeout={2 * 1000}
          components={{ Toast: TopcoCenterToast }}
        >
          <RecoilRoot>
            <ModalContainer />
            <SeoComponent.Default />
            <TheLayout>
              <WithHistory
                propsChildd={{ ...pageProps }}
                WrappedComponent={Component}
              />
            </TheLayout>
          </RecoilRoot>
        </ToastProvider>
      </GoogleReCaptchaProvider>
      {/* </PersistGate>
      </Provider> */}
    </>
  );
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  