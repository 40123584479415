import { UserDefault } from '@src/lib';
import React, { useEffect, useState } from 'react';
import cn from 'clsx';

interface Props {
  isAuthCheck: boolean;
}

const PreAuthCheckBox = (props: Props) => {
  const { isAuthCheck } = props;
  // 사전인증체크
  const [isPreAuthCheck, setIsPreAuthCheck] = useState<boolean>(true);

  useEffect(() => {
    if (UserDefault.preAuthCheckBox.getter()) {
      if (!isAuthCheck) {
        setIsPreAuthCheck(false);
        UserDefault.preAuthCheckBox.clear();
      }
    }
  }, [isAuthCheck]);

  return (
    <>
      {/* 사전인증 체크박스 */}
      {isAuthCheck && (
        <div className="mt-[10px] flex gap-x-[7px] justify-center items-center">
          <input
            id="userPreAuth"
            type="checkbox"
            className={cn(
              'inline-block bg-primary-bg border-primary-border w-[16px] h-[16px] rounded-[3px] cursor-pointer border-solid ',
              'checked:text-primary-text-point',
            )}
            checked={isPreAuthCheck}
            onChange={({ target: { checked } }) => {
              setIsPreAuthCheck(checked);
              if (checked) {
                UserDefault.preAuthCheckBox.setter();
              } else {
                UserDefault.preAuthCheckBox.clear();
              }
            }}
          />
          <label htmlFor="userPreAuth" className="text-[#333] text-[12px]">
            {`I'm over the age of 18`}
          </label>
        </div>
      )}
      {/* 사전인증 체크박스 */}
    </>
  );
};

export default PreAuthCheckBox;
