import { ProductItemType } from '../context';
import { create } from 'zustand';

export interface StateType {
  selectItem: ProductItemType | null;
}

export interface StoreType extends StateType {
  setSelectItem: (selectItem: ProductItemType | null) => void;
}

const useStore = create<StoreType>(set => ({
  selectItem: null,
  pgList: [],
  recurringPgList: [],
  setSelectItem: selectItem => {
    set({ selectItem });
  },
}));

export default useStore;
