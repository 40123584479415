import {
  PaymentWindowModeType,
  ProductGroupType,
} from '../../../data/_constants';

import { ItemMatcherType, ProductItemType } from '../../../context';
import React from 'react';
import cn from 'clsx';
import BuyButton from './BuyButton';

interface PropsType {
  isSelect: boolean;
  item: ProductItemType;
  groupType?: ProductGroupType;
  windowMode: PaymentWindowModeType;
  payType?: string; // 결제수단 paypal에서 문구 제거용
  setSelectItem?: (item: ProductItemType) => void;
  itemMatcher: ItemMatcherType[];
}

/** 모든 상품 깜싸는 그룹 */
const ProductItem = (props: PropsType) => {
  const {
    isSelect,
    item,
    groupType,
    windowMode,
    setSelectItem,
    payType,
    itemMatcher,
  } = props;

  const scrollToPaymentGroup = () => {
    const getPaymentGroup = document.getElementById('paymethodGroup');
    if (!getPaymentGroup) return;
    getPaymentGroup.scrollIntoView({
      block: 'center', // 수직
    });
  };

  return (
    <button
      className="w-full"
      title="product-item"
      type="button"
      onClick={() => {
        setSelectItem && setSelectItem(item);
        scrollToPaymentGroup();
      }}
    >
      <div
        className={cn(
          'grid min-h-[60px] border-x-[1px] border-b-[1px] border-primary-border dark:border-[#3f3f3f]',
          {
            'bg-[#FFF2F2] dark:bg-[#411C1C]':
              isSelect && groupType === 'NORMAL',
            'bg-[#F2FFF2] dark:bg-[#172922]':
              isSelect && groupType === 'MEMBERSHIP',
            'bg-primary-bg dark:bg-[#1E1F21]': !isSelect,
          },
          {
            'grid-cols-[30%_30%_1fr] mo:grid-cols-[22%_25%_1fr]':
              windowMode === PaymentWindowModeType.LARGE &&
              groupType === 'NORMAL',
            'grid-cols-[22%_25%_1fr]':
              windowMode === PaymentWindowModeType.MINI &&
              groupType === 'NORMAL',
            'grid-cols-[25%_1fr_25%] mo:grid-cols-[22%_1fr_25%]':
              windowMode === PaymentWindowModeType.LARGE &&
              groupType === 'MEMBERSHIP',
            'grid-cols-[22%_1fr_25%]':
              windowMode === PaymentWindowModeType.MINI &&
              groupType === 'MEMBERSHIP',
          },
          {
            ['border-t-[2px] border-primary-border dark:border-[#3f3f3f] grid-cols-[22%_1fr] border-l-0']:
              payType === 'paypal',
          },
          '[&>*:nth-child(2)]:border-x-[1px]',
        )}
      >
        {itemMatcher.map((c, index) => {
          return (
            <div
              key={c.id}
              className={cn('flex items-center', {
                'justify-center': index !== itemMatcher.length - 1,
                'justify-between px-[20px] mo:px-[10px]':
                  index === itemMatcher.length - 1 && groupType === 'NORMAL',
                'justify-center flex-col px-[20px] py-[15px] mo:px-[10px]':
                  index === itemMatcher.length - 1 &&
                  groupType === 'MEMBERSHIP',
              })}
            >
              <c.Component
                {...item}
                payType={payType}
                windowMode={windowMode}
                isSelect={isSelect}
              />

              {index === itemMatcher.length - 1 && (
                <BuyButton
                  windowMode={windowMode}
                  isSelect={isSelect}
                  groupType={groupType}
                />
              )}
            </div>
          );
        })}
      </div>
    </button>
  );
};
export default ProductItem;
