import { CloseBtn } from '@src/view/common/closeBtn';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';

const key = 'CrazyTimerModal';
export interface ClickActionModalPropsType {
  imgPath: string;
  onClose: () => void;
}

export const showClickActionModal = (props: ClickActionModalPropsType) => {
  ModalInstance.getInstance().push({
    key: key,
    className: 'animation_fadein',
    shouldCloseOnOverlayCallback: e => {
      props.onClose();
    },
    component: <ClickActionModal {...props} />,
  });
};

export const closeClickActionModal = () => {
  ModalInstance.getInstance().delete(key);
};

const ClickActionModal = (props: ClickActionModalPropsType) => {
  const { imgPath, onClose } = props;

  return (
    <div
      className={
        'w-[350px] m-auto p-0 rounded-none overflow-hidden relative mo:w-[260px]'
      }
    >
      <CloseBtn
        imgPath="/images/alert/close/ico_close_2.png"
        className="w-[35px] h-[35px] right-0 top-0 absolute z-[1]"
        onClick={e => {
          closeClickActionModal();
        }}
      />
      <a
        href="/payment"
        onClick={() => {
          closeClickActionModal();
        }}
      >
        <img src={imgPath} alt="crazytimer" />
      </a>
    </div>
  );
};

export default ClickActionModal;
