import { PaymentContext } from '../context';
import useSWR, { useSWRConfig } from 'swr';

import { UserSession } from '@src/lib';
import { TopcoPaymentRequestApi } from './TopcoPayment';

const usePaymentData = () => {
  const { mutate: swrMutate } = useSWRConfig();
  const { mature } = UserSession.getUserInfo();

  const { data, error } = useSWR<PaymentContext, Error>(
    { url: 'product/lists', args: { mature } },
    () => TopcoPaymentRequestApi.productList(),
    {
      revalidateOnFocus: false,
    },
  );

  return {
    mutate: swrMutate,
    data,
    error,
  };
};

export default usePaymentData;
