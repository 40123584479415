export const enum AtomKey {
  FETCH_MATURE = 'fetchMature',
  BINGE = 'bingeState1', // 자동 로그인
  GLOBAL_PROCESS = 'globalProcessState',
  SEARCH_KEYWORD = 'SearchKeyword',
  HASHTAG_KEYWORD = 'HashTagKeyword', // hashtag/keyword 페이지용
  LIBRARY_PURCHASES = 'LibPurchasesState', // 내서재 구독 체크박스용
  // 페이지리스트 정렬 ----->
  SORTBOX_HASHTAG = 'sortBoxHashTag',
  SORTBOX_BINGE = 'sortBoxBinge',
  SORTBOX_GENRE = 'sortBoxGenre',
  SORTBOX_ARTWORKS = 'sortBoxArtworks',
  SORTBOX_LIBRARY = 'sortBoxlibrary',
  // <----- 페이지리스트 정렬
  SEARCH_TEXT = 'searchText',
  SEARCH_LOG = 'search/loglist',
  SEARCH_LOG_SET = 'search/loglist/set',
  SEARCH_KEYWORD_RECENT = 'search/keyword/recent',
  SEARCH_MODE = 'search/mode',
  USER_SUBSCRIBED = 'UserSubscribeState',
  GNB_MENU = 'gnbMenuState',
  SNB_MENU = 'snbMenuState',
  LOGO = 'logoState',
  SERVER_CHECK = 'serverCheckState',
  USER_TIKET = 'ticketState',
  VIEWER_AUTO_SCROLL_MODE = 'autoScrollModeState',
  VIEWER_AUTO_SCROLL_SPEED = 'autoScrollSpeedState',
  VIEWER_TOOLBAR = 'showToolbarState',
  VIEWER_EPISODE = 'showEpisodeListState',
  VIEWER_EPISODE_TOGGLE = 'toggleEpisodeList',
}

export const enum AtomStorageKey {
  FETCH_MATURE = 'fetchPersitst',
  SEARCH_KEYWORD = 'searchKeywordPersitst',
  HASHTAG_KEYWORD = 'hashTagKeywordPersitst',
  LIBRARY_PURCHASES = 'libPurchasesStatePersitst',
  SORTBOX = 'sortBoxPersitst',
  SEARCH_LOG = 'search/loglist/persist',
  LOGO = 'logoPersist',
  USER_TIKET = 'ticketPersist',
  VIEWER_AUTO_SCROLL = 'autoScrollPersist',
}
