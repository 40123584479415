module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  localeDetection: false,
  page: {
    '*': ['footer', 'header', 'search', 'sidemenu', 'auth'],
    '/': ['main'],
    '/ranking': ['ranking'],
    '/mypage': ['mypage'],
    '/library': ['library'],
    '/hashtag': ['hashtag'],
    '/gift': ['gift'],
    '/genres': ['genres'],
    '/binge': ['binge'],
    '/new': ['new'],
  },
};
