import { Event } from '@toptoon-developers/global.toptoonplus.common.lib/dist/gtm/Gtm';
import { GlobalGtm } from '@toptoon-developers/global.toptoonplus.common.lib/dist/gtm/global';
import { PaymentContext } from '../../context';
import { PaymentWindowModeType } from '../../data/_constants';
import { PgItem } from '.';
import { TopcoPartner } from '@src/lib/partner';
import cn from 'clsx';

const PaymentContent = (props: PaymentContext) => {
  const { windowMode, selectPgGroup, selectProductItem, purchaseAction } =
    props;
  return (
    <>
      {/* paymentNotice */}
      <div className={cn('mb-[15px]')}>
        <h3
          className={cn(
            'mb-[8px] text-primary-text dark:text-[#fff] text-[1.1rem] font-[500] tracking-[-0.2px] mo:text-[0.9375rem] mo:mx-auto mo:mb-0',
          )}
        >
          Payment method
        </h3>
        <p
          className={cn(
            'mt-[5px] text-[#999] leading-[17px] mo:text-[11.2px]',
            {
              'text-[12.6px]': windowMode === PaymentWindowModeType.LARGE,
              'text-[11.2px]': windowMode === PaymentWindowModeType.MINI,
            },
          )}
        >
          {`Day Comics' partnered payment methods keep your payment information safe and secure.`}
        </p>
      </div>
      {/* paymentNotice */}
      {/* pgList */}
      <section id="payMethodNotice">
        <div className="titArea flex items-center justify-between mb-[14px]">
          <div className="text-[.9rem] font-[500] text-primary-text dark:text-[#fff]">
            How do you want to pay?
          </div>
        </div>
      </section>

      <section
        className={cn('pgGroup flex flex-col ', {
          ['gap-y-[31px] mo:gap-y-[18px]']:
            windowMode === PaymentWindowModeType.LARGE,
          ['gap-y-[18px]']: windowMode === PaymentWindowModeType.MINI,
        })}
      >
        {selectPgGroup.map(item => {
          return (
            <PgItem
              key={`pg-item-${item.title}}`}
              groupItem={item}
              windowMode={windowMode ?? PaymentWindowModeType.LARGE}
              onClick={(e, item) => {
                e.preventDefault();

                // GTM - 결제 버튼 클릭
                GlobalGtm.getInstance().setter({
                  event: Event.PURCHASE_BUTTON_CLICK,
                  pCode: TopcoPartner.getter(),
                });

                // purchaseAction && purchaseAction(item, props, false);
                purchaseAction && purchaseAction(item, props, false);
              }}
            />
          );
        })}
      </section>
      {/* pgList */}
    </>
  );
};

export default PaymentContent;
