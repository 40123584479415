/* eslint-disable import/no-anonymous-default-export */
export default {
  title:
    'DAYCOMICS | manhwa | toon | comics | free | read | comic | online | cartoons | korean | Manga | webtoon',
  description:
    'DAYCOMICS - Enjoy our completed all-ages series for FREE - Daily webtoon, Drama, Romance, Comedy, Action, horror DAYCOMICS | manhwa | toon | comics | free | read | comic | online | cartoons | korean | Manga | webtoon',
  canonical: 'https://daycomics.com/',
  author: '',
  additionalLinkTags: [
    // { rel: 'manifest', href: '/favicon/manifest.json' },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '192x192',
      href: '/favicon/android-icon-192x192.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon/favicon-16x16.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '96x96',
      href: '/favicon/favicon-96x96.png',
    },
    {
      rel: 'android-icon',
      type: 'image/png',
      sizes: '36x36',
      href: '/favicon/android-icon-36x36.png',
    },
    {
      rel: 'android-icon',
      type: 'image/png',
      sizes: '48x48',
      href: '/favicon/android-icon-48x48.png',
    },
    {
      rel: 'android-icon',
      type: 'image/png',
      sizes: '72x72',
      href: '/favicon/android-icon-72x72.png',
    },
    {
      rel: 'android-icon',
      type: 'image/png',
      sizes: '96x96',
      href: '/favicon/android-icon-96x96.png',
    },
    {
      rel: 'android-icon',
      type: 'image/png',
      sizes: '144x144',
      href: '/favicon/android-icon-144x144.png',
    },
    {
      rel: 'android-icon',
      type: 'image/png',
      sizes: '192x192',
      href: '/favicon/android-icon-192x192.png',
    },
    {
      rel: 'apple-touch-icon',
      href: '/favicon/apple-icon-57x57.png',
      size: '57x57',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '60x60',
      href: '/favicon/apple-icon-60x60.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '72x72',
      href: '/favicon/apple-icon-72x72.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '76x76',
      href: '/favicon/apple-icon-76x76.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '114x114',
      href: '/favicon/apple-icon-114x114.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '120x120',
      href: '/favicon/apple-icon-120x120.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '144x144',
      href: '/favicon/apple-icon-144x144.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '152x152',
      href: '/favicon/apple-icon-152x152.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/favicon/apple-icon-180x180.png',
    },
  ],
  additionalMetaTags: [
    {
      name: 'keywords',
      content:
        'DAYCOMICS | manwha | toon | comics | free | read | comic | oline | cartoons | korean | Manga | webtoon |',
    },
    {
      name: 'viewport',
      content:
        'width=device-width, shrink-to-fit=no, initial-scale=1.0, maximum-scale=1.0',
    },
    {
      name: 'application-name',
      content:
        'DAYCOMICS | manwha | toon | comics | free | read | comic | oline | cartoons | korean | Manga | webtoon |',
    },
    {
      name: 'msapplication-tooltip',
      content:
        'DAYCOMICS | manhwa | toon | comics | free | read | comic | online | cartoons | korean | Manga | webtoon',
    },
    {
      name: 'facebook-domain-verification',
      content: 'ke44rvkhpylrui8twrkd0xpipkl8f6',
    },
    {
      name: 'google-site-verification',
      content: '4_avR5bTFpNtk2z85LHCfs3yGl0wq6Ae00C2y_oc1pI',
    },

    {
      name: 'naver-site-verification',
      content: '18abf824ec815f1fcb9859b109752955ba8297c7',
    },
    {
      name: 'google-signin-client_id',
      content:
        '1069119357520-vaeu8t9e7sfpho46skk6pnggr6dgcdv9.apps.googleusercontent.com',
    },
    {
      name: 'theme-color',
      content: '#292929',
    },
    {
      name: 'copyright',
      content: 'DAYCOMICS',
    },

    {
      name: 'msapplication-TileColor',
      content: '#292929',
    },
    {
      name: 'msapplication-TileImage',
      content: '/ms-icon-144x144.png',
    },
    {
      name: 'twitter:url',
      content: `daycomics.com`,
    },
    {
      name: 'twitter:title',
      content: 'DAYCOMICS',
    },
    {
      name: 'twitter:description',
      content:
        'Thousands Of Pleasure! Explore our eccentric unlimited webtoon only available at DAYCOMICS!',
    },
    {
      name: 'twitter:image',
      content: 'https://daycomics.com/images/seo/seo_thumb.jpg',
    },
    {
      name: 'twitter:image:width',
      content: `600`,
    },
    {
      name: 'twitter:image:height',
      content: `600`,
    },
  ],
  openGraph: {
    type: 'website',
    url: 'daycomics.com',
    title:
      'DAYCOMICS | manhwa | toon | comics | free | read | comic | online | cartoons | korean | Manga | webtoon',
    description:
      'Enjoy our completed all-ages series for FREE - DAYCOMICS | manhwa | toon | comics | free | read | comic | online | cartoons | korean | Manga | webtoon',
    site_name: 'DAYCOMICS',
    images: [
      {
        url: 'https://daycomics.com/images/seo/seo_thumb.jpg',
        width: 470,
        height: 250,
        alt: 'daycomics',
      },
    ],
  },
  twitter: {
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image',
  },
};

export const mainSEO = {
  title: 'Day Comics: Stories you never knew you needed.',
  description:
    'The official Day Comics platform. Enter a whole new way to read Web comics. Dive in thousands of stories and various genres released daily.',
};

export const originalsSEO = {
  title: 'Day Comics – Originals',
  description:
    'Exclusive & binge-worthy stories only at Day Comics. New episodes released every day.',
};

export const newSEO = {
  title: 'Day Comics – New releases',
  description: 'Check out Day Comics new releases.',
};

export const genresSEO = {
  title: 'Day Comics - Genres',
  description: 'Discover various genres from Day Comics.',
};

export const rankingSEO = {
  title: 'Day Comics – Popular titles',
  description:
    'Check out some of the most binge-worthy and interesting and fascinating titles from Day Comics.',
};

export const artworksSEO = {
  title: 'Day Comics - Characters feed',
  description:
    "Explore Day Comics' unique and charming characters and choose your favorite!",
};

export const completedSEO = {
  title: 'Day Comics - Completed',
  description:
    'Experience the utmost immersive sensation from Day Comic’s binge-worthy completed series.',
};

export const newsFeedSEO = {
  title: 'Day Comics – Announcements',
  description:
    'Check out some of our exclusive events and promotions by visiting our newsfeed.',
};

export const promoSEO = {
  title: `Day Comics – Daycomics' promotional contents and coin discounts`,
  description: 'Visit our promo page to keep track on which series are getting discounted or even free!',
};

export const OrganizationJS = {
  type: 'Organization',
  name: 'DAYCOMICS',
  url: 'https://daycomics.com',
  sameAs: [],
  // id:"",
  // logo:"",
  // legalName:"",
  // address: {
  //   streetAddress: '1600 Saratoga Ave',
  //   addressLocality: 'San Jose',
  //   addressRegion: 'CA',
  //   postalCode: '95129',
  //   addressCountry: 'US',
  // },
  // contactPoint:[
  //   {
  //     telephone: '+1-401-555-1212',
  //     contactType: 'customer service',
  //     areaServed: 'US',
  //     availableLanguage: ['English', 'Spanish', 'French'],
  //   },
  //   {
  //     telephone: '+1-877-746-0909',
  //     contactType: 'customer service',
  //     contactOption: 'TollFree',
  //     availableLanguage: 'English',
  //   },
  //   {
  //     telephone: '+1-877-453-1304',
  //     contactType: 'technical support',
  //     contactOption: 'TollFree',
  //     areaServed: ['US', 'CA'],
  //     availableLanguage: ['English', 'French'],
  //   },
  // ],
};
