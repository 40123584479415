import { PaymentWindowModeType, ProductGroupType } from '../../data/_constants';
import { openProductGroupDetailModal } from './ProductGroupDetailModal';

/**
 * 그룹 상품별 안내 레이어 띄우는 버튼
 * 일반상품, 멤버십 상품에 사용중
 * @returns
 */
interface PropsType {
  groupType: ProductGroupType;
  windowMode: PaymentWindowModeType;
}
export const ProductDetail = (props: PropsType) => {
  const { groupType, windowMode } = props;
  // 비노출 조건 일반상품, 멤버십 상품 아니거나 미니충전소 일때 비노출
  const isHidden =
    (groupType !== ProductGroupType.normal &&
      groupType !== ProductGroupType.membership) ||
    windowMode === PaymentWindowModeType.MINI;
  if (isHidden) return null;

  return (
    <h3 className="text-[#bbb] text-right mt-1 text-[11px] font-medium">
      <button
        type="button"
        onClick={(e: any) => {
          openProductGroupDetailModal({
            modalKey: groupType,
          });
        }}
      >
        View Details
      </button>
    </h3>
  );
};
