import { CompanyInfo } from '@src/constants';
import { PaymentWindowModeType } from '../../data/_constants';
import cn from 'clsx';

interface PropsType {
  windowMode: PaymentWindowModeType;
}

const Notice = (props: PropsType) => {
  const { windowMode } = props;

  const liClassName = cn(
    'text-[0.7rem] leading-[17px] text-[#888] mb-[5px] ml-[13px] list-disc marker:text-[12px] marker:text-[#888]',
    {
      'mo:text-[12px] mo:leading-[16px]':
        windowMode === PaymentWindowModeType.LARGE,
      'text-[12.6px]': windowMode === PaymentWindowModeType.MINI,
    },
  );

  return (
    <div
      className={cn('w-full relative opacity-1', {
        'mb-[50px]': windowMode === PaymentWindowModeType.LARGE,
        'pb-[30px] ': windowMode === PaymentWindowModeType.MINI,
      })}
    >
      <p
        className={cn(
          'text-[0.9rem] text-primary-text dark:text-[#fff] font-[500] tracking-[-0.2px] mb-[15px]',
          {
            'mb-[10px] mt-[18px] mr-[5px]':
              windowMode === PaymentWindowModeType.LARGE,
            'mb-[10px]': windowMode === PaymentWindowModeType.MINI,
          },
        )}
      >
        Disclosure
      </p>
      <ul
        className={cn('ml-[3px]', {
          'mt-[10px]': windowMode === PaymentWindowModeType.MINI,
        })}
      >
        {noticeArr.map((item, index) => {
          return (
            <li key={`${item}-${index}`} className={liClassName}>
              {item}
            </li>
          );
        })}
        {/* <li className={liClassName}>
          Day Comics is an official platform that provides safe online payment
          service.
        </li>
        <li className={liClassName}>
          By purchasing this product, you agree to our Terms of Service and
          Privacy Policy.
        </li>
        <li className={liClassName}>
          The prices of all taxable products already include VAT.
        </li>
        <li className={liClassName}>
          Coins can only be used on official Day Comics platform.
        </li>
        <li className={liClassName}>
          Auto-reload feature is fully supported on a web browser, but may not
          properly function on some apps and devices.
        </li>
        <li className={liClassName}>
          The following service may be changed or terminated without prior
          notice depending on circumstances.
        </li>
        <li className={liClassName}>
          Bonus coins will expire within 30 days from the date of purchase.
        </li> */}
        <li className={liClassName}>
          Feel free to reach out to our customer support at{' '}
          <a
            className="text-[#999] font-[500]"
            href={`mailto:${CompanyInfo.EMAIL_CS}`}
            target="_self"
          >
            {CompanyInfo.EMAIL_CS} for any assitance.
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Notice;

const noticeArr: string[] = [
  'Daycomics is an official platform that provides safe online payment services.',
  'By purchasing this product, you agree to our Terms of Service and Privacy Policy.',
  'VAT is already included in the prices of all taxable products.',
  'Any recurring product will automatically renew until you cancel it.',
  'The following service may be changed or terminated without prior notice depending on circumstances.',
  'Due to the nature of digital goods, purchased comics and episodes are not refundable.',
  "By using Day Comics' official site, you are supporting our authors and artists to continue their work.",
  //Feel free to reach out to our customer support at cs@daycomics.com for any assitance.
];
