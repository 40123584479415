import { MARKETING_MATURE } from '@src/view/components/viewer/data';
import { ThumbnailKey } from './_constants';
import { ThumbnailType } from './BaseType';
import { TopcoPartner } from '@src/lib/partner';
import { UserSession } from '@src/lib';
import _ from 'lodash';

const SLICE_SIZE = 2;

export const initRealTimeThumb = {
  height: 137,
  width: 226,
  path: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==',
};

export const initThumb = {
  height: 500,
  width: 300,
  path: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==',
};

/**
 * ANCHOR: 이미지 원본 데이터 추출용
 * @param data
 * @param key
 * @param index
 * @returns width, height, path
 */
export const imgParser = (
  data: any,
  key: string,
  index: number = 0,
): ThumbnailType | null => {
  if (!data || !_.has(data, key) || !_.isArray(data[key])) return null;

  if (data[key].length > index) return data[key][index];

  return data[key].length > 0 ? data[key][0] : null;
};

/**
 * ANCHOR: 이미지 데이터 리스트 추출용
 * * 리스트 반환
 * * max length 없으면 slice 하지 않음.
 * @param data
 * @param key
 * @param index
 * @param maxLength
 * @returns width, height, path
 */
const imgListSlicer = (
  data: any,
  key: string,
  maxLength: number = 0,
): ThumbnailType[] => {
  if (!data || !_.has(data, key) || !_.isArray(data[key])) return [];

  if (data[key].length < 0) return [];

  if (maxLength === 0) return data[key];

  return data[key].slice(0, maxLength);
};

/**
 * ANCHOR: 회차 썸네일 가공 - thumbnailImage 키의 [0][1] 번쨰로 가공하는 함수
 * - 0번째:성인, 1번째:17금
 * @param props
 * @returns
 */
export const convertEpiThumbnailImage = (data: any): ThumbnailType => {
  const { mature } = UserSession.getUserInfo();
  if (mature === 1) {
    const thumbnail = imgParser(data[ThumbnailKey.THUMBNAIL], 'webp');
    return thumbnail ?? initThumb;
  }
  // imgParser에서 1번째 이미지 없으면 알아서 0번째 이미지 가져옴.
  const thumbnail17 = imgParser(data[ThumbnailKey.THUMBNAIL], 'webp', 1);
  return thumbnail17 ?? initThumb;
};

/**
 * (23.08.07 추가)
 * ANCHOR: 회차 썸네일 가공 - verticalThumbnail, verticalThumbnail17 키로 구분하여 가공하는 함수
 * - 성인 여부에 따라 키의 0번째 <<객체>> 반환
 * @param data Thumbnail Data
 * @param marketingMature comic router 이면 1 또는 2 / content router 이면 0
 * @returns
 */
export const convertEpiVerticalThumbnail = (
  data: any,
  marketingMature: number = 0,
): ThumbnailType => {
  const { mature } = UserSession.getUserInfo();
  const { is17 } = TopcoPartner.getInfo();

  const thumbnail = imgParser(data[ThumbnailKey.VERTICAL], 'webp') ?? initThumb;

  // 일반 썸네일 : 성인이거나 || comic router 이고, is17이 false 일 때(마케팅)
  if (mature || (marketingMature !== MARKETING_MATURE.UNSET && !is17))
    return thumbnail;

  const thumbnail17 = imgParser(data[ThumbnailKey.VERTICAL_17], 'webp');

  return thumbnail17 ?? thumbnail;
};

/**
 * (23.08.07 추가)
 * ANCHOR: 회차 썸네일 가공 - verticalThumbnail, verticalThumbnail17 키로 구분하여 가공하는 함수
 * - 성인 여부에 따라 키의 0번째 <<리스트>> 반환
 * - 일본처럼 회차에 썸네일을 두 개 노출시켜달라고 요청할 경우를 대비하여 회차리스트 돔에서는 리스트 형태로 저장하여 사용함
 * @param data Thumbnail Data
 * @param marketingMature comic router 이면 1 또는 2 / content router 이면 0
 * @returns
 */
export const convertEpiVerticalThumbnailList = (
  data: any,
  marketingMature: number = 0,
): ThumbnailType[] => {
  const { mature } = UserSession.getUserInfo();
  const { is17 } = TopcoPartner.getInfo();

  const thumbnailList = imgListSlicer(
    data[ThumbnailKey.VERTICAL],
    'webp',
    SLICE_SIZE,
  );

  // 일반 썸네일 : 성인이거나 || comic router 이고, is17이 false 일 때(마케팅)
  if (mature || (marketingMature !== MARKETING_MATURE.UNSET && !is17))
    return thumbnailList.length ? thumbnailList : [initThumb];

  const thumbnail17List = imgListSlicer(
    data[ThumbnailKey.VERTICAL_17],
    'webp',
    SLICE_SIZE,
  );

  return thumbnail17List.length ? thumbnail17List : thumbnailList;
};
