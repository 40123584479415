import { LogoState } from '@src/atom/view';
import cn from 'clsx';
import { useRecoilValue } from 'recoil';

export const enum LogoImgPath {
  DEFAULT = '/images/common/logo/logo_daycomics.png', // 기본 로고
  SECOND = '/images/common/logo/logo_blackfriday.png', // 미사용
  MEMBERSHIP_BASIC = '/images/common/logo/logo_basic_dk.png',
  MEMBERSHIP_STANDARD = '/images/common/logo/logo_standard.png',
  MEMBERSHIP_PREMIUM = '/images/common/logo/logo_premium_membership_dk.png',
  EVENT = '/images/common/logo/logo_0214.png', // 이벤트 용 로고
}

export interface LogoType {
  path: string;
}

const Logo = () => {
  const { logoPath } = useRecoilValue(LogoState);

  const isDev =
    process.env.REACT_APP_ENVIRONMENT &&
    !(process.env.REACT_APP_ENVIRONMENT === 'production');

  return (
    //TODO: toptoon
    <div
      id="logoArea"
      className={cn(
        'h-[50px] grid-in-logo self-start relative',
        'middle:w-[130px] mo:w-[90px]',
      )}
    >
      {/* Header Logo 영역 */}
      <a href="/" className={'flex items-center h-full relative'}>
        <img src={logoPath} alt="logo" className="" />
        {isDev && (
          <div
            className={cn(
              'absolute text-[0.8rem] bottom-[-15px] left-0 font-[600] py-[1px] px-[3px] text-[#ff6665] whitespace-nowrap',
              'tablet:bottom-[-8px] mo:bottom-[-4px] middle:text-[0.6rem]',
            )}
          >
            {process.env.REACT_APP_ENVIRONMENT?.toUpperCase()} 환경
          </div>
        )}
      </a>
      {/* Header Logo 영역 */}
    </div>
  );
};
export default Logo;
