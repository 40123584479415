export interface TimeSliceType {
  leftItem: TimeSliceItemType;
  rightItem: TimeSliceItemType;
  centerItem?: TimeSliceItemType;
}

export interface TimeSliceItemType {
  first: string;
  second: string;
}

// 시간 두자리 slice해서 문자열로 반환
const timeToSliceString = (value: number): TimeSliceItemType => {
  if (value < 10)
    return {
      first: '0',
      second: `${value}`,
    };

  const s: string = `${value}`;
  const first = s.substring(0, 1);
  const second = s.substring(1, 2);
  return {
    first,
    second,
  };
};

export class TimeSliceVM {
  private hour: number;

  private min: number;

  private sec: number;

  constructor(hour: number, min: number, sec: number) {
    this.hour = hour;
    this.min = min;
    this.sec = sec;
  }

  public getTimeSliceData(): TimeSliceType {
    if (this.hour > 0) {
      return {
        leftItem: timeToSliceString(this.hour),
        centerItem: timeToSliceString(this.min),
        rightItem: timeToSliceString(this.sec),
      };
    }
    return {
      leftItem: timeToSliceString(this.min),
      rightItem: timeToSliceString(this.sec),
    };
  }
}
