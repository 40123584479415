import Link from 'next/link';

interface BasicLinkProps {
  href: string;
  title?: string;
  classNames?: any;
  isPrefetch?: boolean;
  onClick?: (e: any) => void;
  id?: string; // hash값으로 스크롤 처리하기 위함. id는 유니크값 적용해야함.
}

interface FCLinkProps extends BasicLinkProps {
  children: React.ReactNode;
}

const TopcoLink = () => {
  return null;
};

/**
 * title을 감싼 a 태그
 * @param "href, title, className, onClick"
 */
const Normal = (props: BasicLinkProps) => {
  const { href, title, classNames = '', isPrefetch = true, onClick } = props;
  if (!isPrefetch) {
    if (onClick) {
      return (
        <a
          href={href}
          className={classNames}
          onClick={(e: any) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {title}
        </a>
      );
    }
    return (
      <a className={classNames} href={href}>
        {title}
      </a>
    );
  } else {
    if (onClick) {
      return (
        <a
          href={href}
          className={classNames}
          onClick={(e: any) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {title}
        </a>
      );
    }
    return (
      <a className={classNames} href={href}>
        {title}
      </a>
    );
  }
};

/**
 * children을 감싼 a 태그
 * @param "href, title, className, onClick"
 */
const Parent = (props: FCLinkProps) => {
  const {
    children,
    href,
    classNames = '',
    isPrefetch = true,
    onClick,
    id = '',
  } = props;
  if (!isPrefetch) {
    if (onClick) {
      return (
        <a
          id={id}
          href={href}
          className={classNames}
          onClick={(e: any) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}
        </a>
      );
    }
    return (
      <a id={id} className={classNames} href={href}>
        {children}
      </a>
    );
  } else {
    if (onClick) {
      return (
        <a
          id={id}
          href={href}
          className={classNames}
          onClick={(e: any) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}
        </a>
      );
    }
    return (
      <a className={classNames} href={href}>
        {children}
      </a>
    );
  }
};

TopcoLink.Normal = Normal;
TopcoLink.Parent = Parent;
export default TopcoLink;
