import { CloseBtn } from '@src/view/common/closeBtn';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import { ModalWrapper } from '@src/lib/CustomModal';
import { isDesktop } from 'react-device-detect';
import { useMemo } from 'react';

const key = 'PopupBlockGuideModal';

interface PropsType {
  onClose: () => void;
}

export const openPopupBlockGuideModal = (props: PropsType) => {
  ModalInstance.getInstance().push({
    key,
    component: <PopupBlockGuideModal {...props} />,
    shouldCloseOnOverlayClick: false,
    shouldCloseOnOverlayCallback(e) {
      props.onClose();
    },
  });
};

export const closePopupBlockGuideModal = () => {
  ModalInstance.getInstance().delete(key);
};

/**
 * popup 차단 안내 레이어
 * @returns
 */
const PopupBlockGuideModal = (props: PropsType) => {
  const { onClose } = props;
  const list = useMemo((): string[] => {
    if (isDesktop) {
      return [
        'Open Safari and click on the settings icon placed at the top right corner.',
        'From the drop-down menu, uncheck "Block Pop-Up" Windows.',
      ];
    } else {
      return [
        'Go to settings and click on the Safari icon from the app list.',
        `Scroll down and toggle off the "Block Pop-ups".`,
      ];
    }
  }, []);

  return (
    <ModalWrapper.Default addClassName="max-w-[400px] w-[88vw] shadow-outline">
      <div className={'popupInner'}>
        <CloseBtn
          imgPath="/images/alert/close/ico_close.png"
          className="absolute top-[4px] right-[4px] w-[35px]"
          onClick={e => {
            onClose();
          }}
        />
      </div>
      <div className="flex flex-col w-full gap-y-[20px] p-[30px_20px_30px_20px]">
        <span className="text-center text-[1.2rem] font-[500] leading-[24px] tracking-[-0.2px]">
          Notice
        </span>
        <p className="text-[1rem] leading-[1.5] tracking-[-0.2px] p-[0px_10px_0px_10px]">
          {
            "Due to the change of Safari's pop-up policy, you have to disable pop-up blocks before signing in."
          }
        </p>
        <ul>
          {list.map((c, index) => {
            return (
              <li
                key={`popup-block-noti-list-${index}`}
                className="font-[400] text-[#8B8B8B] list-decimal list-inside pl-[1.4rem] whitespace-pre-wrap indent-[-0.8rem] text-[0.9rem] mb-[10px] tracking-[-0.2px] leading-[28px]"
              >
                {c}
              </li>
            );
          })}
        </ul>
        <div className="flex justify-center h-[44px]">
          <button
            className="rounded-[100px] border-none [5px_20px_5px_20px] bg-[#F02D37] text-white w-[65%] text-[1rem] leading-[1.5rem]"
            onClick={e => {
              e.preventDefault();
              onClose();
            }}
          >
            OK
          </button>
        </div>
      </div>
    </ModalWrapper.Default>
  );
};
