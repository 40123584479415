import Cookies from 'js-cookie';
import { create } from 'zustand';

export interface StateType {
  isMenuOpen: boolean;
  isAccountFormOpen: boolean;
  account: {
    id: string;
    password: string | number;
  };
}
export interface StoreType extends StateType {
  toggleIsMenuOpen: () => void;
  toggleIsAccountForm: () => void;
  changeAccount: (id: string, password: string | number) => void;
}

const useStore = create<StoreType>(set => ({
  isMenuOpen: false,
  isAccountFormOpen: false,
  account: {
    id: '',
    password: '',
  },
  toggleIsMenuOpen() {
    set(state => ({ isMenuOpen: !state.isMenuOpen }));
  },
  toggleIsAccountForm() {
    set(state => ({ isAccountFormOpen: !state.isAccountFormOpen }));
  },
  changeAccount(id, password) {
    Cookies.set('topco_test', `id=${id};password=${password}`);
    set(state => ({ account: { id, password }, isAccountFormOpen: false }));
  },
}));

export default useStore;
