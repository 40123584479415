import { useEffect, useState } from 'react';

import { useIntersectionObserverRef } from 'rooks';

const lightGrayImageBase64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAyAAAAGQAQMAAABs65Z3AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURfPz8////62YZ64AAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA+SURBVHja7cExAQAAAMKg9U9tDQ8gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACASzWd0AABmDxSzAAAAABJRU5ErkJggg==';

// 다크모드용 bgColor
const bgColor =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAADCAYAAAAp8DIiAAAAJ0lEQVR42u3UMQ0AMAgAsGFkBKVYxwQPSSui8bP6ARwQwgKEBbBsAHA+AptknH3aAAAAAElFTkSuQmCC';

// 로딩 이미지
const loadingImg = '/images/loading/img_loading.png';

interface PropsType {
  src: string;
  alt?: string;
  className?: any;
}

const TopcoImg = (props: PropsType) => {
  const { src, className = '', alt = src } = props;

  const [oldSrc, setOldSrc] = useState(src);
  const [addClass, setAddClass] = useState('');

  const callback = (entries: any) => {
    if (entries && entries[0]) {
      if (entries[0].isIntersecting) {
        const image = entries[0].target;

        if (image === undefined) return null;

        if (image.classList.contains('contentBeforeLoaded')) {
          image.setAttribute('src', image.getAttribute('data-src'));
          image.classList.remove('contentBeforeLoaded');
          image.style.removeProperty('min-height');
          setAddClass('');
        }
      }
    }
  };

  const [myRef] = useIntersectionObserverRef(callback, {
    rootMargin: '0px 0px 20px 0px',
  });

  useEffect(() => {
    if (src !== oldSrc) {
      setAddClass('contentBeforeLoaded');
      setOldSrc(src);
    }
  }, [src]);

  return (
    <img
      ref={myRef}
      src={loadingImg}
      data-src={src === '' ? loadingImg : src}
      className={`${className} ${addClass} contentBeforeLoaded`}
      alt={alt || src}
      title={alt || ''}
    />
  );
};

export default TopcoImg;
