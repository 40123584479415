import { PaymentWindowModeType } from '../../../data/_constants';
import cn from 'clsx';

interface ProductBenefitPropTypes {
  windowMode: PaymentWindowModeType;
  comment: string; // 첫번째 Item
  description: string; // 두번째 Item
  price?: string;
  periodValue?: number; // membership 상품 PLAN 개월수 (ex. 1 / 3 / 6)
  periodUnit?: string; // membership 상품 PLAN 개월명 (= MONTHS)
  groupType?: string;
}

const ProductBenefit = (child: React.ReactNode) => {
  return <>{child}</>;
};

const Normal = (props: ProductBenefitPropTypes) => {
  const { windowMode, comment, description, groupType } = props;
  return (
    <div
      className={cn('flex items-center justify-center mo:flex-col mo:px-3px', {
        'border-x-[1px]': groupType === 'NORMAL',
      })}
    >
      <div
        id="benefitCon"
        className={cn(
          'flex items-center justify-center mo:flex-col gap-x-[4px] font-medium',
          '[&>*]:hidden [&>*:nth-child(1)]:block [&>*:nth-child(2)]:block',
          {
            'text-[0.9rem] mo:text-[0.78rem] mo:leading-[16px] [&>*:nth-child(2)]:mo:text-[0.65rem] [&>*:nth-child(2)]:mo:leading-[14px]':
              windowMode === PaymentWindowModeType.LARGE,
            'flex-col text-[13px] mo:text-[0.78rem] leading-[16px] [&>*:nth-child(2)]:text-[11px] [&>*:nth-child(2)]:mo:text-[0.65rem] [&>*:nth-child(2)]:leading-[14px]':
              windowMode === PaymentWindowModeType.MINI,
          },
        )}
        dangerouslySetInnerHTML={{ __html: description }}
      >
        {/* {description} */}
        {/* <div
          className={cn({
            'text-center text-[17px] mo:text-[11px] mo:leading-[16px]':
              windowMode === PaymentWindowModeType.LARGE,
            'text-[11px] leading-[16px]':
              windowMode === PaymentWindowModeType.MINI,
          })}
        >
          {comment}
        </div>
        <div
          className={cn(
            'text-[#64B5FF] ml-[4px] text-center text-[15px] mo:ml-0 mo:text-[9.5px] mo:leading-[14px]',
            {
              'ml-[4px] text-[15px]':
                windowMode === PaymentWindowModeType.LARGE,
              'ml-0 text-[9.5px] leading-[14px]':
                windowMode === PaymentWindowModeType.MINI,
            },
          )}
        >
          <span>{description}</span>
        </div> */}
      </div>
    </div>
  );
};

const Membership = (props: ProductBenefitPropTypes) => {
  const { windowMode, price, periodValue, periodUnit, groupType } = props;

  return (
    <>
      {periodUnit && periodValue && (
        <div
          className={cn(
            'flex items-center justify-center leading-[0.9rem] font-[500] text-[#3C9D68] mo:flex-col mo:px-3px ',
            {
              'text-[18px] mo:text-[13px]':
                windowMode === PaymentWindowModeType.LARGE,
              'text-[13px]': windowMode === PaymentWindowModeType.MINI,
            },
            {
              'border-x-[1px]': groupType === 'MEMBERSHIP',
            },
          )}
        >
          ${(Number(price) / periodValue).toFixed(2)} / {''}
          <br className="hidden mo:block" />
          {'month'}
        </div>
      )}
    </>
  );
};

ProductBenefit.Normal = Normal;
ProductBenefit.Membership = Membership;
export default ProductBenefit;
