import React, { useMemo } from 'react';

import { CloseBtn } from '@src/view/common/closeBtn';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import { ModalWrapper } from '@src/lib/CustomModal';
import { ProductGroupType } from '../../data/_constants';

interface PropsType {
  modalKey: ProductGroupType;
}
export const closeProductGroupDetailModal = (key: string) => {
  ModalInstance.getInstance().delete(key);
};

export const openProductGroupDetailModal = (props: PropsType) => {
  ModalInstance.getInstance().push({
    key: props.modalKey,
    shouldCloseOnOverlayClick: true,
    component: <ProductGroupDetailModal {...props} />,
  });
};

export const ProductGroupDetailModal = (props: PropsType) => {
  const { modalKey } = props;

  const data: DetailType[] = useMemo(() => {
    switch (modalKey) {
      case ProductGroupType.normal: {
        return normalGroupNotiArr;
        break;
      }
      case ProductGroupType.membership: {
        return membershipGroupNotiArr;
        break;
      }
      default:
        return [];
        break;
    }
  }, [modalKey]);

  const mainTitle: string = useMemo(() => {
    switch (modalKey) {
      case ProductGroupType.normal: {
        return 'Single Payment';
        break;
      }
      case ProductGroupType.membership: {
        return 'Membership';
        break;
      }
      default:
        return '';
        break;
    }
  }, [modalKey]);

  return (
    <ModalWrapper.Default
      addClassName={
        'text-[1rem] w-[94vw] max-w-[450px] mo:h-auto mo:max-h-[90vh] overflow-hidden overflow-y-auto scrollbar-hide  break-words p-[35px_30px]'
      }
    >
      <CloseBtn
        imgPath="/images/alert/close/ico_close.png"
        className="w-[35px] h-[35px] right-0 top-0 absolute z-[1]"
        onClick={() => {
          closeProductGroupDetailModal(modalKey);
        }}
      />
      <h1 className="font-medium text-center text-primary-text">{mainTitle}</h1>
      <div className="flex flex-col h-full contentInner gap-y-[10px] my-[10px]">
        <div className="pl-[15px] mo:max-h-[70%] h-full overflow-y-auto noticeInner scrollbar-hide flex flex-col gap-y-[10px]">
          {data.map((item, index) => {
            return (
              <div
                className="contentCroup flex flex-col gap-y-[5px]"
                key={`${item}-${index}`}
              >
                <div className="subTit font-medium text-[16px] text-[#313131]">
                  {item.title}
                </div>
                <ul>
                  {item.textArea.map((c, index) => {
                    return (
                      <li
                        key={`${c}-${index}`}
                        className="list-disc list-outside text-[#aaa] text-[11px] leading-[18px] tracking-[-.2px]"
                      >
                        {c}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>

        <div className={'btn-area w-full'}>
          <button
            type="button"
            className={
              'btn-confirm block bg-primaryRed text-white text-[15px] rounded-[50px] font-medium h-[46px] w-full max-w-[200px] mx-auto'
            }
            onClick={() => {
              closeProductGroupDetailModal(modalKey);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </ModalWrapper.Default>
  );
};

interface DetailType {
  title: string;
  textArea: string[];
}

/**
 * 일반상품 상세내용
 */
const normalGroupNotiArr: DetailType[] = [
  {
    title: 'Coin Bundles',
    textArea: [
      'To unlock and read any series you want, you have to sign in and purchase coins first.',
      'Bonus coins have an expiry date, and the time may vary depending on the type of your bonus.',
      'Delay may happen until your coins to be reflected on your account depending on your network environment.',
      'Any series or episode unlocked using coins will be permanently stored in your account.',
      'All sales are final, and refund will not take in place once the comics are read.',
      'For all other refund requests, please reach out to our customer support at cs@daycomics.com.',
    ],
  },
  {
    title: 'Auto-reload',
    textArea: [
      'Auto-Reload will automatically reloads your coin when it drops below 10.',
      'Reload amount varies depending on the bundles you chose.',
      'You will get charged from your initial payment method at the price you initially chose on every reload.',
      'You can cancel or pause your reload feature anytime from your profile.',
      'Auto-Reload feature may not be supported on certain apps and devices.',
    ],
  },
];

/**
 * 멤버십상품 상세내용
 */
const membershipGroupNotiArr: DetailType[] = [
  {
    title: '',
    textArea: [
      'All free promotion and service are already included in our Premium Membership.',
      'Any series or episode accessed using membership will not be stored, and you will lose the accessibility once your membership ends.',
      'Membership will automatically renew at the same price and in the same interval that you have initially joined in for.',
      'By joining our membership, you agree that your membership will continue until you cancel it on your remark.',
      'Cancellation or cesation of the service without any notice may happen if you have insufficient funds or have changed your payment method while your membership is active.',
      'You can cancel your membership anytime from your profile by clicking "Withdraw Membership".',
      'Retrieval of your membership status and advantage will be restricted once you finalize the cancellation process.',
      'Please be noted that signing up for our membership does not grant a full access to every single title on our website. (Non-exclusive series can only be accessed using coins)',
      'Membership benefits are limited to one individual account per user.',
      'Please refer to our Terms of Service for more details.',
    ],
  },
];
