import { ApiApp } from '@toptoon-developers/global.toptoonplus.common.lib/dist/apiV2/client/V1';
import { UserDefault } from '@src/lib';

const useAppData = () => {
  const updateFcmToken = (
    accessToken: string,
    fcmToken: string,
    version: string,
  ) => {
    if (!accessToken) return;

    if (fcmToken.length === 0) return;

    const userSesson = UserDefault.getUserSession();
    userSesson.headers.token = accessToken ?? '';

    new ApiApp(userSesson)
      .updatePushToken(fcmToken, `${version}`)
      .then((res: any) => {})
      .catch((err: any) => {});
  };

  return { updateFcmToken };
};

export default useAppData;
