import { atom, selector } from 'recoil';
import { AtomKey, AtomStorageKey } from '../_constants';
import { recoilPersist } from 'recoil-persist';

const localStorage =
  typeof window !== 'undefined' ? window.localStorage : undefined;

const { persistAtom } = recoilPersist({
  key: AtomStorageKey.SEARCH_LOG,
  storage: localStorage,
});

export const SearchText = atom<string>({
  key: AtomKey.SEARCH_TEXT,
  default: '',
});

export const SearchLogList = atom<string[]>({
  key: AtomKey.SEARCH_LOG,
  default: [],
  effects: [persistAtom],
});

export const SetSearchLog = selector({
  key: AtomKey.SEARCH_LOG_SET,
  get: ({ get }) => {
    return get(SearchText);
  },
  set: ({ get, set }, saveSearchText) => {
    const searchLogList = get(SearchLogList);
    const lastLog = searchLogList[searchLogList.length - 1];
    const searchText = `${saveSearchText}`;

    if (!searchText || lastLog === searchText) {
      return;
    }
    // empty 거르기
    if (searchLogList.length > 0) {
      if (
        // 배열의 마지막 text 현재 검색키워드가 포함되거나 (텍스트 추가입력)
        // 검색 키워드에 배열의 마지막 text에 포함될때 (텍스트 일부제거)
        (searchText.length > lastLog.length &&
          searchText.indexOf(lastLog) >= 0) ||
        (searchText.length < lastLog.length && lastLog.indexOf(searchText) >= 0)
      ) {
        const searchLog = [...searchLogList.slice(0, -1), searchText];
        return set(SearchLogList, searchLog);
      } else if (
        lastLog !== searchText &&
        searchLogList.indexOf(searchText) === -1
      ) {
        const searchLog = [...searchLogList, searchText];
        return set(SearchLogList, searchLog);
      }
    } else if (searchLogList.length === 0) {
      const searchLog = [searchText];
      return set(SearchLogList, searchLog);
    }
    return;
  },
});
