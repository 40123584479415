import useSWR, { useSWRConfig } from 'swr';

import { MARKETING_MATURE } from './_constants';
import { TopcoViewerRequestApi } from './TopcoViewer';
import { ViewerContext } from '../context';

const useViewerData = (
  comicId: number,
  episodeId: number,
  marketingMature: MARKETING_MATURE,
) => {
  const { mutate: swrMutate } = useSWRConfig();

  const { data: viewerData, error } = useSWR<ViewerContext, Error>(
    {
      url: `page/episode?comicId=${comicId}/${episodeId}`,
      args: {
        comicId: comicId,
        episodeId: episodeId,
        marketingMature,
      },
    },
    ({ args }) =>
      TopcoViewerRequestApi.getViewerData(
        args.comicId,
        args.episodeId,
        args.marketingMature,
      ),
    {
      revalidateOnFocus: false,
    },
  );
  return {
    mutate: swrMutate,
    viewerData,
    error,
  };
};

export default useViewerData;
