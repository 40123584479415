import React from 'react';
import { PaymentWindowModeType } from '../../../data/_constants';
import cn from 'clsx';

interface PropsType {
  windowMode: PaymentWindowModeType;
}

const RefillHeader = (props: PropsType) => {
  const { windowMode } = props;

  return (
    <div
      className={cn(
        'bg-[#FFF9D6] dark:bg-[#3E3817] border-t-black flex items-center py-[12px] px-[18px] border-[1px] border-t-[2px] mo:py-[10px] mo:px-[6px]',
        {
          'py-[10px] px-[6px]': windowMode === PaymentWindowModeType.MINI,
        },
      )}
    >
      <div className="flex items-center justify-center">
        <img
          className="w-[26px]"
          src="/images/payment/light/icon_auto.png"
          alt="ico_auto"
        />
      </div>
      <div
        className={cn(
          'flex ml-[5px]',
          {
            'items-center mo:items-start mo:flex-col':
              windowMode === PaymentWindowModeType.LARGE,
          },
          {
            'items-start flex-col': windowMode === PaymentWindowModeType.MINI,
          },
        )}
      >
        <div className={cn('flex items-center')}>
          <div
            className={cn(
              'font-[600] text-[#FFA825] dark:text-[#FFCF26] ',
              {
                'text-[18px] mo:text-[13px]':
                  windowMode === PaymentWindowModeType.LARGE,
              },
              {
                'text-[13px]': windowMode === PaymentWindowModeType.MINI,
              },
            )}
          >
            Auto Reload:{' '}
          </div>
          <div
            className={cn(
              'text-primary-text dark:text-[#fff] ml-[5px]',
              {
                'text-[18px] mo:text-[13px] font-[600]':
                  windowMode === PaymentWindowModeType.LARGE,
              },
              {
                'text-[13px]': windowMode === PaymentWindowModeType.MINI,
              },
            )}
          >
            Hassle free!{' '}
          </div>
        </div>
        <div
          className={cn(
            'text-primary-text-info dark:text-[#8F8756]',
            {
              'text-[15px] mo:text-[11px] mo:leading-[11px] mo:font-[500] ml-[5px] mo:ml-0':
                windowMode === PaymentWindowModeType.LARGE,
            },
            {
              'text-[11px] leading-[11px] font-[500] ml-0':
                windowMode === PaymentWindowModeType.MINI,
            },
          )}
        >
          Auto reloads when below 10 coins
        </div>
      </div>
    </div>
  );
};

export default RefillHeader;
