import cn from 'clsx';
import { SnsBtnTypeCode } from './constants/SnsBtnTypeCode';

interface PropTypes {
  type: SnsBtnTypeCode;
}
const SnsComment = (props: PropTypes) => {
  const { type } = props;
  if (type === SnsBtnTypeCode.Default) return null;
  return (
    <span
      className={cn(
        'comt text-[11px] text-[#aaa] leading-[13px] text-center max-w-[72px] break-words block mx-auto mt-[7px]',
      )}
    >
      Previous login method
    </span>
  );
};
export default SnsComment;
