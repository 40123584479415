import { PgItemType } from '../context';

export enum PaymentAction {
  selectProductItem = 'selectProductItem',
  setPgList = 'setPgList',
}

export enum ProductGroupType {
  membership = 'MEMBERSHIP',
  normal = 'NORMAL',
  autopayment = 'AUTO_PAYMENT',
  refill = 'REFILL',
}

export enum PaymentWindowModeType {
  LARGE = 'PAYMENT_PAGE',
  MINI = 'PAYMENT_MODAL',
  MEMBERSHIP_DETAIL_PAGE = 'MEMBER_PAGE',
  PAYPAL = 'PAYMENT_PAYPAL',
}

// device
export const Device = {
  PC: 2,
  MOBILE_IOS: 4,
  MOBILE_ANDROID: 8,
  APP_IOS: 32,
  APP_ANDROID: 64,
};

// 일반상품
export const pgList: PgItemType[] = [
  {
    type: 'paypal',
  },
  {
    type: 'eximbay',
  },
  {
    type: 'payletter',
  },
  {
    type: 'paymentwall',
  },
];

// 정기결제 상품
export const pgRecurringList: PgItemType[] = [
  {
    type: 'eximbay',
  },
  {
    type: 'payletter',
  },
];

export interface BonusCoin {
  coin: number;
  label: string;
  name: string;
  order: number;
  period: number;
  periodUnit: string;
  type: number;
}

export type GroupTypes = {
  title: string;
  code: string;
  userGroupCode: string;
  description: string;
};
