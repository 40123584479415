import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import { CloseBtn } from '@src/view/common/closeBtn';

export interface Callback {
  onConfirm: (e: any) => void;
  onClose: (e: any) => void;
}

interface PropsType {
  key: string;
  msg: string;
  btnName?: string;
  callback: Callback;
}

export const openConfirmModal = (props: PropsType) => {
  ModalInstance.getInstance().push({
    key: props.key,
    shouldCloseOnOverlayClick: false,
    shouldCloseOnEsc: false,
    shouldCloseOnOverlayCallback(e) {
      props.callback.onClose(e);
    },
    component: <ConfirmModal {...props} />,
  });
};

export const closeConfirmModal = (key: string) => {
  ModalInstance.getInstance().delete(key);
};

const ConfirmModal = (props: PropsType) => {
  const { msg, btnName = 'Confirm', callback } = props;
  return (
    <div className="animation_fadein bg-white z-201 max-w-[300px] w-[88vw] max-h-[90vh] overflow-hidden bg-[rgba(0, 0, 0, 0.8)]">
      <div className="my-auto mx-auto p-0 rounded-[0] overflow-hidden relative">
        <CloseBtn
          imgPath="/images/alert/close/ico_close.png"
          className="w-[35px] h-[35px] right-[4px] top-[4px] absolute"
          onClick={callback.onClose}
        />
        <div className="tracking-[-0.2px] py-[15px] px-0">
          <div className="pt-[1.2rem] px-[20px] pb-[1.6rem] text-[#666] font-[400] text-[0.8rem] leading-[1.1rem] text-center">
            <strong className="text-[#000] block text-[1rem] font-[500] mb-[10px]">
              {msg}
            </strong>
            <div className="mt-[20px] overflow-hidden flex justify-center">
              <button
                className="w-[80%] rounded-[50px] h-[44px] leading-[44px] text-[17px] overflow-hidden bg-[#f02d37] text-[#fff] float-right"
                type="button"
                onClick={callback.onConfirm}
              >
                {btnName}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
