import useLogin from '@src/hooks/useLogin';
import { UserSession } from '@src/lib';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import { CloseBtn } from '@src/view/common/closeBtn';
import { openMiniPaymentModal } from '../payment';
import { ActionLayerType } from '@src/middleware/model';

const key = 'ACTION_LAYER_MODAL';

interface Props {
  actionLayer: ActionLayerType.ActionLayer;
  openMiniPayment: boolean;
  updateActionLayerLog: (actionLayerId: number, buttonId?: number) => void;
}

export const openActionLayerModal = (props: Props) => {
  const { actionLayer } = props;
  ModalInstance.getInstance().push({
    key,
    className: 'action-layer-modal',
    component: <ActionLayerModalComponent {...props} />,
    shouldCloseOnOverlayClick: actionLayer.closeOnOutsideClick, // overlay 클릭 닫기 여부
    onOverlayClick: (e: any) => {
      e.target.disabled = true; // 중복 클릭 방지
      if (!props.actionLayer.isCloseLogging) return; // isCloseLogging false 면 동작 안함
      props.updateActionLayerLog(props.actionLayer.actionLayerId);
    },
  });
};
export const closeActionLayerModal = () => {
  ModalInstance.getInstance().delete(key);
};

const ActionLayerModalComponent = (props: Props) => {
  const { actionLayer, openMiniPayment, updateActionLayerLog } = props;
  const {
    actionLayerId,
    imageUrl,
    closeBtnDisplay,
    closeBtnColorCode,
    isCloseLogging,
    buttonList,
    ...btnStyle // btnWidth,btnHeight,btnFontSize,btnLineHeight,btnBottom,btnGap,
  } = actionLayer;
  const { showJoinAndLoginModal } = useLogin();

  return (
    <div className="w-auto max-w-[440px] mo:w-[85vw]">
      {/* 상단닫기버튼 */}
      <div className="w-full bg-white overflow-hidden break-all relative">
        {closeBtnDisplay && (
          <CloseBtn
            imgPath={`/images/alert/ico_close_${closeBtnColorCode}.png`}
            className={'w-[20px] h-[20px] top-[10px] right-[10px] absolute'}
            onClick={(e: any) => {
              e.target.disabled = true; // 중복 클릭 방지
              closeActionLayerModal();
              if (!isCloseLogging) return;
              updateActionLayerLog(actionLayerId);
            }}
          />
        )}
        <div className="w-full">
          <img src={imageUrl} alt="crazytimer" />
        </div>
        <div
          className="absolute w-full flex justify-center"
          style={{
            bottom: `${btnStyle.btnBottom}`,
            columnGap: `${btnStyle.btnGap}`,
          }}
        >
          {buttonList.map((button, index) => {
            return (
              <button
                key={`actionlayer-button-${index}-${button.buttonId}`}
                className="outline-none border-none p-0 m-0"
                style={{
                  height: `${btnStyle.btnHeight}`,
                  width: `${btnStyle.btnWidth}`,
                  lineHeight: `${btnStyle.btnLineHeight}`,
                  fontSize: `${btnStyle.btnFontSize}`,
                  background: `${button.bgColorCode}`,
                  color: `${button.textColorCode}`,
                }}
                onClick={(e: any) => {
                  e.target.disabled = true; // 중복 클릭 방지
                  closeActionLayerModal();
                  if (button.isLogging) {
                    updateActionLayerLog(actionLayerId, button.buttonId);
                  }
                  if (button.action === 'url') {
                    if (button.url === '/payment') {
                      // 미니 결제창 뜨는곳은 페이지 이동없이 로그인 시켜야함
                      if (openMiniPayment) {
                        if (UserSession.getUserInfo().token !== '') {
                          openMiniPaymentModal();
                        } else {
                          showJoinAndLoginModal();
                        }
                        return;
                      }
                    }
                    window.location.href = button.url;
                  }
                }}
              >
                {button.text}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ActionLayerModalComponent;
