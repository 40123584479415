import { UserDefault, UserSession } from '../user';

import Cookies from 'js-cookie';

/**
 * Marketing script 처리를 위한 module
 * TODO 해당 클래스엥서 접근하는 cookie 및 api는 clean Architecture 영역에서 별도로 생각함.
 */
export module Marketing {
  const isStop = process.env.NEXT_PUBLIC_V2_STAGE !== 'production';

  /**
   * assist 매체
   * 회차 / 뷰어 시점에 쿠키(mAssist) 저장
   * 회원 가입 / 결제 완료 시점에 해당 쿠키가 존재할 경우 api 송신
   * test: http://localhost:3000/comic/103021/?p_id=for_mime&assistclickid=d4d1952c-7af7-1903-8d24-fb33d3c7f621
   * https://toptoon.jp/comic/103021?assistclickid=b1823eaf-41b0-4bf2-91b8-3ad74f9e4400&p_id=mine01
   */
  export const Assist = {
    url: 'https://adserver.assistads.net/cv',
    key: 'mAssist',
    getCookie: (): string => {
      return Cookies.get(Assist.key) ?? '';
    },
    /**
     * 해당 key 값이 있을 경우에만 호출 (회원가입 결제 완료에서만 호출)
     * @returns
     */
    sendApi: async (): Promise<boolean> => {
      const value = Assist.getCookie();
      if (!value) return false;

      try {
        await fetch(`${Assist.url}?assistclickid=${value}`, {
          mode: 'no-cors',
        });
        return true;
      } catch (e: any) {
        return false;
      }
    },
  };

  /**
   * TrafficJunky 매체
   */
  export const TrafficJunky = {
    url: 'https://ads.trafficjunky.net/ct',
    key: 'mTrafficJunky',
    getCookie: (): string => {
      return Cookies.get(TrafficJunky.key) ?? '';
    },
    sendSignupApi: async (userId: number): Promise<boolean> => {
      const value = TrafficJunky.getCookie();
      if (!value) return false;

      try {
        await fetch(
          `${
            TrafficJunky.url
          }?a=1000482741&member_id=1006491611&cb=${Math.floor(
            Date.now() / 1000,
          )}&cti=${userId}&ctv=1&ctd=&aclid=${value}`,
          {
            mode: 'no-cors',
          },
        );
        return true;
      } catch (e: any) {
        return false;
      }
    },

    sendPurchaseApi: async (
      payInfo: { id: string; value: number; currency: string } | null,
    ): Promise<boolean> => {
      if (!payInfo) return false;
      const value = TrafficJunky.getCookie();
      if (!value) return false;

      const { userId } = UserSession.getUserInfo();

      try {
        await fetch(
          `${
            TrafficJunky.url
          }?a=1000482751&member_id=1006491611&cb=${Math.floor(
            Date.now() / 1000,
          )}&cti=${payInfo.id}&ctv=${payInfo.value}&ctd=${
            payInfo.id
          }${userId}&aclid=${value}`,
          {
            mode: 'no-cors',
          },
        );
        return true;
      } catch (e: any) {
        return false;
      }
    },
  };
}
