import { useState } from 'react';

const useInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const handleValue = (e: any) => {
    setValue(e.target.value);
  };
  return [value, handleValue, setValue];
};

export const useToggle = (initialState: any) => {
  // 상태값을 toggle 시켜주는 Hooks , 나중에 파일 분리하기
  const [state, setState] = useState(initialState);

  const toggle = () => setState((prev: boolean) => !prev);

  return [state, toggle, setState];
};

export default useInput;
