import _ from 'lodash';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import useMediaBreak from './useMediaBreak';
import { DeviceBit, layoutList } from '@src/constants/Layout';
import { UserSession } from '@src/lib';

interface LayerDisplayType {
  headerHidden: boolean;
  footerHidden: boolean;
  bodyWrapperHidden: boolean;
}

const useTheLayouotCtrl = () => {
  const [layoutHidden, setLayoutHidden] = useState<LayerDisplayType>({
    headerHidden: false,
    footerHidden: false,
    bodyWrapperHidden: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const { isDesktop, isMobile } = useMediaBreak();

  const router = useRouter();
  const { pathname } = router;

  const isViewHidden = useCallback(
    (viewState: number, isMobile: boolean, isPC: boolean): boolean => {
      if (viewState === DeviceBit.NONE) return true;

      if (isMobile) {
        return (viewState & DeviceBit.MOBILE) === 0;
      } else if (isPC) {
        return (viewState & DeviceBit.PC) === 0;
      } else {
        return (viewState & DeviceBit.TABLET) === 0;
      }
    },
    [],
  );

  /**
   * !isMobile : tablet 포함
   * !isDesckTop : tablet 포함
   */
  useEffect(() => {
    // const { mature } = UserSession.getUserInfo();
    const item = _.find(layoutList, { path: pathname });

    if (!item) {
      setLayoutHidden({
        headerHidden: false,
        footerHidden: false,
        bodyWrapperHidden: false,
      });

      setIsLoading(false);
      return;
    }

    // if (item.path === '/' && mature === 0) {

    // }

    const h_hidden = isViewHidden(item.headerVisible, isMobile, isDesktop);
    const f_hidden = isViewHidden(item.footerVisible, isMobile, isDesktop);
    const b_hidden = isViewHidden(item.bodyWrapperVisible, isMobile, isDesktop);
    setLayoutHidden({
      headerHidden: h_hidden,
      footerHidden: f_hidden,
      bodyWrapperHidden: b_hidden,
    });

    setIsLoading(false);

    // setBodyWrapperHidden(
    // pathname === '/' ||
    // pathname === '/appSnsCallback' ||
    // pathname === '/transferCallback' ||
    // pathname === '/content/[comicId]/[episodeId]' ||
    // pathname === '/ipblock' ||
    // (!isDesktop && pathname === '/event/theme/[themeId]'),
    // );

    // setHeaderHidden(
    // pathname === '/content/[comicId]/[episodeId]' ||
    // pathname === '/comic/[comicId]/[episodeId]' ||
    // pathname === '/comicprologue/[comicId]/[episodeId]' ||
    // pathname === '/popup' ||
    // pathname === '/transferCallback' ||
    // pathname === '/appSnsCallback' ||
    // pathname.includes('/cb/[pg]') ||
    // pathname === '/popup/[pgId]' ||
    // pathname === '/blockUser' ||
    // pathname === '/serverCheck' ||
    //   (!isDesktop &&
    //     (pathname === '/comic/[comicId]' ||
    //       pathname === '/content/[comicId]')) ||
    //     (isMobile &&
    //       (pathname === '/hashtag/keyword/[keyword]' ||
    //         pathname === '/hashtag/genre/[genre]')),
    // );

    // setFooterHidden(
    // pathname === '/popup' ||
    // pathname === '/appSnsCallback' ||
    // pathname === '/transferCallback' ||
    // pathname === '/content/[comicId]/[episodeId]' ||
    // pathname === '/comic/[comicId]/[episodeId]' ||
    // pathname === '/comicprologue/[comicId]/[episodeId]' ||
    // pathname === '/serverCheck' ||
    // pathname.includes('/cb/[pg]') ||
    // pathname === '/popup/[pgId]' ||
    // !isDesktop &&
    //   (pathname === '/hashtag/genre/[genre]' ||
    //     pathname === '/hashtag/keyword/[keyword]'),
    // );
    // setIsLoading(false);
  }, [pathname, isDesktop, isMobile]);

  return {
    layoutHidden,
    isLoading,
  };
};

export default useTheLayouotCtrl;
