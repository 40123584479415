import {
  AutoScrollModeState,
  AutoScrollModeType,
  AutoScrollSpeedState,
} from '@src/atom/viewer';
import { useGlobalClick } from '@src/hooks';
import React, { useCallback, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';

const useAutoScroll = () => {
  const requestRef = useRef<number>(0);
  const scrollingRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const [autoScrollMode, setAutoScrollMode] =
    useRecoilState(AutoScrollModeState);

  const [autoScrollSpeed, setAutoScrollSpeed] =
    useRecoilState(AutoScrollSpeedState);

  /**
   * 자동스크롤 중 '터치' or '스크롤 이동'시
   */
  useEffect(() => {
    if (autoScrollMode !== AutoScrollModeType.OFF) {
      // 자동스크롤 중일때 '터치' or '스크롤 이동' or '스피드 조절시 일시정지
      window.addEventListener('touchstart', handlePauseAutoScrollEvent);
      window.addEventListener('touchmove', handlePauseAutoScrollEvent);
      window.addEventListener('mousedown', handlePauseAutoScrollEvent);
      window.addEventListener('wheel', handleWheelScrollEvent);
      if (autoScrollMode === AutoScrollModeType.PAUSE) {
        // 일시정지 이후 재시작 시킴
        window.addEventListener('touchend', handleRestartAutoScrollEvent);
        window.addEventListener('mouseup', handleRestartAutoScrollEvent);
        // ↓↓------------------ event remove ------------------↓↓
      } else {
        window.removeEventListener('touchend', handleRestartAutoScrollEvent);
        window.removeEventListener('mouseup', handleRestartAutoScrollEvent);
      }
    } else {
      window.removeEventListener('touchstart', handlePauseAutoScrollEvent);
      window.removeEventListener('touchmove', handlePauseAutoScrollEvent);
      window.removeEventListener('mousedown', handlePauseAutoScrollEvent);
      window.removeEventListener('wheel', handleWheelScrollEvent);
    }
    return () => {
      window.removeEventListener('touchstart', handlePauseAutoScrollEvent);
      window.removeEventListener('touchmove', handlePauseAutoScrollEvent);
      window.removeEventListener('wheel', handleWheelScrollEvent);
      window.removeEventListener('mousedown', handlePauseAutoScrollEvent);
      window.removeEventListener('touchend', handleRestartAutoScrollEvent);
      window.removeEventListener('mouseup', handleRestartAutoScrollEvent);
    };
  }, [autoScrollMode]);

  // ============모달 관련 ================
  // 모달을 닫은 후 재실행 시키기
  useGlobalClick({
    id: 'topco_react_body_0',
    useClick:
      autoScrollMode !== AutoScrollModeType.OFF &&
      autoScrollMode === AutoScrollModeType.PAUSE,
    callback: (isSearch: boolean) => {
      if (isSearch) {
        if (!document.body.className.includes('modal')) {
          setAutoScrollMode(AutoScrollModeType.ON);
        }
      }
    },
  });

  //모달이 뜬 후 일시정기 시키기
  useEffect(() => {
    if (autoScrollMode === AutoScrollModeType.OFF) return;
    if (document.body.className.includes('modal')) {
      setAutoScrollMode(AutoScrollModeType.PAUSE);
    }
  }, [document.body.className]);
  // ============모달 관련 ================

  /** 뷰어 벗어났을때, 자동스크롤 멈춤 */
  useEffect(() => {
    return () => {
      if (requestRef.current > 0 && autoScrollMode !== AutoScrollModeType.OFF)
        window.cancelAnimationFrame(requestRef.current);
    };
  }, [requestRef, autoScrollMode]);

  /**
   * autoScrollMode 바뀔때마다 실행되고 있음
   *  * 현재 위치가 뷰어 젤 하단에 도달할때까지 계속 실행
   * @param 현재위치 : auto scroll mode가 바뀔때, 위치값을 한번 받아온다
   */
  const startAutoScroll = useCallback(
    (posY: number, isMobile: boolean) => {
      if (autoScrollMode !== AutoScrollModeType.ON) return;
      document.body.style.overflow = 'auto';

      /**
       * 유저가 설정한 스피드 값을 실제로 사용할 스피드로 변환
       */
      const realSpeed = autoScrollSpeed + 8;
      const perPx = isMobile ? realSpeed / 4 : realSpeed / 2;
      const end = document.getElementById('endOfViewer'); // 뷰어 전체 길이
      const viewerHeight = end ? end.offsetTop : window.innerHeight;

      // 매개변수 posY는 한번 받아오므로, callback move함수가 실행될때마다 재할당 해주기 위해 let pos 변수를 선언
      let pos = posY;

      /**
       * interval처럼 requestAnimationFrame의 callback(move함수)이 조건에 만족할때까지 재귀적으로 실행된다.
       */
      const move = () => {
        window.cancelAnimationFrame(requestRef.current);
        scrollTo({
          top: pos + perPx,
        });
        pos = pos + perPx; // 변화된 현재 위치값 재할당

        if (viewerHeight - pos - window.innerHeight > 0) {
          // requestAnimationFrame(1000ms/60frame)
          // - 리페인트 이전에 실행할 콜백을 인자로 받아 부드럽게 움직이도록 해준다.
          // - setInterval, setTimeout과 비슷하지만 프레임을 신경쓰느냐 아니냐의 차이가 있어 코드가 복잡해 질수록 뚝뚝 끊기게 된다.
          requestRef.current = window.requestAnimationFrame(move); // 조건에 만족할때까지 callback 실행
        } else {
          window.cancelAnimationFrame(requestRef.current);
        }
      };

      move(); // 한번실행
    },
    [autoScrollMode, autoScrollSpeed, requestRef],
  );

  // 스크롤 일시정지
  // 속도조절, 스크롤이동, up, down 버튼 클릭시
  const handlePauseAutoScrollEvent = (e: any) => {
    if (
      !e.target.closest(`#${'auto_setting_wrap'}`) &&
      !e.target.closest(`#${'up_btn'}`) &&
      !e.target.closest(`#${'down_btn'}`) &&
      !e.target.closest(`#${'comicContainer'}`)
    )
      return;

    if (e.target.closest(`#${'comicContainer'}`)) {
      if (e.type === 'touchmove') {
        setAutoScrollMode(AutoScrollModeType.PAUSE);
      }
      return;
    }

    setAutoScrollMode(AutoScrollModeType.PAUSE);
  };

  // 스크롤 재시작
  const handleRestartAutoScrollEvent = (e: any) => {
    if (
      !e.target.closest(`#${'auto_setting_wrap'}`) &&
      !e.target.closest(`#${'up_btn'}`) &&
      !e.target.closest(`#${'down_btn'}`)
    )
      return;
    ``;
    // 잠깐의 딜레이가 있어야 동작.
    clearTimeout(scrollingRef.current);
    scrollingRef.current = setTimeout(() => {
      scrollingRef.current = undefined;
      setAutoScrollMode(AutoScrollModeType.ON);
      // dispatch(viewerStore.Action.clickUpAndDown(false));
    }, 20);
  };

  const handleWheelScrollEvent = (e: any) => {
    if (!e.target.closest(`#${'comicContainer'}`)) return;

    if (e.deltaY > 10) {
      setAutoScrollMode(AutoScrollModeType.PAUSE);
      // wheel 동작 멈춤 처리
      clearTimeout(scrollingRef.current);
      scrollingRef.current = setTimeout(() => {
        setAutoScrollMode(AutoScrollModeType.ON);

        scrollingRef.current = undefined;
      }, 100);
    } else if (e.deltaY < -20) {
      setAutoScrollMode(AutoScrollModeType.PAUSE);
    }
  };

  return {
    startAutoScroll,
  };
};

export default useAutoScroll;
