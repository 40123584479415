import { PaymentWindowModeType } from '@src/view/components/payment/data/_constants';
import cn from 'clsx';
import { useMemo } from 'react';

interface ProductBenefitPropTypes {
  windowMode: PaymentWindowModeType;
  comment: string; // 첫번째 Item
  description: string; // 두번째 Item
  price?: string;
  periodValue?: number; // membership 상품 PLAN 개월수 (ex. 1 / 3 / 6)
  periodUnit?: string; // membership 상품 PLAN 개월명 (= MONTHS)
}

interface MembershipBenefitPropTypes {
  price?: string;
  cycle?: string;
  windowMode: PaymentWindowModeType;
  isSelect: boolean | undefined;
  payType?: string; // 결제수단 paypal에서 문구 제거용 : 모바일에서 너무 비좁아서 기획쪽 요청
  description: string;
  ableFreeTrial: boolean;
  freeTrial: number;
  periodValue?: number; // membership 상품 PLAN 개월수 (ex. 1 / 3 / 6)
  periodUnit?: string; // membership 상품 PLAN 개월명 (= MONTHS)
}

const Benefit = (child: React.ReactNode) => {
  return <>{child}</>;
};

const Normal = (props: ProductBenefitPropTypes) => {
  const { windowMode, comment, description } = props;
  return (
    <div
      className={cn('flex items-center justify-center mo:flex-col mo:px-3px')}
    >
      <div
        id="benefitCon"
        className={cn(
          'flex items-center justify-center mo:flex-col gap-x-[4px] text-[#64B5FF] break-keep font-medium',
          '[&>*]:hidden [&>*:nth-child(1)]:block [&>*:nth-child(2)]:block',
          {
            'text-[0.9rem] mo:text-[0.825rem] mo:leading-[16px] [&>*:nth-child(2)]:mo:text-[0.65rem] [&>*:nth-child(2)]:mo:leading-[14px]':
              windowMode === PaymentWindowModeType.LARGE,
            'flex-col text-[0.825rem] leading-[16px] [&>*:nth-child(2)]:text-[0.65rem] [&>*:nth-child(2)]:leading-[14px]':
              windowMode === PaymentWindowModeType.MINI,
          },
        )}
        dangerouslySetInnerHTML={{ __html: description }}
      >
        {/* {description} */}
        {/* <div
          className={cn({
            'text-center text-[17px] mo:text-[11px] mo:leading-[16px]':
              windowMode === PaymentWindowModeType.LARGE,
            'text-[11px] leading-[16px]':
              windowMode === PaymentWindowModeType.MINI,
          })}
        >
          {comment}
        </div>
        <div
          className={cn(
            'text-[#64B5FF] ml-[4px] text-center text-[15px] mo:ml-0 mo:text-[9.5px] mo:leading-[14px]',
            {
              'ml-[4px] text-[15px]':
                windowMode === PaymentWindowModeType.LARGE,
              'ml-0 text-[9.5px] leading-[14px]':
                windowMode === PaymentWindowModeType.MINI,
            },
          )}
        >
          <span>{description}</span>
        </div> */}
      </div>
    </div>
  );
};

const Membership = (props: MembershipBenefitPropTypes) => {
  const {
    price,
    cycle,
    windowMode,
    isSelect,
    payType,
    description,
    ableFreeTrial,
    periodValue,
    periodUnit,
    freeTrial,
  } = props;
  // const cycleString = Number(cycle?.substring(0, 1));

  const priceInfo = useMemo((): string => {
    let getPeriod = 1;
    if (periodValue && periodValue !== undefined) getPeriod = periodValue;

    return `${price} every ${getPeriod} ${getPeriod > 1 ? 'months' : 'month'}`;
  }, [price, periodValue]);

  return (
    <div
      className={cn(
        'border-primary-border dark:border-x-[#3f3f3f] price flex justify-between items-center gap-x-[3px] text-left py-[14px] px-[20px] mo:text-[12px] mo:px-[10px]',
        {
          'text-[14px]': windowMode === PaymentWindowModeType.LARGE,
          'text-[12px]': windowMode === PaymentWindowModeType.MINI,
        },
        '[&_ul]:list-[disc] [&_ol]:list-[decimal] [&_li]:list-[inherit]',
      )}
    >
      <div className="leading-[1rem]">
        <ul
          className={cn('px-[15px] mo:px-[10px] break-keep leading-5', {
            'px-[15px] break-keep': windowMode === PaymentWindowModeType.LARGE,
            'px-[10px] break-keep': windowMode === PaymentWindowModeType.MINI,
          })}
        >
          {ableFreeTrial && freeTrial > 0 && (
            <li className="list-disc marker:text-[4px] break-keep">
              START {freeTrial}-DAY FREE TRIAL
            </li>
          )}
          <li className="list-disc marker:text-[4px] break-keep">
            Access to more than 26,000 stories
          </li>
          <li className="list-disc marker:text-[4px] break-keep">
            Billed ${priceInfo}
          </li>
          {/* TODO: 두번째 li부터 margin-top: 3px 추가 */}
        </ul>
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: description }}></div> */}
    </div>
  );
};
Benefit.Normal = Normal;
Benefit.Membership = Membership;
export default Benefit;
