import { SearchLogList } from '@src/atom/search/searchText';
import { UserDefault, UserSession } from '@src/lib';
import { ApiComic } from '@toptoon-developers/global.toptoonplus.common.lib/dist/apiV2/client/V1';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useRouter } from 'next/router';

interface PropsType {
  id: string;
  useClick: boolean;
  callback: (isSearch: boolean, e?: any) => void;
}

const useGlobalClick = (props: PropsType) => {
  const { id, useClick, callback } = props;
  const router = useRouter();
  const clearSearchLog = useResetRecoilState(SearchLogList);
  const searchLog = useRecoilValue(SearchLogList);
  const handle = (e: any) => {
    // 자신부터 부모중에 매칭되는 id가 있는지 찾음
    if (e.target.closest(`#${id}`) !== null) {
      callback(true, e);
    } else {
      callback(false, e);
    }
  };

  useEffect(() => {
    // searchLog 통신 api

    async function func() {
      const comicId = Number(Cookies.get('search_comicId')) ?? null;
      const t = [...searchLog];
      clearSearchLog();
      await new ApiComic(UserDefault.getUserSession())
        .updateSearchByKeywords(t, comicId)
        .then(res => {
          Cookies.remove('search_comicId');
        })
        .catch(err => {
          Cookies.remove('search_comicId');
        });
    }
    if (searchLog.length > 0) {
      func();
    }
  }, [router]);

  useEffect(() => {
    if (useClick) {
      window.removeEventListener('click', handle);
      window.addEventListener('click', handle);
    } else {
      window.removeEventListener('click', handle);
    }

    return () => {
      window.removeEventListener('click', handle);
    };
  }, [useClick]);
};

export default useGlobalClick;
