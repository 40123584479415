import { AtomKey, AtomStorageKey } from '../_constants';

import { LogoImgPath } from '@src/view/theLayout/Header/logo/Logo';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export interface LogoVM {
  logoPath: string;
}

const localStorage =
  typeof window !== 'undefined' ? window.localStorage : undefined;

const { persistAtom } = recoilPersist({
  key: AtomStorageKey.LOGO,
  storage: localStorage,
});

export const LogoState = atom<LogoVM>({
  key: AtomKey.LOGO,
  default: {
    logoPath: LogoImgPath.DEFAULT,
  },
  effects_UNSTABLE: [persistAtom],
});
