import Script from 'next/script';

declare global {
  interface Console {
    devlog: (message?: any, ...optionalParams: any[]) => void;
  }
}

const TopcoScript = () => {
  const pLog = console;
  const newCFn = () => {
    const mlist: Console = Object.create(
      Object.getPrototypeOf(console),
      Object.getOwnPropertyDescriptors(console),
    );
    mlist.devlog = (() => {
      if (
        process.env.NODE_ENV === 'development' ||
        (typeof window !== 'undefined' &&
          window?.location.origin.includes('localhost:300'))
      ) {
        return pLog.log;
      } else {
        return () => {};
      }
    })();
    return mlist;
  };
  const newConsole = newCFn();
  console = newConsole;
  return (
    <>
      {/* GTM Script 1 : Head */}
      <Script
        id="GTM"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TBNF2HF');`,
        }}
      />
      {/* GTM Script 1 : Head */}
    </>
  );
};

export default TopcoScript;
