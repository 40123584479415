import { CloseBtn } from '@src/view/common/closeBtn';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import { ModalWrapper } from '@src/lib/CustomModal';
import { UserByVisitorId } from '@src/hooks/useLogin';
import { UserSession } from '@src/lib';
import cn from 'clsx';
import { openLoginModal } from '../login/LoginComponent';
import { useEffect } from 'react';

const key = 'existIdNoticeModal';

export const closeExistIdNoticeModal = () => {
  ModalInstance.getInstance().delete(key);
};

export const showExistIdNoticeModal = (props: UserByVisitorId) => {
  ModalInstance.getInstance().push({
    key,
    shouldCloseOnOverlayClick: false,
    shouldCloseOnOverlayCallback: (e: any) => {
      closeExistIdNoticeModal();
    },
    component: <ExistIdModal {...props} />,
  });
};

const ExistIdModal = (props: UserByVisitorId) => {
  useEffect(() => {
    if (props.method === 'global') {
      UserSession.setLastUserEmail(props.loginId);
    } else {
      UserSession.setLastLoginProvider(props.method.replace('.com', ''));
    }
  }, []);

  const ExistingAccountDom = () => {
    const { isEmail, loginId, method } = props;
    const img_src = method.replace('.com', '');
    if (method === 'global') return <span>{loginId}</span>;
    if (isEmail) {
      return (
        <div className="flex items-center justify-center gap-1">
          <img
            className="w-[22px]"
            src={`/images/sns/ico_${img_src}.png`}
            alt={`${img_src}_icon`}
          />
          <span>{loginId}</span>
        </div>
      );
    }
    return (
      <div className="flex items-center justify-center gap-1">
        <span>Signed in with</span>
        <img
          className="w-[22px]"
          src={`/images/sns/ico_${img_src}.png`}
          alt={`${img_src}_icon`}
        />
      </div>
    );
  };

  return (
    <ModalWrapper.Default addClassName="h-[280px] max-w-[310px] text-center tracking-0.1 dark:bg-[#222] dark:border-[#494949] dark:border-[1px] dark:text-white">
      <div className="relative w-full h-full p-0 mx-auto overflow-hidden">
        <CloseBtn
          imgPath="/images/alert/close/ico_close.png"
          className="h-[35px] w-[35px] right-[4px] top-[4px] absolute"
          onClick={(e: any) => {
            closeExistIdNoticeModal();
          }}
        />
        <div className="p-[23px] flex justify-between flex-col h-full ">
          <div className="flex items-center justify-center">
            <img
              className=" aspect-[1/1] w-[35px]"
              src="/images/existId/light/notice.png"
              alt="existid_notice_img"
            />
            <span className="ml-[6px] font-semibold text-[24px] block">
              Notice
            </span>
          </div>

          {/* <br /> */}
          <div className="text-[13px] leading-[19.5px]">
            <span>
              You have an existing account.
              <br />
              Please sign in with your existing account.
              <br />
            </span>
          </div>
          <div className="border-t-[1px] border-primary-border dark:border-[#494949] text-[14px] leading-[21px] pt-[10px]">
            <span className="text-primary-text-point">Existing account</span>
            <br />
            {ExistingAccountDom()}
          </div>
          <div className="btnLogin mt-[5px]">
            <button
              id="signInButton"
              className={cn(
                `shadow-logInAndJoin bg-primary-button-bg-point max-w-[180px] h-[40px] text-white text-[17px] font-semibold leading-[25.5px] rounded-[50px] w-full mx-auto`,
              )}
              onClick={() => {
                closeExistIdNoticeModal();
                openLoginModal();
              }}
            >
              Sign In
            </button>
          </div>
          <span className="text-primary-text-info text-[10px] leading-[15px]">
            For inquiries, please contact our customer support.
          </span>
        </div>
      </div>
    </ModalWrapper.Default>
  );
};

export default ExistIdModal;
