export enum MOVE_EPI_TYPE {
  EPISODE_CLICK = 0,
  PREV = 1,
  NEXT = 2,
  FREE_PURCHASE = 3, // 일반 소장
  PAID_PURCHASE = 4, // membership 소장
}

export enum MARKETING_MATURE {
  UNSET = 0,
  NON_ADULT = 1,
  ADULT = 2,
}

export enum SIDEMENU_VIEW_TYPE {
  HIDE = 1,
}

export enum OWN_TYPE {
  purchase = 'PURCHASE_EPISODE',
  unlockAll = 'UNLOCK_ALL',
  rent = 'RENT_EPISODE',
  pass = 'PASS',
  ticket = 'TICKET',
  autoPurchase = 'AUTO_PURCHASE',
  waitFreeComic = 'WAIT_FREE_COMIC',
  peekIn = 'PEEK_IN',
}

export enum EPI_TYPE {
  none = 0,
  normal = 1,
  prologue = 2,
  epilogue = 3,
  forAdvertising = 4,
  restNotice = 5,
  notice = 6,
  prologueForAdvertising = 7,
}

export enum HEADER_ICON_TYPE {
  HOME = 'home',
  GIFT = 'gift',
  LIKE = 'like',
  KEEP = 'keep',
  EPI_LIST = 'epilist',
}

/**
 * 유저별 소장 type구분
 */
export enum VIEWER_PURCHASE_TYPE {
  FREE_PURCHASE = 'FREE_PURCHASE', // 일반유저 소장시 purchaseType
  PAID_PURCHASE = 'PAID_PURCHASE', // membership유저 소장시 purchaseType
}

export enum VIEWER_LOCATION_TYPE {
  EPISODE_CLICK = 'viewer_episode_click',
  PREV = 'viewer_prev',
  NEXT = 'viewer_next',
  FREE_PURCHASE = 'viewer_free_purchase', // 일반 소장
  PAID_PURCHASE = 'viewer_paid_purchase', // membership 소장
}
