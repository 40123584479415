import { CloseBtn } from '@src/view/common/closeBtn';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import cn from 'clsx';

const key = 'membershipInfoModal';

export const openMembershipInfoModadl = () => {
  ModalInstance.getInstance().push({
    key,
    shouldCloseOnOverlayClick: true,
    component: <MembershipInfoModal />,
  });
};

export const closeMembershipInfoModal = () => {
  ModalInstance.getInstance().delete(key);
};

//TODO: toptoon
const noticeList = [
  'Toptoon membership and enjoy episodes updated daily on various devices.',
  'Enjoy over 100,000+ episodes with amazing translation quality.',
  " Discover exclusive TOPTOON Originals that you can't find anywhere else.",
];

const MembershipInfoModal = () => {
  return (
    <div className="p-0 my-0 mx-auto bg-white rounded-[2px] text-[1rem] max-w-[339px] max-h-[90vh] w-[94vw] scrollbar-hide">
      <div className="w-full bg-white relative z-[1] rounded-none overflow-hidden break-all pt-[42px] px-[26px] pb-[39px]">
        <CloseBtn
          imgPath="/images/alert/close/ico_close.png"
          className="absolute top-[4px] right-[4px] text-transparent z-[1] cursor-pointer inline-block w-[35px] h-[35px]"
        />
        <div className="flex flex-col items-center">
          <div className="mb-[2.54px]">
            <img
              className="h-[42.46px]"
              src="/images/payment/ico_mothly_autopay.png"
              alt="ico-monthly-autopay"
            />
          </div>

          <div className="mt-[8px] mx-0 mb-[14px]">
            <div className="font-[500] text-[22px] leading-[23px] tracking-[-0.2px] text-[#000000] whitespace-nowrap">
              About Premium Membership
            </div>
          </div>

          <div className="mb-[22px]">
            <p className="text-[13px] leading-[18px] text-center tracking-[-0.2px] text-[#313131]">
              The membership fees will be automatically charged to you by the
              payment method you specify at the time of your initial purchase.
            </p>
          </div>

          <div className="mb-[29px]">
            <ul>
              {noticeList.map((c, index) => {
                return (
                  <li
                    key={`notice-tem-${index}`}
                    className={cn(
                      'text-[#aaa] text-[12px] font-[500] tracking-[-0.2px] pl-[15px] relative',
                      "before:content-['•'] before:absolute before:left-0 before:text-[#ddd] before:font-[500] before:inline-block before:text-[26px]",
                    )}
                  >
                    {c}
                  </li>
                );
              })}
            </ul>
          </div>

          <div>
            <button
              type="button"
              className="py-[13px] px-[70px] bg-[#f02d37] text-[#fff] text-[17px] rounded-[50px] font-[500] tracking-[-0.2px]"
              onClick={(e: any) => {
                closeMembershipInfoModal();
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipInfoModal;
