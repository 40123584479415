import { create } from 'zustand';

export interface StoreType {
  isLike: boolean;
  setIsLike: (value: boolean) => void;
}
const useStore = create<StoreType>(set => ({
  isLike: false,
  setIsLike(value) {
    set(state => ({ ...state, isLike: value }));
  },
}));
export default useStore;
