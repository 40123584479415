import React, { useState } from 'react';

type Props = {
  changeAccount: (id: string, password: string | number) => void;
  account: () => {
    id: string;
    password: string | number;
  };
};

const ChangeAccountCookie = (props: Props) => {
  const [id, setId] = useState(props.account().id);
  const [pw, setPw] = useState(props.account().password);
  return (
    <div className="absolute left-0 top-[-150px] text-[0.9rem] bg-[#333535] rounded-md">
      <input
        className="border
        border-solid border-black text-[#000]"
        type="text"
        value={id}
        placeholder="ID"
        onChange={e => {
          e.stopPropagation();
          setId(e.target.value);
        }}
      />
      <input
        className="border
        border-solid border-black text-[#000]"
        type="text"
        value={pw}
        placeholder="PW"
        onChange={e => {
          e.stopPropagation();
          setPw(e.target.value);
        }}
      />
      <button
        className="w-full align-center"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          props.changeAccount(id, pw);
        }}
      >
        입력
      </button>
    </div>
  );
};

export default ChangeAccountCookie;
