import React from 'react';
import cn from 'clsx';

/**
 *
 * @param children
 * @returns
 * component별 포지션 설정
 * addClassName으로 ModalItem 크기 등 설정
 *
 */

interface PropTypes {
  addClassName?: string;
  children: React.ReactNode;
  id?: string;
}

const ModalWrapper = (children: React.ReactNode) => {
  return { children };
};

const Default = (props: PropTypes) => {
  const { addClassName, children, id } = props;
  return (
    <div
      id={id}
      className={cn(
        'popupInner animation_fadein z-[100] relative shadow-outline bg-white',
        {},
        addClassName,
      )}
    >
      {children}
    </div>
  );
};

const Bottom = (props: PropTypes) => {
  const { addClassName = '', children } = props;

  return (
    <div
      className={cn(
        'min-w-[10px] min-h-[10px] z-[100] bg-white',
        'mo:mt-auto mo:mb-[30px]',
        {},
        addClassName,
      )}
    >
      {children}
    </div>
  );
};

const SnbMenu = (props: PropTypes) => {
  const { addClassName, children, id } = props;
  return (
    <div
      id={id}
      className={cn(
        'min-w-[10px] min-h-[10px] z-[100] animation_slidein fixed right-0 top-0',
        {},
        addClassName,
      )}
    >
      {children}
    </div>
  );
};

ModalWrapper.Default = Default;
ModalWrapper.SnbMenu = SnbMenu;
ModalWrapper.Bottom = Bottom;

export default ModalWrapper;
