import {
  ApiModal,
  ApiUser,
} from '@toptoon-developers/global.toptoonplus.common.lib/dist/apiV2/client/V1';
import {
  GnbMenuItemType,
  GnbMenuState,
  HeaderType,
  SnbMenuState,
  TicketState,
  initSnbMenu,
} from '@src/atom';
import {
  TypeCheck,
  _days,
} from '@toptoon-developers/global.toptoonplus.common.lib';
import { UserDefault, UserSession } from '@src/lib';
import { isPromoHidden, uuidv4 } from '@src/lib/utils/utils';

import Cookies from 'js-cookie';
import { MembershipCode } from '@src/constants';
import { UserSubscribeState } from '@src/atom/user';
import _ from 'lodash';
import { openRefillErrorModal } from '@src/view/modal/payment/RefillErrorModal';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useSetRecoilState } from 'recoil';

const useUserCoin = () => {
  const router = useRouter();
  const setGnbMenuState = useSetRecoilState(GnbMenuState);
  const setSnbMenuState = useSetRecoilState(SnbMenuState);
  const setTicketState = useSetRecoilState(TicketState);
  const setUserSubscribeState = useSetRecoilState(UserSubscribeState);

  // /********************************************************
  //  * login 통계 4시간마다 호출
  //  ********************************************************/
  // useEffect(() => {
  //   const { token } = UserSession.getUserInfo();
  //   if (!token || !UserSession.isLoginCountCheck()) return;

  //   new ApiLogin(UserDefault.getUserSession())
  //     .updateLoginCount(token, UserDefault.env.getUdid())
  //     .then(res => {})
  //     .catch(err => {});
  // }, []);

  /**
   * ANCHOR: gnbMenu 데이터 가공
   */
  const menuUpdate = useCallback(() => {
    const { mature } = UserSession.getUserInfo();
    const isOnlyMature = mature === 1;
    const path = router.pathname;

    let menuList: GnbMenuItemType[] = [];

    menuList.push({
      name: 'Ongoing',
      link: '/ongoing',
      isActive: false,
      mature: 2,
    });

    menuList.push({
      name: 'New',
      link: '/new',
      isActive: false,
      mature: 2,
    });

    menuList.push({
      name: 'Completed',
      link: '/completed',
      isActive: false,
      mature: 2,
    });

    menuList.push({
      name: 'Ranking',
      link: '/ranking',
      isActive: false,
      mature: 2,
    });

    if (!isPromoHidden()) {
      menuList.push({
        name: 'Promo',
        link: '/event',
        isActive: false,
        mature: 2,
      });
    }

    menuList.push({
      name: 'Genres',
      link: '/genres',
      isActive: false,
      mature: 2,
    });

    if (isOnlyMature) {
      menuList.push({
        name: 'Artworks',
        link: '/artworks',
        isActive: false,
        mature: 1,
      });
      menuList.push({
        name: 'Newsfeed',
        link: '/newsfeed',
        isActive: false,
        mature: 1,
      });
    }

    /**
     * ANCHOR: 메뉴별 active조건 때문에 2차 가공 처리하였는데..
     * 굳이 이렇게 안해도 될것같은데 마땅한 방법이 떠오르지 않음
     * 추후 개선 예정
     */
    const list = _.transform(
      menuList,
      (result: GnbMenuItemType[], c) => {
        let isActive = false;
        if (c.mature !== mature && c.mature !== 2) return;

        if (mature === 0 && path === '/') {
          isActive = c.link === '/ongoing';
        } else if (path.includes(c.link) && c.link === '/ranking') {
          isActive = path.includes(c.link);
        } else {
          isActive = path === c.link;
        }

        result.push({
          ...c,
          isActive,
        });
      },
      [],
    );

    setGnbMenuState(list);
  }, [router.pathname]);

  const refreshSnb = useCallback(() => {
    const userInfo = UserSession.getUserInfo();
    const coinInfo = UserSession.getCoinInfo();
    if (userInfo.token) {
      let membershipCode = MembershipCode.NOT_SET;
      let membershipExpireAt = '';
      const { userSubscribe } = coinInfo;
      if (userSubscribe && userSubscribe.code) {
        membershipCode = userSubscribe.code as MembershipCode;
        membershipExpireAt = _days.remainingDateForDHM(
          userSubscribe.expiredAt || '',
        );
      }

      setSnbMenuState(prev => {
        return {
          ...prev,
          menuList: prev.menuList,
          headerType: HeaderType.login,
          userInfo: {
            provider: userInfo.provider,
            userId: userInfo.loginId,
            userCoin: coinInfo.coinInfo.coin,
            userBonusCoin: {
              coin: coinInfo.coinInfo.bonusCoin,
              refreshAt: _days.remainingDateForDHM(coinInfo.refreshAt || ''),
            },
            userMembership: {
              code: membershipCode,
              refreshAt: membershipExpireAt,
            },
          },
        };
      });
    } else {
      setSnbMenuState(initSnbMenu);
    }
  }, [router.pathname]);

  const getUserData = useCallback(() => {
    new ApiUser(UserDefault.getUserSession())
      .getCoinInfo()
      .then((res: any) => {
        const data = res.data.data;

        // 5min get current coin 정보 갱신(코인 정보) => global process와 공유됨 (21.12.09)
        const inTimeoutMin = new Date(new Date().getTime() + 5 * 60 * 1000);
        Cookies.set('coinRefresh', uuidv4(), {
          expires: inTimeoutMin,
        });

        const coinInfo = {
          coin: 0,
          bonusCoin: 0,
        };
        if (!TypeCheck.itemsByPath(res, 'coins')) {
          const conis = data.coins || [];

          coinInfo.coin = _.sum(
            _.map(
              _.filter(conis, v => v.type < 6),
              'coin',
            ),
          );
          coinInfo.bonusCoin = _.sum(
            _.map(
              _.filter(conis, v => v.type >= 6),
              'coin',
            ),
          );
        }

        const subscribe =
          TypeCheck.itemsByPath(data, 'userMembership.code') === null // paymentCount를 이용한 Membership 조건 확인용
            ? {
                code: MembershipCode.NOT_SET,
                expiredAt: '0',
                isMembershipCancel: false,
              }
            : TypeCheck.itemsByPath(data, 'userMembership');

        // membership 구독상태값(isMembershipCancel) 추가
        const convertIsMembershipSub = (data: any) => {
          if (!data || data.lenth === 0) return true;
          const findMembershipItem = _.find(data, { isMemberShip: true });
          if (!findMembershipItem) return true;
          const { order } = findMembershipItem;
          if (order) return false;
        };
        const IsRefillUser = TypeCheck.itemsByPath(data, 'isRefillUser');
        UserSession.setCoinInfo({
          coinInfo: coinInfo,
          refreshAt: TypeCheck.itemsByPath(data, 'refreshAt'),
          goods: TypeCheck.itemsByPath(data, 'goods'),
          paymentCount: TypeCheck.itemsByPath(data, 'paymentCount'),
          userSubscribe: {
            ...subscribe,
            isMembershipCancel: convertIsMembershipSub(data.recurring),
          },
          waitFreeComic: TypeCheck.itemsByPath(data, 'waitFreeComic'),
          loginCount: TypeCheck.itemsByPath(data, 'loginCount'),
          isRefillUser: IsRefillUser,
        });

        if (IsRefillUser && !!IsRefillUser.error) {
          openRefillErrorModal({ orderId: IsRefillUser.orderId });
          new ApiModal(UserDefault.getApiVaildateHeaders()).modalCheck({
            code: IsRefillUser.modalCodes.error,
            action: 'viewed',
          });
        }

        setUserSubscribeState(subscribe);
        setTicketState({
          freeTicketIds: UserSession.getFreeTicketIds(data.goods),
          freeTicketCount: UserSession.getFreeTicketCount(data.goods),
        });

        refreshSnb();
      })
      .catch((err: any) => {
        console.error(err);
      });
  }, [router.pathname]);
  return {
    menuUpdate,
    getUserData,
    refreshSnb,
  };
};

export default useUserCoin;
