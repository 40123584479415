import { AtomKey, AtomStorageKey } from '../_constants';

import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

interface FetchMatureType {
  isFetchMature: boolean;
}

const localStorage =
  typeof window !== 'undefined' ? window.localStorage : undefined;

const { persistAtom } = recoilPersist({
  key: AtomStorageKey.FETCH_MATURE,
  storage: localStorage,
});

export const fetchMature = atom<FetchMatureType>({
  key: AtomKey.FETCH_MATURE,
  default: { isFetchMature: false },
  effects_UNSTABLE: [persistAtom],
});
