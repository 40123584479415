import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ErrorMessage, Field, FormikErrors, FormikTouched } from 'formik';

import AppManager from '@src/lib/app/AppManager';
import { Event } from '@toptoon-developers/global.toptoonplus.common.lib/dist/gtm/Gtm';
import { GlobalGtm } from '@toptoon-developers/global.toptoonplus.common.lib/dist/gtm/global';
import { TopcoPartner } from '@src/lib/partner';
import { UserSession } from '@src/lib';
import _ from 'lodash';
import cn from 'clsx';
import { VisitorUtils } from '@src/lib/utils/VisitorUtils';

interface PropsTypes {
  values: {
    email: string;
    domain: string;
  };
  errors: FormikErrors<{
    email: string;
    domain: string;
  }>;

  touched: FormikTouched<{
    email: string;
    domain: string;
  }>;

  type: string; // 'join' || 'login' (gmail.com 처리 구분을 위함)
  handleChange: (e: ChangeEvent<any>) => void;
}

const domainList = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'icloud.com',
  'outlook.com',
  'others',
];

const init = {
  email: '',
  domain: '',
  password: '',
  policyCheckbox: true,
  adCheck: false,
};

const DomainForm = (props: PropsTypes) => {
  const { values = init, errors, touched, handleChange, type } = props;

  const [isInputField, setIsInputField] = useState(false); // 도메인으로 others(직접선택) 클릭했는지
  const [isInitValue, setIsInitValue] = useState(true); // 도메인 선택하기 전인지
  const [isOpen, setIsOpen] = useState(false); // SelectBox 열려있는지

  const domainRef = useRef<any>();
  const googleLoginRef = useRef(null);

  const clickDomainItem = async (e: any) => {
    const domain = e.target.innerText;

    // App 이고, 가입 창일 때 => 구글 로그인 창 뜨도록
    if (
      AppManager.getInstance().isApp() &&
      type === 'join' &&
      domain === 'gmail.com'
    ) {
      const visitorId = await VisitorUtils.fetchVisitorId();
      AppManager.getInstance().currentPage.setter();
      AppManager.getInstance().action.snsLogin(
        'google',
        `visitorId=${visitorId}&preAuthToken=${UserSession.getPreAuthToken()}&isAlreadyMature=${0}`,
      );
      return;
    }

    // App 아닐 때 + App 인데 로그인 창일 때
    setIsInitValue(false);

    if (domain === 'others') {
      setIsInputField(true);
      values.domain = ''; // 빈 값으로 초기화
    } else {
      values.domain = domain;
    }
  };

  // Select Box 영역 외 클릭 시 닫히도록
  const onClick = (e: any) => {
    if (isOpen && !domainRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', onClick);
    }
    return () => {
      document.removeEventListener('mousedown', onClick);
    };
  });

  useEffect(() => {
    // App 이 아닐 때만 동작
    if (typeof window !== undefined && !AppManager.getInstance().isApp()) {
      const { google }: any = window;
      if (!google) return;

      google.accounts.id.renderButton(googleLoginRef.current, {
        type: 'standard',
        theme: 'outline',
        size: 'small',
        shape: 'rectangular',
        text: 'signin',
        width: 125,
        locale: 'en_US',
        click_listener: () => {
          // GTM - 가입 버튼 클릭
          try {
            GlobalGtm.getInstance().setter({
              event: Event.JOIN_BUTTON_CLICK,
              pCode: TopcoPartner.getter(),
            });
          } catch {
            console.error('gtm');
          }
        },
      });
    }
  }, [isOpen]);

  return (
    <div className={`emailArea justify-between`}>
      {/* ID */}
      <Field
        className={`emailInput border border-solid border-[#eee] bg-white py-[9px] px-[12px] rounded-[5px] text-[#333] h-[40px] text-[13px] w-[50%] focus:border-primaryRed focus:ring-0 placeholder:text-[11px]`}
        name="email"
        type="text"
        value={values.email}
        onChange={(e: any) => {
          // '@' 입력 시 도메인 select box focus 되도록
          const value = e.target.value;
          if (value.includes('@')) {
            domainRef.current.focus();
            setIsOpen(true);
          } else handleChange(e);
        }}
        placeholder="Enter your email"
        autoFocus
      />
      <div className="w-[7%] text-[13px] text-black inline-block text-center">
        {`@`}
      </div>

      {/* DOMAIN */}
      {isInputField ? (
        // others 눌렀을 때 나오는 input 창
        <Field
          className={`w-[43%] border border-solid border-[#eee] bg-white py-[10px] px-[12px] rounded-[5px] text-[#333] h-[40px] text-[13px] focus:border-primaryRed focus:ring-0 placeholder:text-[11px]`}
          type="text"
          name="domain"
          value={values.domain}
          onChange={handleChange}
          autoFocus
        />
      ) : (
        // Select Box
        <button
          ref={domainRef}
          type="button"
          onClick={() => {
            setIsOpen(prev => !prev);
          }}
          className={cn(
            `domain inline-block w-[43%] text-[11px] text-[#6c7381] leading-[20px] border border-solid border-[#eee] bg-white py-[9px] rounded-[5px] h-[40px] focus:border-[#333] focus:ring-0`,
            {
              'text-[13px] text-[#333]': !isInitValue,
            },
          )}
        >
          <div className="flex justify-between items-center px-[12px]">
            <div className="truncate">
              {values.domain !== '' ? values.domain : 'Choose your domain'}
            </div>
            <div>
              <img src="/images/common/arrow_down_black.png" alt="arrow" />
            </div>
          </div>

          <div
            className={cn(
              'absolute w-[127px] text-left py-[4px] z-[10] bg-white mt-[10px] border border-[#333] border-solid rounded-[5px]',
              {
                block: isOpen,
                hidden: !isOpen,
              },
            )}
          >
            {_.map(domainList, (item, index) => {
              // gmail.com => select box 돔 위에 구글에서 제공하는 버튼을 투명하게 붙임 (가입만)
              if (
                item === 'gmail.com' &&
                type === 'join' &&
                !AppManager.getInstance().isApp()
              ) {
                return (
                  <div key={index} className="group">
                    {/* 보여지는 돔 */}
                    <div
                      className={
                        'z-[9] py-[4px] px-[19px] group-hover:bg-[#EFEFEF]'
                      }
                    >
                      {item}
                    </div>
                    {/* 구글에서 제공하는 버튼 돔 */}
                    <div
                      id="google_btn"
                      ref={googleLoginRef}
                      className="overflow-hidden opacity-[0.001] absolute top-[10px] w-full z-[10]" // opacity-[0.001] => 0으로 주면 hover가 안먹혀서 설정
                    />
                  </div>
                );
              }

              return (
                <div key={index} className="group">
                  <div
                    className={
                      'z-[9] py-[4px] px-[19px] group-hover:bg-[#EFEFEF]'
                    }
                    onClick={clickDomainItem}
                  >
                    {item}
                  </div>
                </div>
              );
            })}
          </div>
        </button>
      )}

      {/* 에러 문구 출력 */}
      {errors.email && touched.email && (
        <ErrorMessage
          component="p"
          name="email"
          className={cn(
            `erroMsg warning_msg text-primaryRed text-[12px] text-left mt-[4px] mb-[2px] leading-[17px]`,
          )}
        />
      )}
      {errors.domain && touched.domain && (
        <ErrorMessage
          component="p"
          name="domain"
          className={cn(
            `erroMsg warning_msg text-primaryRed text-[12px] text-left mt-[4px] mb-[2px] leading-[17px]`,
          )}
        />
      )}
    </div>
  );
};
export default DomainForm;
