export enum CoinRow {
  Coin = 'Coins',
  Bcoin = 'Bonus Coins',
  FawCount = 'FAW Ticket',
  MemberShip = 'MemberShip',
  MemberShipNot = 'MemberShipNot',
}

export interface CoinRowType {
  type: CoinRow;
  value: string;
  subText: string;
}
