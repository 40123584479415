import {
  EPI_TYPE,
  MARKETING_MATURE,
  OWN_TYPE,
  VIEWER_LOCATION_TYPE,
  VIEWER_PURCHASE_TYPE,
} from '.';
import {
  EpisodeType,
  InduceLayerType,
  ViewerContext,
  WeeklyBannerType,
} from '../context/ViewerContext';
import {
  ThumbnailMode,
  ThumbnailType,
  convertBanner,
  convertEpiVerticalThumbnail,
  convertUserBanner,
} from '@src/middleware/model';
import { UserDefault, UserSession } from '@src/lib/user';
import { ApiEpisode } from '@toptoon-developers/global.toptoonplus.common.lib/dist/apiV2/client/V1';
import { BaseModel } from '@src/middleware/model/BaseModel';
import { UnlockAllType } from '../../episode/context';
import _ from 'lodash';
import { isOpenCheck } from '@src/lib/utils/timeUtils';

export namespace TopcoViewerRequestApi {
  export const getViewerData = (
    comicId: number,
    episodeId: number,
    marketingMature: MARKETING_MATURE,
  ) => {
    const content = UserDefault.viewerContent.getter();
    if (content) {
      const currentEpisodeIdx = content.episode.findIndex((c: any) => {
        return c.episodeId === episodeId;
      });
      if (currentEpisodeIdx >= 0) {
        return TopcoViewerRequestApi.ViewerData(
          comicId,
          episodeId,
          marketingMature,
          content,
        );
      }
    }
    return TopcoViewerRequestApi.ViewerData(
      comicId,
      episodeId,
      marketingMature,
    );
  };

  export const ViewerData = async (
    comicId: number,
    currentEpisodeId: number,
    marketingMature: MARKETING_MATURE,
    contentData?: any,
  ): Promise<ViewerContext> => {
    try {
      const { token } = UserSession.getUserInfo();

      let content: any = null;
      let etc: any = null;

      if (contentData) {
        content = contentData;
      }

      await new ApiEpisode(UserDefault.getApiVaildateHeaders())
        .apiCheckViewer(comicId, currentEpisodeId, {
          location: 'viewer',
          action: 'view_contents',
          isCached: content !== null,
        })
        .then((res: any) => {
          const data = res.data.data;
          const { comic, episode, ownType, unlockAllInfo, ...etcData } = data;

          if (!content) {
            content = { comic, episode, ownType };
          }

          etc = etcData;
        })
        .catch(err => {
          console.error(err);
          throw new Error('viewer error');
        });
      const currentEpisodeIdx = content.episode.findIndex((c: any) => {
        return c.episodeId === currentEpisodeId;
      });

      // comic, current episode 예외처리
      if (!content.comic || currentEpisodeIdx < 0)
        throw new Error('viewer error');

      return new TopcoViewer(
        comicId,
        currentEpisodeId,
        content,
        etc,
        marketingMature,
      ).getContext();
    } catch (e) {
      console.error(e);
      throw new Error('viewer error');
    }
  };
}

export class TopcoViewer extends BaseModel<ViewerContext> {
  constructor(
    currentComicId: number,
    currentEpisodeId: number,
    content: any,
    etc: any,
    marketingMature: MARKETING_MATURE,
  ) {
    super();

    const { token } = this.userSession;

    const { episodeList, currentEpisode, prevEpisode, nextEpisode } =
      this.convertEpisode(content.episode, currentEpisodeId, marketingMature);

    const { isContract, comicTitle, thumbnailImage, comicId } =
      this.convertComic(content.comic);

    const unlockAll = this.convertUnlockAll(
      content.unlockAllInfo,
      comicTitle,
      thumbnailImage,
      comicId,
    );

    const { isInduce, contentImage, bottomBackgroundColor, useCopyright } =
      currentEpisode;

    // episode..구매 조건?(참고 : OWN_TYPE)
    const ownType = content.ownType;

    /**
     * 소장 api 호출 type 정의 payType 기준으로 보내는 값 변경
     */
    const purchaseBtnType = {
      purchaseType:
        currentEpisode.payType === 0
          ? VIEWER_PURCHASE_TYPE.FREE_PURCHASE
          : VIEWER_PURCHASE_TYPE.PAID_PURCHASE,
      locationType:
        currentEpisode.payType === 0
          ? VIEWER_LOCATION_TYPE.FREE_PURCHASE
          : VIEWER_LOCATION_TYPE.PAID_PURCHASE,
    };

    // contentBottom 비노출 조건
    const isContentBottomHidden = !token && isInduce;

    this.setContext({
      comicId,
      currentEpisode,
      prevEpisode,
      nextEpisode,
      token,
      episodeList,
      purchaseBtnType,
      userBanner: _.has(etc, 'userBanner')
        ? convertUserBanner(etc.userBanner)
        : null,
      isKeepBtnVisible: this.isKeepBtnVisible({
        token,
        marketingMature,
        item: currentEpisode,
        ownType,
      }),
      contentImage: contentImage,
      isWelcomLayerVisible: !token && isInduce,
      induceLayer: this.convertInduceLayer(etc.induceLayer),
      contentBottom: isContentBottomHidden
        ? null
        : {
            bottomBackgroundColor: bottomBackgroundColor,
            useCopyright: useCopyright,
            weeklyBanners: this.convertWeeklyBanner(etc.weeklyBanners),
            populars: this.getComicItems(etc.popularComics),
            nextEpisodePublishedAt: _.has(etc.nextEpisodePublishedAt, 'date')
              ? etc.nextEpisodePublishedAt.date
              : '',
            nextEpisode,
          },
      unlockAll,
      authEventCode: _.has(etc, 'authEventCode') ? etc.authEventCode : '',
      IAA: _.has(etc, 'IAA') ? etc.IAA : false,
      isBingeWatching: _.has(etc, 'isBingeWatching')
        ? etc.isBingeWatching === 1
        : false,
      isEpisodeLike: etc.participationEpisodeLike ?? false,
      marketingMature: MARKETING_MATURE.UNSET,
      isContract,
      logMembershipAdjustId: etc.logMembershipAdjustId ?? 0,
    });
  }

  /**
   * local타임 기준 episode list
   * @returns episodeList, currentEpisode, prevEpisode, nextEpisode
   */
  private convertEpisode(
    data: any,
    currentEpisodeId: number,
    marketingMature: number,
  ) {
    let prevEpisode: EpisodeType | null = null;
    let nextEpisode: EpisodeType | null = null;
    let idx = 0;
    let currentIdx = -1;

    const episodeList: EpisodeType[] = _.transform(
      data,
      (result: EpisodeType[], c, index: number) => {
        const { episodeId, information } = c;

        if (!isOpenCheck(information, 'publishedAt', 'closedAt')) return;

        const item: EpisodeType = {
          episodeId,
          title: information.title ?? '',
          subTitle: information.subTitle ?? '',
          thumbnail: convertEpiVerticalThumbnail(c, marketingMature),
          isCurrent: episodeId === currentEpisodeId,
          payType: information.payType ?? 0,
          isInduce: c.isInduce ?? false,
          type: information.type ?? EPI_TYPE.none,
          possessionCoin: information.possessionCoin ?? 0,
          isPurchased: c.isPurchased === 1,
          contentImage: c.contentImage.webp ?? [],
          bottomBackgroundColor: information.copyrightType ?? '#FFFFFF',
          useCopyright: information.useCopyright === 1,
        };

        if (episodeId === currentEpisodeId) {
          currentIdx = idx;
        }

        result.push(item);
        idx += 1;
      },
      [],
    );

    const currentEpisode = episodeList[currentIdx];

    if (currentIdx > 0) {
      prevEpisode = episodeList[currentIdx - 1];
    }

    if (currentIdx >= 0 && currentIdx < episodeList.length - 1) {
      nextEpisode = episodeList[currentIdx + 1];
    }

    return { episodeList, currentEpisode, prevEpisode, nextEpisode };
  }

  /**
   * comic data (대부분 unlockall에 필요)
   * @returns comicId, isContract, comicTitle, thumbnailImage
   */
  private convertComic(data: any) {
    const { information } = data;

    return {
      comicId: data.comicId,
      isContract: information.isContract ?? 1,
      comicTitle: information.title ?? '',
      thumbnailImage: this.convertComicThumbnail(
        data,
        'squareThumbnail',
        ThumbnailMode.NORMAL,
      ),
    };
  }

  /**
   * induceLayer
   * @returns comicId, position, title, scrollRate
   */
  private convertInduceLayer(data: any): InduceLayerType | null {
    if (!data) return null;

    return {
      comicId: data.comicId,
      position: data.position,
      title: data.title,
      scrollRate: data.scrollRate,
    };
  }

  /**
   * unlockall
   */
  private convertUnlockAll = (
    unlockAllInfo: any,
    title: string,
    thumbnail: ThumbnailType,
    comicId: number,
  ): UnlockAllType | null => {
    if (!unlockAllInfo) return null;

    const isEnable = unlockAllInfo.isEnable;
    if (!isEnable) return null;

    const parserUnlockAllInfoChild = (data: any, key: string) => {
      if (_.has(data, key)) {
        let title = '';
        if (_.has(data[key], 'title')) {
          const titleObject = data[key].title;
          title = titleObject.first ?? '';
          const last = titleObject.last ?? '';
          if (last !== '') {
            title += ` ~ ${last}`;
          }
        }

        const episodes = data[key].episodes ?? [];

        return {
          sum: data[key].sum ?? 0,
          disCounted: data[key].discounted ?? 0,
          disCountRate: data[key].discountRate ?? 0,
          total: episodes.length,
          title,
        };
      }
      return null;
    };

    const all = _.has(unlockAllInfo, 'lists')
      ? parserUnlockAllInfoChild(unlockAllInfo.lists, 'all')
      : null;
    const coin = _.has(unlockAllInfo, 'lists')
      ? parserUnlockAllInfoChild(unlockAllInfo.lists, 'coin')
      : null;

    if (all === null || coin === null) return null;

    return {
      all,
      coin,
      isFreeUsed: all.total !== coin.total,
      comicId,
      title,
      thumbnail,
    };
  };

  /**
   * WeeklyBanner
   */
  private convertWeeklyBanner(data: any) {
    if (!data) return [];

    return _.transform(
      data,
      (result: WeeklyBannerType[], c) => {
        const banner = convertBanner(c);
        if (!banner) return;

        result.push({
          id: c.id,
          comicId: banner.comicId,
          target: banner.target,
          link: banner.link,
          eventThemeId: banner.eventThemeId,
          image: banner.images.mobile,
        });
      },
      [],
    );
  }

  /**
   * 소장아이콘 노출조건
   * @param param0
   * @returns
   */
  private isKeepBtnVisible({
    token,
    marketingMature,
    item,
    ownType,
  }: {
    token: string;
    marketingMature: MARKETING_MATURE;
    item: EpisodeType;
    ownType: any;
  }): boolean {
    // 마케팅 페이지(/comic)일 경우 강제로 숨김
    if (
      marketingMature === MARKETING_MATURE.NON_ADULT ||
      marketingMature === MARKETING_MATURE.ADULT
    )
      return false;

    if (!token) return true;

    const { isPurchased, possessionCoin } = item;
    return (
      token.length > 0 &&
      isPurchased === false &&
      possessionCoin > 0 &&
      ownType !== OWN_TYPE.peekIn
    );
  }
}
