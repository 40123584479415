import { useEffect, useState } from 'react';

import cn from 'clsx';
import { membershipUnit } from '@src/lib/utils/utils';
import { BonusCoinType } from '@src/view/components/payment/context';
import { PaymentWindowModeType } from '@src/view/components/payment/data/_constants';

interface ProductCoinPropTypes {
  coin: number;
  bonusCoin: BonusCoinType[];
  windowMode: PaymentWindowModeType;
  isSelect: boolean | undefined;
  payType?: string; // 결제수단 paypal에서 문구 제거용 : 모바일에서 너무 비좁아서 기획쪽 요청
}

const CoinBonus = (props: ProductCoinPropTypes) => {
  const { coin, bonusCoin, windowMode, isSelect, payType } = props;

  return (
    <div
      className={cn(
        'border-primary-border dark:border-x-[#3f3f3f] font-medium flex justify-between items-center ',
        'border-x-none',
      )}
    >
      <div className="flex items-center">
        <div className="flex items-center justify-center">
          <img
            className={cn({
              'w-[18px] mo:w-[15px] inline-block mr-[6px] mo:mr-[4px]':
                windowMode === PaymentWindowModeType.LARGE,
              'mr-[4px] w-[15px]': windowMode === PaymentWindowModeType.MINI,
            })}
            src="/images/payment/icon_coin.png"
            alt="coin"
          />
        </div>
        <div
          className={cn({
            'text-[0.9rem] leading-[20px] mo:text-[0.825rem] mo:leading-[17px]':
              windowMode === PaymentWindowModeType.LARGE,
            'text-[0.825rem] leading-[18px] forPaymentItemMINI:text-left':
              windowMode === PaymentWindowModeType.MINI,
          })}
        >
          <span>{coin} </span>
          {bonusCoin.map((bCoin, index) => {
            return (
              <span
                key={`bCoin-item-${index}`}
                className={cn({
                  ['text-[#64B5FF]']: index === 0,
                  ['text-[#FF323A]']: index === 1,
                })}
              >
                {` + ${bCoin.coin}`}
              </span>
            );
          })}
        </div>
      </div>
      {/* <div
        className={cn(
          ' text-[#fff] flex items-center justify-center leading-[24px] text-center rounded-[3px]',
          'mo:min-w-[40px] mo:min-h-[25px] mo:text-[11px]',
          {
            'bg-primary-button-bg-point dark:bg-[#FF323A]': isSelect,
            'bg-primary-button-bg-disabled dark:bg-[#666]': !isSelect,
            'min-w-[66px] min-h-[30px] text-[16px]':
              windowMode === PaymentWindowModeType.LARGE,
            'min-w-[40px] min-h-[25px] text-[11px]':
              windowMode === PaymentWindowModeType.MINI,
            ['hidden']: payType === 'paypal',
          },
        )}
      >
        Buy
      </div> */}
    </div>
  );
};

export default CoinBonus;
