import PayMethodItem from './PayMethodItem';
import { PaymentWindowModeType } from '../../data/_constants';
import { PgType } from '../../context';
import cn from 'clsx';

interface PropTypes {
  groupItem: PgType.GroupItemType;
  windowMode: PaymentWindowModeType;
  onClick: (e: any, pg: PgType.MethodItemType) => void;
}
/**
 * pgGrouop easy, cart, other
 * @param props
 * @returns
 */
const PgItem = (props: PropTypes) => {
  const { groupItem, windowMode, onClick } = props;
  const { title, pgList } = groupItem;

  return (
    <div
      className={cn('pgGroupCon flex-col flex ', {
        ['gap-y-[13px] mo:gap-y-[8px]']:
          windowMode === PaymentWindowModeType.LARGE,
        ['gap-y-[8px]']: windowMode === PaymentWindowModeType.MINI,
      })}
    >
      <h1
        className={cn('pgTit font-medium', {
          ['text-[22px] mo:text-[16px]']:
            windowMode === PaymentWindowModeType.LARGE,
          ['text-[16px]']: windowMode === PaymentWindowModeType.MINI,
        })}
      >
        {title}
      </h1>
      <div
        id="pgList"
        className={cn('grid grid-rows-[1fr]', {
          'gap-[10px] mo:gap-[5px] grid-cols-3 mo:grid-cols-2':
            windowMode === PaymentWindowModeType.LARGE,
          'flex-wrap gap-[5px] mt-[10px] grid-cols-2':
            windowMode === PaymentWindowModeType.MINI,
        })}
      >
        {pgList.map((c, index) => {
          return (
            <PayMethodItem.NormalPay
              key={`${c.pg}-${c.method || ''}-${c.order}`}
              pgItem={c}
              windowMode={windowMode}
              onClick={e => {
                onClick(e, c);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
export default PgItem;
