import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import cn from 'clsx';
/**
 * 모든 영역 닫기 버튼 재사용
 * height // 이미지 높이값 동적 적용
 * onClose 함수 적용 필요
 */

interface CloseBtnProps {
  imgPath?: string;
  className?: string;
  onClick?: (e: any) => void;
}
const CloseBtn: React.FC<CloseBtnProps> = (props: CloseBtnProps) => {
  const { imgPath, className, onClick } = props;

  return (
    <button
      className={cn('closeBtn', className)}
      onClick={e => {
        if (onClick) onClick(e);
        else ModalInstance.getInstance().reset();
      }}
    >
      <img
        className="ico-img"
        src={imgPath || '/images/alert/close/icon_delete.png'}
        alt="icon_close"
        title="icon_close"
      />
    </button>
  );
};
export default CloseBtn;
