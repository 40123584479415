import { Callback } from './ConfirmModal';
import { CloseBtn } from '@src/view/common/closeBtn';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import { ModalWrapper } from '@src/lib/CustomModal';
import { ProductItemType } from '@src/view/components/payment/context';
import cn from 'clsx';
import { currencyAndPriceToCurrencySymbol } from '@src/lib/utils/utils';
import { useState } from 'react';
import { UserSession } from '@src/lib';

interface PropsType {
  callback: Callback;
  selectItem: ProductItemType;
}
/**
 * 일반 상품 구매자 대상으로 한 자동충전 상품 유도 레이어
 * 대상자: 결제 일반, 결제 복귀자
 * 레이어 닫을 시 24시간 만료 쿠키 설정
 *
 */
const key = 'autoRefillModal';
export const openAutoRefillModal = (props: PropsType) => {
  ModalInstance.getInstance().push({
    key,
    // shouldCloseOnOverlayClick: false,
    shouldCloseOnOverlayCallback(e) {
      props.callback.onClose(e);
    },
    component: <AutoRefillModal {...props} />,
  });
};

export const closeAutoRefillModal = () => {
  ModalInstance.getInstance().delete(key);
};

const AutoRefillModal = (props: PropsType) => {
  const { callback, selectItem } = props;
  const { linkedProduct, coin, bonusCoin } = selectItem;
  const [isCheck, setIsCheck] = useState<boolean>(true);
  if (!linkedProduct) return null;
  const { price, modalCoin, currencyCode } = linkedProduct;

  const borderStyle = (className: string = '') => {
    return cn(
      'bg-white border-[2px] text-white dark:text-[#041414] border-solide border-[#041414] rounded-[10px] relative',
      className,
    );
  };
  return (
    <ModalWrapper.Default
      id="autoRefillModalCon"
      addClassName="bg-white w-[88vw] h-[90vh] max-h-[645px] mo:max-h-[477] max-w-[450px] relative bg-autoRefillBgImg bg-[length:cover] font-[Roboto]"
    >
      <CloseBtn
        imgPath="/images/alert/close/icon_close_black.png"
        className="w-[30px] mo:w-[22px] absolute right-[0px] top-[0px]"
        onClick={callback.onClose}
      />

      <div className="contentCon p-[2rem_20px] flex flex-col gap-y-[16px] mo:px-[14px]">
        <div
          className={cn(
            'tit text-[21px] text-[#041414] text-center text-shadow-autoRefillShadow font-extrabold',
            'mo:text-[2.9vw] forFawDesc:text-[4vw] forFawDesc:leading-[1rem]',
          )}
        >
          Enhance your reading experience with <br />
          {`Daycomics' most affordable and
          convenient auto-reload service!`}
        </div>

        <div className={`refillInfo ${borderStyle()}`}>
          <ul className="flex flex-col items-center py-[15px] max-w-[75%] mx-auto mo:gap-y-[5px] underMo:max-w-[80%]">
            {autoRefillInfo.map((c, index) => {
              return (
                <li
                  key={`${c}-${index}`}
                  className={cn(
                    ' w-full flex text-primary-text items-center text-[17px] font-medium gap-x-[5px] -tracking-[.2px] break-keep leading-normal',
                    'mo:gap-x-[3.5px] mo:text-[.82rem]',
                    'underMo:max-w-[80%]',
                  )}
                >
                  <span
                    className={cn(
                      'inline-flex bg-[#1DB6B6] shrink-0 w-[15px] h-[15px] font-bold rounded-full text-[.6rem] items-center justify-center text-white',
                      'forPaymentItem:mt-[1%] forPaymentItem:mb-auto',
                    )}
                  >
                    {index + 1}
                  </span>{' '}
                  <div>{c}</div>
                </li>
              );
            })}
          </ul>
          <div
            className={cn(
              'bubble w-[77px] absolute -top-[14%] -right-[4%]',
              'forAutoRefillModal:w-[11vw] forAutoRefillModal:-top-[2vw] forAutoRefillModal:right-[5%]',
            )}
          >
            <img src="/images/alert/autoRefill/img_bubble.png" alt="" />
          </div>
          <div
            className={cn(
              'shiningCoin w-[70px] absolute -left-[4%] -bottom-[15px]',
              'mo:w-[48px]',
            )}
          >
            <img
              src="/images/alert/autoRefill/img_shining_coin_01.png"
              alt=""
            />
          </div>
        </div>

        <div
          className={`refillProduct ${borderStyle(
            'forAutoRefillModal:mt-[2%]',
          )}`}
        >
          <div
            className={cn(
              'inner p-[30px_20px_26px] flex flex-col items-center justify-center font-semibold whitespace-nowrap text-[18px] gap-y-[5px] flex-wrap',
              'forAutoRefillModal:text-[.7rem] forAutoRefillModal:p-[5vw_2vw_2.5vw]',
            )}
          >
            <div className="flex selectCon text-primary-text flex-wrap text-[21px] font-semibold items-center justify-center gap-x-[5px]">
              <div className="priceCon">
                {currencyAndPriceToCurrencySymbol(
                  'ko-KR',
                  currencyCode,
                  Number(price),
                  false,
                )}
              </div>
              <div className="text-[.8rem]">➡︎</div>
              {coin} coins&nbsp;
              {bonusCoin.map((c, index) => {
                return (
                  <div
                    key={`${c.label}-${index}`}
                    className={cn('forAutoRefillModal:text-[.7rem] ')}
                  >
                    + {c.coin} coins
                  </div>
                );
              })}
            </div>

            <div className="flex modalCoinCon text-[#E5A51B] font-semibold">
              <span className="text-[20px]">+ ADD&nbsp;</span>{' '}
              <span className="text-[23px]">{modalCoin}coins</span>
            </div>
          </div>

          <div
            className={cn(
              'titBadge absolute -top-[10px] left-[50%] -translate-x-[50%] shadow-[0px_1px_0px_2px_#041414;] bg-[#1DB6B6] font-semibold rounded-[50px] w-full max-w-[160px] h-[20px] text-white text-[14px] flex items-center justify-center',
              'mo:max-w-[118px]',
              'forAutoRefillModal:text-[.8rem] ',
            )}
          >
            You have chosen
          </div>

          <div
            className={cn(
              'shiningCoin w-[60px] absolute -right-[5%] -bottom-[15px]',
              'mo:w-[44px]',
            )}
          >
            <img
              src="/images/alert/autoRefill/img_shining_coin_02.png"
              alt=""
            />
          </div>
          {/* TODO:자동구독 정책 정해지면 추가예정 23/10/11 */}
          {/* <div className="mt-[15px] flex gap-x-[7px] justify-center items-start">
            <input
              id="autoPaidSubs"
              type="checkbox"
              className={cn(
                'inline-block bg-primary-bg border-primary-border w-[16px] h-[16px] rounded-[3px] cursor-pointer border-solid ',
                'checked:text-primary-text-point',
              )}
              checked={isCheck}
              onChange={({ target: { checked } }) => {
                setIsCheck(checked);
              }}
            />
            <label
              htmlFor="autoPaidSubs"
              className="text-[#041414] text-[11px] leading-[18px]"
            >
              {`I allow PAID-SUBS service to automatically charge bill in a paired sync with AUTO-RELOAD feature.`}
            </label>
          </div> */}
        </div>

        <button
          type="button"
          onClick={callback.onConfirm}
          className={cn(
            'reloadBtn gap-x-[3px] text-white rounded-[50px] bg-[#041414] shadow-[1px_2px_6px_0px_#80A78E] h-[44px] flex items-center justify-center',
            'forAutoRefillModal:text-[15px]',
          )}
        >
          Reload
          <img
            src="/images/alert/autoRefill/icon_arrow.png"
            alt=""
            className="w-[9px] forAutoRefillModal:w-[7px]"
          />
        </button>

        {/* noticeArea */}
        <ul
          className={cn(
            'noticeCon text-[#041414] pl-[1rem] text-[13px] font-[Poppins] leading-[19px] mt-[20px]',
            'forAutoRefillModal:text-[10px] forAutoRefillModal:leading-[14px] forAutoRefillModal:mt-[10px]',
          )}
        >
          {noticeArr.map((c, index) => {
            return (
              <li className="list-disc list-outside " key={`${c}-${index}`}>
                {c}
              </li>
            );
          })}
        </ul>
        {/* noticeArea */}
      </div>
    </ModalWrapper.Default>
  );
};

/**
 * 상품 설명
 */
const autoRefillInfo: string[] = [
  'Reloads by itself on below 10 coins.',
  'Extra bonus coins on every reload.',
  'Cancel it anytime you want.',
];

/**
 * 유의사항
 */
const noticeArr: string[] = [
  'The price of the following product already includes VAT.',
  'When the remaining coins drop below 10, it will automatically reload from your registered payment.',
  'Coins used after the reload are not eligible for a refund.',
  'You can temporarily pause your reload service from My account -> My Profile.',
  'The following service may be changed or terminated without any prior notice.',
];
