import { PaymentContext, PgType } from '../context';

import { Notice } from './notice';
import PaymentContent from './payment/PaymentContent';
import { PaymentWindowModeType } from '../data/_constants';
import { ProductContent } from './product';
import _ from 'lodash';
import cn from 'clsx';

const PaymentMain = (context: PaymentContext) => {
  const {
    windowMode,
    productList,
    topcoPayment,
    selectProductItem,
    pgGroups,
    isStoreApp = false,
    token,
    loginInfo,
    selectLastPaymentMethod,
    setSelectItem,
    purchaseAction,
    userBanner,
  } = context;
  const mode = windowMode ?? PaymentWindowModeType.LARGE;

  return (
    <div className="max-w-[920px] w-full my-0 mx-auto relative">
      {/* Banner */}
      {/* <UserBanner.PaymentTop
        banner={userBanner}
        className={
          windowMode === PaymentWindowModeType.MINI
            ? 'paymentMiniTop'
            : 'paymentTop'
        }
      /> */}
      {/* Banner */}

      {/* ProductGroup */}
      <div
        id="producGroup"
        className={cn('w-full ', {
          'forPayment:py-0 forPayment:px-[5px]':
            windowMode === PaymentWindowModeType.LARGE,
          'px-[10px]': windowMode === PaymentWindowModeType.MINI,
        })}
      >
        <ProductContent
          windowMode={mode}
          productList={productList}
          topcoPayment={topcoPayment}
          selectProductItem={selectProductItem ?? null}
          isStoreApp={isStoreApp}
          setSelectItem={setSelectItem}
          userBanner={userBanner}
          loginInfo={loginInfo}
          token={token}
        />
      </div>
      {/* ProductGroup */}

      {/* Last Payment Method */}
      {selectLastPaymentMethod && (
        <div
          className={cn(
            'lastPayment bg-[#3C7EFF] dark:bg-[#233250]  mt-[35px] p-[10px_20px] text-[16px] flex justify-between items-center mo:gap-x-[5px] mo:p-[10px]',
            {
              ['']: windowMode === PaymentWindowModeType.LARGE,
              ['p-[.5rem_1.1rem] m-[1.5rem_10px] mo:p-[10px] mo:m-[1rem_10px]']:
                windowMode === PaymentWindowModeType.MINI,
            },
          )}
        >
          <div className={cn('flex items-center')}>
            <div
              className={cn(
                'flex items-center mo:text-[14px] mo:flex-col mo:items-start',
                {
                  ['text-[15px] flex-col !items-start']:
                    windowMode === PaymentWindowModeType.MINI,
                  ['text-[20px]']: windowMode === PaymentWindowModeType.LARGE,
                },
              )}
            >
              <div className="flex items-center">
                <img
                  src="/images/payment/icon_clock.png"
                  alt="clock"
                  className={cn('w-[30px] mr-[3px] mo:w-[22px]', {
                    // ['self-start']: windowMode === PaymentWindowModeType.MINI,
                  })}
                />
                <span className="mr-[5px] text-white">
                  Your recent payment method
                  <br
                    className={cn({
                      ['block']: windowMode === PaymentWindowModeType.MINI,
                      ['hidden  forPaymentLastMethod:block']:
                        windowMode === PaymentWindowModeType.LARGE,
                    })}
                  />
                </span>
              </div>
              <span
                className={cn(
                  'text-[#A4FAFF] font-medium ',
                  'dark:text-[#7BB8FF]',
                  {
                    'mo:ml-[25px]': windowMode === PaymentWindowModeType.LARGE,
                    ['ml-[33px] mo:ml-[11%]']:
                      windowMode === PaymentWindowModeType.MINI,
                  },
                )}
              >
                {selectLastPaymentMethod.paymentMethodName}
                {/* 결제수단 아이콘 이미지 대신 텍스트로 대체함. 이유는 아이콘 이미지 서로 다른 여백이여서 일관성이 없음. */}
                {/* <img
                  className="max-w-[170px] mo:max-w-[130px] inline-block"
                  src={`/images/payment/light/pgIcons/pay_${
                    selectLastPaymentMethod.pg
                  }${
                    selectLastPaymentMethod.method
                      ? `_${selectLastPaymentMethod.method}`
                      : ''
                  }.png`}
                  alt={`icon-${
                    selectLastPaymentMethod.method
                      ? selectLastPaymentMethod.method
                      : selectLastPaymentMethod.pg
                  }`}
                /> */}
              </span>
            </div>
          </div>
          <div
            className={cn(
              'bg-[#004CDF] text-white inline-block rounded-[3px] text-center cursor-pointer font-[500]',
              'dark:bg-[#1957CF] dark:text-primary-text',
              {
                'w-[130px] h-[40px] leading-[40px] mo:w-[65px] mo:h-[35px] mo:leading-[35px] mo:text-[14px]':
                  windowMode === PaymentWindowModeType.LARGE,
                'w-[75px] h-[35px] leading-[35px] text-[14px] mo:w-[65px]':
                  windowMode === PaymentWindowModeType.MINI,
              },
            )}
            onClick={e => {
              e.preventDefault();

              let pgList: PgType.MethodItemType[] = [];

              pgGroups.normal.forEach(c => {
                pgList = pgList.concat(c.pgList);
              });

              const current = pgList.filter(item => {
                return (
                  item.pg === selectLastPaymentMethod.pg &&
                  item.method === selectLastPaymentMethod.method
                );
              });

              if (current.length === 0) return;
              purchaseAction && purchaseAction(current[0], context, true);
            }}
          >
            Buy
          </div>
        </div>
      )}
      {/* Last Payment Method */}

      {/* paymentGroup */}
      <div
        id="paymethodGroup"
        className={cn('mt-[35px] w-full ', {
          'forPayment:py-0 forPayment:px-[5px]':
            windowMode === PaymentWindowModeType.LARGE,
          'px-[10px]': windowMode === PaymentWindowModeType.MINI,
        })}
      >
        <PaymentContent {...context} />
      </div>
      {/* paymentGroup */}

      {/* noticeGroup */}
      <div
        id="payNoticeGroup"
        className={cn('mt-[35px] w-full ', {
          'forPayment:py-0 forPayment:px-[5px]':
            windowMode === PaymentWindowModeType.LARGE,
          'px-[10px]': windowMode === PaymentWindowModeType.MINI,
        })}
      >
        <Notice windowMode={mode} />
      </div>
      {/* noticeGroup */}
    </div>
  );
};
export default PaymentMain;
