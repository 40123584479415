export interface WeeklyType {
  en: string;
}

export const weekliyData: WeeklyType[] = [
  {
    en: 'MON',
  },
  {
    en: 'TUE',
  },
  {
    en: 'WED',
  },
  {
    en: 'THU',
  },
  {
    en: 'FRI',
  },
  {
    en: 'SAT',
  },
  {
    en: 'SUN',
  },
  {
    en: 'TOP',
  },
];
