import { UserDefault, UserSession } from '@src/lib';

import { ApiLayer } from '@toptoon-developers/global.toptoonplus.common.lib/dist/apiV2/client/V1';
import { UserBannerType } from '@src/middleware/model';
import { openActionLayerModal } from '@src/view/modal/actionLayerModal';
import { openMiniPaymentModal } from '@src/view/modal/payment';
import { useCallback } from 'react';
import useLogin from '@src/hooks/useLogin';

const useUserBannerClick = () => {
  const { showLoginModal } = useLogin();

  // 액션레이어 닫기 및 버튼 클릭 로그 생성 api
  const updateActionLayerLog = useCallback(
    (actionLayerId: number, buttonId?: number) => {
      new ApiLayer(UserDefault.getUserSession()).updateActionLayerLog({
        actionLayerId,
        buttonId,
      });
    },
    [],
  );

  /**
   * banner type 별 액션
   * normal : 동작없음
   * url : url이동 or /payment 일때 결제 배너 로직
   * layer : 액션레이어
   * @param banner
   * @returns
   */
  const bannerClickAction = useCallback(
    (banner: UserBannerType, openMiniPayment: boolean = false) => {
      if (banner.type === 'normal') {
        return;
      }
      if (banner.type === 'url') {
        if (banner.link === '/payment') {
          if (openMiniPayment) {
            if (UserSession.getUserInfo().token !== '') {
              openMiniPaymentModal();
            } else {
              showLoginModal();
            }
            return;
          }
        }
        window.location.href = banner.link;
      }
      if (banner.type === 'layer' && banner.clickActionLayer) {
        //레이어 띄어짐
        openActionLayerModal({
          actionLayer: banner.clickActionLayer,
          openMiniPayment,
          updateActionLayerLog,
        });
      }
    },
    [showLoginModal],
  );
  return { bannerClickAction };
};

export default useUserBannerClick;
