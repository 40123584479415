import React from 'react';
import cn from 'clsx';

/**
 *
 * @param id, onClick, setPosition
 * @returns
 * ANCHOR: id: 좌/우 버튼 ID 부여
 * ANCHOR: setPosition: 좌/우 버튼 위치 정의 left, right, top, bottom
 * ANCHOR: onClick: 좌/우 버튼 클릭 이벤트
 */

const ArrowBtn = (children: React.ReactNode) => {
  return { children };
};

interface BtnPropTypes {
  onClick?: (e: any) => void;
  id?: string;
  setPosition?: string;
}

const ARROW_BTN_IMG_PATH = '/images/common/icon_hd_arrow.png';

const commonCSS = (addClassName: string, setPosition?: string) =>
  cn(
    'bg-white rounded-[50%] w-[45px] h-[45px] flex justify-center items-center transition-all ease-[cubic-bezier(0.42,0,0.58,1)] duration-200 opacity-0 absolute top-[50%] z-[1]',
    'lg:group-hover:opacity-100 middle:hidden',
    'dark:bg-arrowBtnDark',
    {},
    addClassName,
    setPosition,
  );

const Next = (props: BtnPropTypes) => {
  const { id, setPosition, onClick } = props;
  return (
    <button
      id={id}
      className={commonCSS('shadow-mainBnrArrowBtnNext ', setPosition)}
      onClick={onClick}
    >
      <img
        className="w-[26px] mx-auto"
        src={ARROW_BTN_IMG_PATH}
        alt="arrow-icon"
      />
    </button>
  );
};

const Prev = (props: BtnPropTypes) => {
  const { id, setPosition, onClick } = props;
  return (
    <button
      id={id}
      className={commonCSS(
        'rotate-180 shadow-mainBnrArrowBtnPrev',
        setPosition,
      )}
      onClick={onClick}
    >
      <img
        className="w-[26px] mx-auto"
        src={ARROW_BTN_IMG_PATH}
        alt="arrow-icon"
      />
    </button>
  );
};

ArrowBtn.Next = Next;
ArrowBtn.Prev = Prev;
export default ArrowBtn;
