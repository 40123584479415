import React from 'react';
import cn from 'clsx';
import {
  PaymentWindowModeType,
  ProductGroupType,
} from '@src/view/components/payment/data/_constants';

interface PropsType {
  isSelect: boolean;
  groupType?: ProductGroupType;
  windowMode: PaymentWindowModeType;
  payType?: string; // 결제수단 paypal에서 문구 제거용
}

const BuyButton = (props: PropsType) => {
  const { isSelect, groupType, windowMode, payType } = props;
  return (
    <div
      className={cn(
        'flex items-center justify-center text-[#fff] leading-[24px] text-center rounded-[3px]',
        'mo:min-w-[40px] mo:min-h-[25px] mo:text-[11px]',
        {
          'bg-primary-button-bg-point bg-[#FF323A]': isSelect,
          // 'bg-[#3C9D68]': isSelect && groupType === 'MEMBERSHIP',
          'bg-primary-button-bg-disabled dark:bg-[#666]': !isSelect,
          'min-w-[66px] min-h-[30px] text-[16px]':
            windowMode === PaymentWindowModeType.LARGE,
          'min-w-[40px] min-h-[25px] text-[11px]':
            windowMode === PaymentWindowModeType.MINI,
          ['hidden']: payType === 'paypal',
        },
      )}
    >
      {groupType === 'MEMBERSHIP' && 'Join'}
      {groupType === 'NORMAL' && 'Buy'}
    </div>
  );
};

export default BuyButton;
