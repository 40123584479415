import { AtomKey } from '../_constants';
import { MembershipCode } from '@src/constants';
import { atom } from 'recoil';

export interface MenuArrTypes {
  title: string;
  link: string;
  imgPath: string;
  color: string;
  type?: string;
}
const menuArr: MenuArrTypes[] = [
  {
    title: 'Mature',
    link: '/unveil',
    imgPath: 'mature',
    color: '',
    type: 'action',
  },
  {
    title: 'Charge',
    link: '/payment',
    imgPath: 'payment',
    color: '',
  },
  {
    title: 'Library',
    link: '/library',
    imgPath: 'library',
    color: '',
  },
  {
    title: 'Newsfeed',
    link: '/newsfeed',
    imgPath: 'newsfeed',
    color: '',
  },
  {
    title: 'Notice',
    link: '/help/notice',
    imgPath: 'notice',
    color: '',
  },
  { title: 'FAQ', link: '/help/faq', imgPath: 'faq', color: '' },
  {
    title: '1:1 Q&A',
    link: '/help/qna',
    imgPath: 'qna',
    color: '',
  },
  {
    title: 'Log out',
    link: '',
    imgPath: 'logout',
    color: '',
  },
];

export enum HeaderType {
  login = 'Login',
  guest = 'Guest',
}
interface MembershipTypes {
  code: MembershipCode;
  refreshAt: string;
}

interface BonusCoinTypes {
  coin: number;
  refreshAt: string;
}
export interface UserInfoTypes {
  provider: string;
  userId: string;
  userCoin: number;
  userBonusCoin: BonusCoinTypes;
  userMembership: MembershipTypes;
}

interface SnbMenuType {
  menuList: MenuArrTypes[];
  headerType: HeaderType;
  userInfo: UserInfoTypes;
}

export const initSnbMenu = {
  headerType: HeaderType.guest,
  menuList: menuArr,
  userInfo: {
    provider: '',
    userId: '',
    userCoin: 0,
    userBonusCoin: {
      coin: 0,
      refreshAt: '',
    },
    userMembership: {
      code: MembershipCode.NOT_SET,
      refreshAt: '',
    },
  },
};

const localStorage =
  typeof window !== 'undefined' ? window.localStorage : undefined;

export const SnbMenuState = atom<SnbMenuType>({
  key: AtomKey.SNB_MENU,
  default: initSnbMenu,
});
